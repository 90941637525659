import { guardWithErrorHandling, fetchRecord } from '@/router/navigation-guards'

import RecordPage from '@/pages/RecordPage.vue'

export default [
  {
    path: '/records/:id',
    name: 'RecordPage',
    component: RecordPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout'
    },
    props: route => ({
      record: route.params.record
    }),
    beforeEnter: guardWithErrorHandling(
      async function (to, from, next) {
        to.params.record = await fetchRecord({ id: to.params.id, countView: true })
        next()
      }
    )
  }
]
