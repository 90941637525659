<template>
  <main class="page-not-found">
    <div class="layout-centered">
      <div class="page-not-found__icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="56"
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="var(--gray)"
          >
            <g
              stroke-width="2"
              transform="translate(22 19)"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2 0c-.264876 0-.4796005.2147245-.4796005.4796005a.4796104.4796104 0 0 0 .0004158.019966L2 12 2.4791847.4995665c.011027-.2646464-.1945722-.4881238-.4592187-.4991507A.4796005.4796005 0 0 0 2 0z"
              />
              <circle
                cx="2"
                cy="17"
                r="1"
              />
            </g>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M24.1949153 55C8.7316384 48.3718412 1 40.5740072 1 31.6064982V9.7725632c6.3612753 0 13.905912-2.8031381 22.6339102-8.4094141l-.000014-.0000218c.3394299-.2180268.7766227-.2107525 1.1086113.018446C32.8452747 6.9755666 40.2644388 9.7725632 47 9.7725632v21.833935C47 40.3140794 39.3983051 48.1119134 24.1949153 55z"
            />
          </g>
        </svg>
      </div>
      <h1 class="h3 page-not-found__title">
        {{ $t('error.codes.404.header') }}
      </h1>
      <p>{{ $t('error.codes.404.description') }}</p>
    </div>
  </main>
</template>
<script>
export default {
  metaInfo: function () {
    return {
      title: this.$t('title'),
      meta: [{ name: 'description', content: '' }]
    }
  },
  i18n: {
    messages: {
      pt: {
        title: 'Página não encontrada'
      },
      en: {
        title: 'Page not found'
      }
    }
  }
}
</script>

<style lang="scss">
.page-not-found {
  display: flex;
  flex-grow: 1;
}
.page-not-found .layout-centered {
  text-align: center;
  width: 100%;
  max-width: 44.125rem;
  padding: 1.5rem;
  margin: 3rem auto;

  @media screen and (width >= 47.5rem) {
    margin: auto;
    padding: 1rem;
  }
}

h1.page-not-found__title {
  @media screen and (width <= 60rem) {
    margin: 2rem 0 0.5rem;
  }
}
</style>
