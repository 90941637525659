import client from '@/api/client'

/**
 * Fetch root unit
 */
export async function getRoot () {
  const response = await client.get('/units/root')
  return response.data
}

/**
 * Fetch a unit information
 * @param {object} root0 Root object
 * @param {string} root0.unitID The id of the unit to fetch
 * @param {boolean} root0.countView IF the the view counter ios returned
 */
export async function get ({ unitID, countView }) {
  const response = await client.get(`/units/${unitID}`, { params: { countView } })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {object} root0.unit The unit data used to create the unit
 */
export async function create ({ unit }) {
  const response = await client.post('/units', unit)
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {object} root0.unit Unit data used for creation
 * @param {object} root0.options options?
 */
export async function validateBeforeCreate ({ unit, options }) {
  const response = await client.post('/validate/create-unit', {
    value: unit,
    options
  })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {string} root0.unitID Id of the unit to update
 * @param {object} root0.unit Data about the unit
 */
export async function update ({ unitID, unit }) {
  const response = await client.put(`/units/${unitID}`, unit)
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {string} root0.unitID The id of the unit to delete
 */
export async function remove ({ unitID }) {
  const response = await client.delete(`/units/${unitID}`)
  return response.data
}

/**
 * Lists units
 * @param {object} root0 Root object
 * @param {number} root0.page The page to retrieve
 * @param {number} root0.perPage The number of results perPage
 * @param {string} root0.sort The sort param
 * @param {string} root0.q Search query
 * @param {boolean} root0.ignored ?
 */
export async function list ({ page, perPage, sort, q, ignored }) {
  const params = {
    ...(q && { q }),
    ...(sort && { sort })
  }
  if (perPage) {
    params.limit = perPage
    if (page) {
      params.skip = (page - 1) * perPage
    }
  }
  if (ignored) {
    params.ignored = ignored
  }
  const response = await client.get('/units', {
    params
  })
  return response.data
}

/**
 * Fetches the list of unit types available in backend
 */
export async function listTypes () {
  const response = await client.get('/units/types')
  return response.data
}

/**
 * List record for a given unit
 * @param {object} root0 Root object
 * @param {string} root0.unitID The id of the unit to get record from
 * @param {number} [root0.page] The page to retrieve
 * @param {number} [root0.perPage] The number of results that appear in each page
 * @param {string} [root0.sort] The sort method that will be used
 * @param {string} [root0.q] The search query that will be applied
 * @param {string[]} [root0.recordTypes] A list with the record types that will be included
 * @param {object} [root0.start] The start of the interval to filter records
 * @param {object} [root0.end] The end of the interval to filter records
 */
export async function getRecords ({ unitID, page, perPage, sort, q, recordTypes, start, end }) {
  const response = await client.get(`/units/${unitID}/records`, {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      ...(sort && { sort }),
      ...(q && { q }),
      ...(recordTypes && { type: recordTypes }),
      ...(start?.year && { start: `${start.year}-${start.month ?? 1}-${start.day ?? 1}` }),
      ...(end?.year && { end: `${end.year}-${end.month ?? 12}-${end.day ?? 31}` })
    }
  })
  return response.data
}

/**
 * Lis members of a unit
 * @param {object} root0 Root object
 * @param {string} root0.unitID id of the unit to fetch
 * @param {number} root0.page The page to retrieve
 * @param {number} root0.perPage The number of results to show in each page
 * @param {string} root0.sort The sorting order
 * @param {string} root0.q The query to use in a search
 * @param {boolean} root0.ongoing If the affiliation is still active
 */
export async function getMembers ({ unitID, page, perPage, sort, q, ongoing }) {
  const response = await client.get(`/units/${unitID}/members`, {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      ...(sort && { sort }),
      ...(q && { q }),
      ...(ongoing !== undefined && { ongoing })
    }
  })
  return response.data
}

/**
 * List affiliations
 * @param {object} root0 Root object
 * @param {string} root0.unitID The id of the unit
 * @param {number} root0.page The page to retrieve from the server
 * @param {number} root0.perPage The number of results to show in each page
 * @param {string} root0.sort The sort to be used in the list
 * @param {string} root0.q The query used to search affiliations
 * @param {boolean} root0.verified If the affiliation is verified
 * @param {boolean} root0.ongoing If the affiliation is still active
 */
export async function getAffiliations ({ unitID, page, perPage, sort, q, verified, ongoing }) {
  const response = await client.get(`/units/${unitID}/affiliations`, {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      ...(sort && { sort }),
      ...(q && { q }),
      ...(verified !== undefined && { verified }),
      ...(ongoing !== undefined && { ongoing })
    }
  })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {string} root0.unitID The id of the unit to get the managers from
 * @param {number} root0.page The page to retrieve
 * @param {number} root0.perPage The number of results per page
 * @param {string} root0.sort The sorting of the elements
 * @param {string} root0.q The search query
 */
export async function getManagers ({ unitID, page, perPage, sort, q }) {
  const response = await client.get(`/units/${unitID}/managers`, {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      ...(sort && { sort }),
      ...(q && { q })
    }
  })
  return response.data
}

/**
 * List subunits of a unit
 * @param {object} root0 Root object
 * @param {string} root0.unitID The id of a unit to get the sub units from
 * @param {number} root0.page The page that will be retrieved
 * @param {number} root0.perPage The number of elements to show in each page
 * @param {string} root0.sort The sorting to be used
 * @param {string} root0.q The search query
 */
export async function getSubUnits ({ unitID, page, perPage, sort, q }) {
  const response = await client.get('/units', {
    params: {
      parentID: unitID,
      skip: (page - 1) * perPage,
      limit: perPage,
      ...(sort && { sort }),
      ...(q && { q })
    }
  })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {string} root0.unitID The id of the unit to get the scientific
 * @param {object} root0.start The start date to get the production
 * @param {object} root0.end The end date to get the production
 */
export async function getScientificProduction ({ unitID, start, end }) {
  const response = await client.get(`/units/${unitID}/scientific-production`, {
    params: {
      ...(start && { start }),
      ...(end && { end })
    }
  })
  return response.data
}

export default {
  create,
  update,
  remove,
  get,
  list,
  getRoot,
  listTypes,
  getRecords,
  getMembers,
  getAffiliations,
  getManagers,
  getSubUnits,
  validateBeforeCreate
}
