import { guardWithErrorHandling, fetchUnitTypes, fetchUnit, fetchAllUnitManagers } from '@/router/navigation-guards'
import { get as getUserFromId } from '@/api/users'
import { get as getUnit } from '@/api/units'

const AdministrateUnitsPage = () => import('@/pages/AdministrateUnitsPage.vue')
const AdministrateUnitPage = () => import('@/pages/AdministrateUnitPage.vue')
const AdministrateUsersPage = () => import('@/pages/AdministrateUsersPage.vue')
const AdministrateUsersTabPage = () => import('@/pages/AdministrateUsersTabPage.vue')
const AdministrateUserPage = () => import('@/pages/AdministrateUserPage.vue')

export default [
  {
    path: 'admin/units',
    name: 'AdministrateUnitsPage',
    component: AdministrateUnitsPage,
    meta: {
      'porg-auth': {
        roles: ['scholar-admin']
      },
      scrollBehavior: {
        routeUpdate: 'savedPosition'
      }
    },
    props: route => ({
      page: Number(route.query.page) || 1,
      q: route.query.q,
      perPage: Number(route.query.perPage) || undefined
    })
  },
  {
    path: 'admin/units/:unitId',
    name: 'AdministrateUnitPage',
    component: AdministrateUnitPage,
    meta: {
      'porg-auth': {
        roles: ['scholar-admin']
      }
    },
    props: route => ({
      unitId: route.params.unitId,
      unitTypes: route.params.unitTypes,
      currentUnitData: route.params.currentUnitData,
      currentManagersPage: route.params.currentManagersPage
    }),
    beforeEnter: guardWithErrorHandling(
      async function (to, from, next) {
        const [unitTypes, unit, managersPage] = await Promise.all([
          fetchUnitTypes(),
          fetchUnit({ id: to.params.unitId }),
          fetchAllUnitManagers({ id: to.params.unitId })
        ])
        to.params.unitTypes = unitTypes
        to.params.currentUnitData = unit
        to.params.currentManagersPage = managersPage
        next()
      }
    )
  },
  {
    path: 'admin/users',
    name: 'AdministrateUsersPage',
    redirect: { name: 'AdministrateUsersUserRolePage' },
    component: AdministrateUsersPage,
    meta: {
      'porg-auth': {
        roles: ['scholar-admin']
      },
      activeLinkStrategy: 'routeAndChildren'
    },
    props: route => ({
      page: Number(route.query.page) || 1,
      q: route.query.q,
      perPage: Number(route.query.perPage) || undefined,
      roles: route.meta.roles
    }),
    children: [
      {
        path: 'all',
        name: 'AdministrateUsersUserRolePage',
        component: AdministrateUsersTabPage,
        meta: {
          roles: ['user'],
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'authors',
        name: 'AdministrateUsersAuthorRolePage',
        component: AdministrateUsersTabPage,
        meta: {
          roles: ['author', 'pre-setup-author'],
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'operators',
        name: 'AdministrateUsersOperatorRolePage',
        component: AdministrateUsersTabPage,
        meta: {
          roles: ['operator'],
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'administrators',
        name: 'AdministrateUsersAdminRolePage',
        component: AdministrateUsersTabPage,
        meta: {
          roles: ['scholar-admin'],
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      }
    ]
  },
  {
    path: 'admin/users/:userId',
    name: 'AdministrateUserPage',
    component: AdministrateUserPage,
    meta: {
      'porg-auth': {
        roles: ['scholar-admin']
      }
    },
    props: route => ({
      originalUser: route.params.user,
      units: route.params.units || []
    }),
    beforeEnter: guardWithErrorHandling(
      async function (to, from, next) {
        to.params.user = await getUserFromId({ id: to.params.userId })
        if (to.params.user.units.length > 0) {
          const units = []
          for (const unitID of to.params.user.units) {
            const unit = await getUnit({ unitID })
            units.push(unit)
          }
          to.params.units = units
        }
        next()
      }
    )
  }
]
