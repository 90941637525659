<template>
  <component
    :is="tag"
    class="card-row"
  >
    <!-- FIXME: row should be vertically aligned -->
    <avatar
      :name="value.unit.acronym"
      class="card-row__figure figure--letter figure--avatar-desktop-only"
    />
    <div class="card-row__text">
      <UnitBreadcrumb :unit="value.unit" />
      <component
        :is="affiliationTitleTag"
        class="card-row__title h5--ssp researcher-affiliation__unit-name"
      >
        <RouterLink
          v-if="clickable"
          :to="{ name: 'UnitPage', params: { unitID: value.unit.id } }"
          class="card-row__link"
        >
          {{ value.unit.name }}
        </RouterLink>
        <template v-else>
          {{ value.unit.name }}
        </template>
      </component>
      <p class="u-text-secondary">
        <DateInterval
          :start="value.start"
          :end="value.end"
        />
      </p>
      <span
        v-if="showBadge && !value.verified"
        class="label label--gray label--sm"
      >
        {{ $t('settings.account.affiliations.pending.approval') }}
      </span>
    </div>
    <div
      v-if="editable"
      class="card-row__meta card-row__meta--align-start"
    >
      <Dropdown>
        <button
          slot="dropdown-trigger"
          class="i-ellipsis"
          aria-label="More options"
          aria-haspopup="true"
          aria-expanded="false"
          @click.prevent=""
        />
        <template slot="dropdown-panel">
          <ul class="dropdown-menu dropdown--xs">
            <li class="dropdown-menu__item">
              <button
                class="dropdown-menu__link"
                @click.prevent="editAffiliation"
              >
                {{ $t('actions.edit.affiliation') }}
              </button>
            </li>
            <li class="dropdown-menu__item">
              <button
                class="dropdown-menu__link u-text-danger"
                @click.prevent="removeAffiliation"
              >
                {{ $t('actions.remove.affiliation') }}
              </button>
            </li>
          </ul>
        </template>
      </Dropdown>
    </div>
  </component>
</template>
<script>
import Avatar from '@/components/utils/Avatar.vue'
import Dropdown from '@/components/utils/Dropdown.vue'
import UnitBreadcrumb from '@/components/units/UnitBreadcrumb.vue'
import DateInterval from '@/components/utils/DateInterval.vue'

export default {
  components: {
    Avatar,
    Dropdown,
    UnitBreadcrumb,
    DateInterval
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    tag: {
      type: String,
      default: 'div'
    },
    affiliationTitleTag: {
      type: String,
      default: 'p'
    },
    editable: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    showBadge: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    editAffiliation () {
      this.$emit('edit', this.value)
    },
    removeAffiliation () {
      this.$emit('remove', this.value)
    }
  }
}
</script>
<style lang="scss">
.researcher-affiliation__unit-name {
  margin-top: 0.125rem !important;
  margin-bottom: 0.25rem;
  align-items: center !important;
}
</style>
