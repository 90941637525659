import Settings from '@/router/private/settings'
import Records from '@/router/private/records'
import { guardWithErrorHandling, getUserRolesFromCookie } from '@/router/navigation-guards'
import { get as fetchRecord } from '@/api/records'
import { get as fetchProfile, getEmails as fetchEmails, getNotifications } from '@/api/profile'
import { get as getUserTask } from '@/api/user-tasks'
import store from '@/store'
import dayjs from 'dayjs'

const CreateRecordPage = () => import('@/pages/CreateRecordPage.vue')
const MergeRecordsPage = () => import('@/pages/MergeRecordsPage.vue')
const SetupAccountPage = () => import('@/pages/SetupAccountPage.vue')

const NegotiateOAuthPage = () => import('@/pages/NegotiateOAuthPage.vue')
const EmailVerificationPage = () => import('@/pages/EmailVerificationPage.vue')
const ReportRecordPage = () => import('@/pages/ReportRecordPage.vue')
const NotificationsPage = () => import('@/pages/NotificationsPage.vue')
const NegotiateCienciaIdPage = () => import('@/pages/NegotiateCienciaIdPage.vue')

/** @type {Array<import('vue-router').Route>} */
export default [
  ...Settings,
  ...Records,
  {
    path: '/negotiate-oauth/:provider',
    name: 'NegotiateOAuthPage',
    component: NegotiateOAuthPage,
    meta: {
      'porg-auth': {
        roles: ['author']
      }
    },
    props: route => {
      return {
        provider: route.params.provider,
        nonce: route.query.nonce,
        code: route.query.code
      }
    },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('fetchExternalProfiles')
      next()
    }
  },
  {
    path: '/negotiate-ciencia-id/:provider',
    name: 'NegotiateCienciaIdPage',
    component: NegotiateCienciaIdPage,
    meta: {
      'porg-auth': {
        roles: ['author']
      }
    },
    props: route => {
      return {
        provider: route.params.provider
      }
    },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('fetchExternalProfiles')
      next()
    }
  },
  {
    path: '/account/setup',
    name: 'SetupAccountPage',
    component: SetupAccountPage,
    meta: {
      'porg-auth': {
        roles: ['user']
      }
    },
    props: route => ({
      profile: route.params.profile,
      configuration: route.params.configuration
    }),
    async beforeEnter (to, from, next) {
      // TODO: error handling
      const [profile, emails] = await Promise.all([fetchProfile(), fetchEmails()])
      if (!profile?.roles?.includes('pre-setup-author') && !profile?.roles?.includes('pre-opt-in-author')) {
        return next({ path: '/', replace: false })
      }
      const { email: primaryEmail } = emails.find(e => e.primary)
      profile.email = primaryEmail
      to.params.profile = profile
      to.params.configuration = store.state.configuration
      next()
    }
  },
  {
    path: '/notifications',
    name: 'NotificationsPage',
    component: NotificationsPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['user']
      }
    },
    props: route => ({
      notifications: route.params.notifications,
      page: route.params.page,
      perPage: route.params.perPage,
      now: route.params.now
    }),
    async beforeEnter (to, from, next) {
      to.params.page = 1
      to.params.perPage = 10
      to.params.now = dayjs()
      const notifications = await getNotifications({
        page: to.params.page,
        perPage: to.params.perPage,
        untilTimestamp: to.params.now.toISOString()
      })
      to.params.notifications = notifications
      next()
    }
  },
  {
    path: '/email-verification',
    name: 'EmailVerificationPage',
    component: EmailVerificationPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['user']
      }
    },
    props: route => ({
      email: route.query.email,
      token: route.query.token
    })
    // TODO: beforeEnter that redirects to / if email or token are not defined
  },
  {
    path: '/create-record',
    name: 'CreateRecordPage',
    component: CreateRecordPage,
    meta: {
      layout: 'PageWithNavBarLayout',
      'porg-auth': {
        roles: ['user']
      }
    },
    beforeEnter: guardWithErrorHandling(
      async function (to, from, next) {
        const roles = getUserRolesFromCookie()
        const profile = store.state.profile
        if (roles.includes('author') || roles.includes('operator') || (profile.units ?? []).length > 0) {
          return next()
        }
        return next({ ...(from ?? { path: '/' }), replace: true })
      }
    )
  },
  {
    path: '/merge-records',
    name: 'MergeRecordsPage',
    component: MergeRecordsPage,
    meta: {
      layout: 'PageWithNavBarLayout'
    },
    props: route => ({
      taskId: route.query.taskId,
      task: route.params.task,
      records: route.params.records ?? [],
      mode: route.params.mode ?? 'manual'
    }),
    beforeEnter: guardWithErrorHandling(
      async function (to, from, next) {
        const roles = getUserRolesFromCookie()
        if (!roles.includes('author') && !roles.includes('operator')) {
          return next({ ...(from ?? { path: '/' }), replace: true })
        }
        if (to.query.taskId) {
          const task = await getUserTask({ id: to.query.taskId })
          to.params.task = task
          to.params.mode = task.type
          const records = await Promise.all(
            task.records.map(record => fetchRecord({ id: record.metadata.id, allowDraft: true }))
          )
          to.params.records = records
          return next()
        }
        to.params.mode = 'manual'
        next()
      })
  },
  {
    path: '/records/:id/report',
    name: 'ReportRecordPage',
    component: ReportRecordPage,
    meta: {
      layout: 'PageWithNavBarLayout',
      'porg-auth': {
        roles: ['user']
      }
    },
    props: route => ({
      record: route.params.record
    }),
    beforeEnter: guardWithErrorHandling(
      async function (to, from, next) {
        to.params.record = await fetchRecord({ id: to.params.id })
        next()
      }
    )
  }
]
