export const SET_TOP_MESSAGE = 'SET_TOP_MESSAGE'

export const RECEIVED_PROFILE = 'RECEIVED_PROFILE'

export const RECEIVED_EXTERNAL_PROFILES = 'RECEIVED_EXTERNAL_PROFILES'

export const RECEIVED_MANAGED_UNITS = 'RECEIVED_MANAGED_UNITS'

export const SET_CONFIGURATION_STATE = 'SET_CONFIGURATION_STATE'

export const SET_PROGRESS_BAR_STATE = 'SET_PROGRESS_BAR_STATE'
