import client from '@/api/client'

/**
 * Get a single user task
 * @param {object} object Root object
 * @param {string} object.id The id of the user task to get
 * @returns returns with the user task data
 */
export async function get ({ id }) {
  const response = await client.get(`/user-tasks/${id}`)
  return response.data
}

/** @typedef {{ items: [], totalItems: number }} Page */

/**
 * Get an array of user tasks based on an array of types and/or record types and/or a query string
 * @param {object} object Root object
 * @param {string} [object.q] Query to do the search
 * @param {Array} object.type  A list of user task types
 * @param {Array} object.recordTypes  A list of user record tyes
 * @param {number} object.page The page number that you are trying to get
 * @param {number} object.perPage The number of results per page
 * @param {string} object.stakeholder The userId of the stakeholder
 * @param {string} object.taker The userId of the taker
 * @returns {Promise<Page>} userTasksPage
 */
export async function list ({ type, page, perPage, q, stakeholder, taker, recordTypes }) {
  const params = {
    ...(type && { type }),
    ...(recordTypes && { recordTypes }),
    ...(q && { q }),
    skip: (page - 1) * perPage,
    limit: perPage,
    ...(stakeholder && { stakeholder }),
    ...(taker && { taker })
  }
  const response = await client.get('/user-tasks', { params })
  return response.data
}

export default {
  get,
  list
}
