<template>
  <DeleteConfirmationModal
    v-if="isModalOpen"
    v-model="isModalOpen"
    :resource-name="record.title"
    @delete="deleteRecord"
  >
    <template #header>
      <h1 class="modal__title">
        {{ $t('delete.record.confirmation.header') }}
      </h1>
    </template>
    <template #consequences-description>
      <i18n
        v-if="profile.roles.includes('operator') || isUnitManager()"
        path="delete.record.confirmation.operator.text"
        tag="p"
      >
        <span
          place="redText"
          class="u-text-danger"
        >{{ $t('delete.record.confirmation.operator.redText') }}</span>
      </i18n>
      <p v-else>
        {{ $t('delete.record.confirmation.author.text') }}
      </p>
      <div class="card">
        <Record
          :record="record"
          :clickable="false"
        />
      </div>
    </template>
    <template #delete-button-text>
      {{ $t('delete.record.confirmation.button') }}
    </template>
  </DeleteConfirmationModal>
</template>
<script>
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal.vue'
import Record from '@/components/records/Record.vue'
import { remove as removeRecord } from '@/api/records'
import { isUnitManagerOfAuthorMemberships } from '@/utils/record-helpers'

import { mapState } from 'vuex'

export default {
  components: {
    DeleteConfirmationModal,
    Record
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      required: true
    }
  },
  computed: {
    isModalOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    ...mapState({
      profile: state => state.profile
    })
  },
  methods: {
    async deleteRecord () {
      await removeRecord({ id: this.record.metadata.id })
      this.$emit('deleted', this.record.metadata.id)
      this.isModalOpen = false
    },
    isUnitManager () {
      return isUnitManagerOfAuthorMemberships({ record: this.record, profile: this.profile })
    }
  }
}
</script>
