<template>
  <div class="settings-page container layout-sidebar layout-sidebar--desktop-only">
    <div class="layout-sidebar-two-columns-container">
      <div class="layout-sidebar__sidebar layout-sidebar__sidebar--sm">
        <div class="card">
          <header class="card__section card__section--header card__section--full-width">
            <div class="card-row">
              <UserAvatar
                :src="profile.avatar"
                :name="profile.name"
                class="card-row__figure figure--56"
              />
              <div class="card-row__text">
                <span class="card-row__title h5--ssp">
                  {{ profile.name }}
                </span>
                <p v-if="profile.username">
                  {{ profile.username }}
                </p>
              </div>
            </div>
          </header>
          <section class="card__section">
            <template v-if="profile.roles.includes('user')">
              <h2 class="card__section-title h5--ssp">
                {{ $t('menu.account.header') }}
              </h2>
              <ul class="block-list">
                <li>
                  <RouterLink
                    :to="{ name: 'EditProfilePage' }"
                    :class="{ 'u-active-link': activeRouteName === 'EditProfilePage' }"
                  >
                    {{ $t('menu.account.submenu.profile') }}
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    :to="{ name: 'ManageEmailsPage' }"
                    :class="{ 'u-active-link': activeRouteName === 'ManageEmailsPage' }"
                  >
                    {{ $t('menu.account.submenu.emails') }}
                  </RouterLink>
                </li>
                <template v-if="profile.roles.includes('author')">
                  <li>
                    <RouterLink
                      :to="{ name: 'ManageAliasesPage' }"
                      :class="{ 'u-active-link': activeRouteName === 'ManageAliasesPage' }"
                    >
                      {{ $t('menu.account.submenu.aliases') }}
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      :to="{ name: 'ManageExternalRepositoriesPage' }"
                      :class="{ 'u-active-link': activeRouteName === 'ManageExternalRepositoriesPage' }"
                    >
                      {{ $t('menu.account.submenu.external.repositories') }}
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      :to="{ name: 'ManageRecordsPage' }"
                      :class="{ 'u-active-link': activeRouteName === 'ManageRecordsPage' }"
                    >
                      {{ $t('menu.account.submenu.records') }}
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      :to="{ name: 'ManageAffiliationsPage' }"
                      :class="{ 'u-active-link': activeRouteName === 'ManageAffiliationsPage' }"
                    >
                      {{ $t('menu.account.submenu.affiliations') }}
                    </RouterLink>
                  </li>
                </template>
              </ul>
            </template>
            <template v-if="profile.roles.includes('author') || profile.roles.includes('operator')">
              <h2 class="card__section-title h5--ssp">
                {{ $t('menu.tasks.header') }}
              </h2>
              <ul class="block-list">
                <li>
                  <!-- FIXME: add logic for menu link circle -->
                  <RouterLink
                    :to="{ name: 'ManageDuplicatedRecordsPage' }"
                    :class="{
                      'u-active-link': activeRouteName === 'ManageDuplicatedRecordsPage',
                      'menu__link--new': false
                    }"
                  >
                    {{ $t('menu.tasks.submenu.duplicates') }}
                  </RouterLink>
                </li>
                <li>
                  <!-- FIXME: add logic for menu link circle -->
                  <RouterLink
                    :to="{ name: 'ManageInconsistenciesPage' }"
                    :class="{
                      'u-active-link': activeRouteName === 'ManageInconsistenciesPage',
                      'menu__link--new': false
                    }"
                  >
                    {{ $t('menu.tasks.submenu.inconsistencies') }}
                  </RouterLink>
                </li>
                <li v-if="profile.roles.includes('operator')">
                  <!-- FIXME: add logic for menu link circle -->
                  <RouterLink
                    :to="{ name: 'ManageComplaintsPage' }"
                    :class="{
                      'u-active-link': activeRouteName === 'ManageComplaintsPage',
                      'menu__link--new': false
                    }"
                  >
                    {{ $t('menu.tasks.submenu.complaints') }}
                  </RouterLink>
                </li>
              </ul>
            </template>
            <collapsible-list
              v-for="unit in managedUnits"
              :key="unit.id"
              class="card__section-title menu__managed-unit"
            >
              <template #trigger-text>
                <h1 class="h5--ssp">
                  {{ unit.acronym }}
                </h1>
              </template>
              <template #list>
                <ul
                  :key="'ul-' + unit.id"
                  class="block-list"
                >
                  <li>
                    <RouterLink
                      :to="{ name: 'ManageUnitInfoPage', params: { unitId: unit.id } }"
                      :class="{ 'u-active-link': $route.params.unitId === unit.id && activeRouteName === 'ManageUnitInfoPage' }"
                    >
                      {{ $t('menu.unit.management.submenu.unit') }}
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      :to="{ name: 'ManageUnitAffiliationsPage', params: { unitId: unit.id } }"
                      :class="{ 'u-active-link': $route.params.unitId === unit.id && activeRouteName === 'ManageUnitAffiliationsPage' }"
                    >
                      {{ $t('menu.unit.management.submenu.affiliations') }}
                    </RouterLink>
                  </li>
                </ul>
              </template>
            </collapsible-list>
            <template v-if="profile.roles.includes('scholar-admin')">
              <h2 class="card__section-title h5--ssp">
                {{ $t('menu.administration.header') }}
              </h2>
              <ul class="block-list">
                <li>
                  <RouterLink
                    :to="{ name: 'AdministrateUsersPage' }"
                    :class="{ 'u-active-link': activeRouteName === 'AdministrateUsersPage' }"
                  >
                    {{ $t('menu.administration.submenu.users') }}
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    :to="{ name: 'AdministrateUnitsPage' }"
                    :class="{ 'u-active-link': activeRouteName === 'AdministrateUnitsPage' }"
                  >
                    {{ $t('menu.administration.submenu.units') }}
                  </RouterLink>
                </li>
              </ul>
            </template>
          </section>
        </div>
      </div>
      <div class="layout-sidebar__main">
        <RouterView />
      </div>
    </div>
  </div>
</template>
<script>
import UserAvatar from '@/components/utils/UserAvatar.vue'
import CollapsibleList from '@/components/utils/CollapsibleList.vue'
import { mapState } from 'vuex'

export default {
  components: {
    UserAvatar,
    CollapsibleList
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      managedUnits: state => state.managedUnits
    }),
    activeRouteName () {
      const routesWithMeta = this.$route.matched.filter(r => r.meta.activeLinkStrategy !== undefined)
      const mostSpecific = routesWithMeta.length > 0
        ? routesWithMeta[routesWithMeta.length - 1]
        : this.$route.matched[this.$route.matched.length - 1]
      const strategy = mostSpecific.meta?.activeLinkStrategy ?? 'route'

      switch (strategy) {
        case 'exact': { // only exact route (full path) - no query params, no hash
          // eslint-disable-next-line security/detect-non-literal-regexp
          const regex = new RegExp(mostSpecific.regex)
          const isFullPathMatch = regex.test(this.$route.path) &&
            this.$route.hash === '' &&
            Object.keys(this.$route.query).length === 0

          return isFullPathMatch ? mostSpecific.name : null
        }
        case 'routeAndChildren': // route and its children - allows query params, hash
          return mostSpecific.name
        case 'route': // only route with same name - allows query params, hash
        default:
          return this.$route.name === mostSpecific.name ? mostSpecific.name : null
      }
    }
  },
  metaInfo: function () {
    return { titleTemplate: this.$t('titleTemplate') }
  },
  i18n: {
    messages: {
      pt: {
        titleTemplate: '%s – Configurações – Scholar'
      },
      en: {
        titleTemplate: '%s – Settings – Scholar'
      }
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables";

.settings-page {
  .menu__link--new::after {
    content: "\25cf";
    color: $blue-600;
    margin-left: 0.25rem;
  }

  .menu__managed-unit {
    .trigger {
      color: initial;
      cursor: pointer;
    }
    .trigger--closed {
      margin-bottom: 0;
      transition-property: all;
      transition-delay: 0.2s;
    }
    .trigger::after {
      color: $gray;
    }
    .trigger--closed::after {
      border-width: 5px 5px 0;
    }
    .trigger--open::after {
      border-width: 0 5px 5px;
    }
  }
}
</style>
