// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router, { afterVuePorg } from '@/router'
import VuePorg, { getInfoFromCookie } from 'vue-porg'
import client from '@/api/client'

import i18n, { setLocale } from '@/i18n'
import * as ProfileAPI from '@/api/profile'
import Meta from 'vue-meta'

import { getStaticConfig, get as getConfig } from '@/api/configuration'

import 'dayjs/locale/pt'
import 'dayjs/locale/en'

import relativeTime from 'dayjs/plugin/relativeTime'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import arraySupport from 'dayjs/plugin/arraySupport'

import dayjs from 'dayjs'

dayjs.extend(relativeTime)
dayjs.extend(LocalizedFormat)
dayjs.extend(arraySupport)

const SocketIOPromise = async () => (await import('socket.io-client')).io

/**
 * Starts an websocket connection with the server if the user is logged in
 * @returns A promise to a Socket connection or null if the user is not logged in
 */
async function startWebSocket () {
  // Only connect via websocket for logged users
  if (getInfoFromCookie()) {
    const io = await SocketIOPromise()
    return io('/', { transports: ['websocket'] })
  }
  return null
}

// Load socketIO concurrently
export const startingWebSocket = startWebSocket()

Vue.use(VuePorg, {
  router,
  store,
  axios: client,
  notAuthorizedHandlerApi: () => {
    window.location.href = '/'
  },
  notAuthorizedHandlerRoute: (auth, user, { to, from, next }) => {
    if (from.path) {
      // the user was already inside the SPA so we can safely abort navigation
      if (getInfoFromCookie()) {
        return next(false)
      } else {
        window.location.href = `/api/authenticate?callbackUrl=${encodeURIComponent(to.fullPath)}`
      }
    }
    // the user came from outside the SPA
    // e.g. typed the link on the url bar or clicked on a bookmark
    return next({ path: '/' })
  }
})

afterVuePorg()

Vue.use(Meta)

Vue.mixin({ methods: { setLocale } })

Vue.prototype.$notification = {
  send: function ({ key, dismiss, type }) {
    store.dispatch('setTopMessage', {
      active: true,
      msg: key,
      dismiss,
      type
    })
  },
  dismiss: function () {
    store.dispatch('setTopMessage', {
      active: false,
      msg: undefined,
      dismiss: false,
      type: ''
    })
  }
}

Vue.filter('prettyBytes', function (num) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number')
  }

  const neg = num < 0
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  if (neg) {
    num = -num
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B'
  }

  const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
  const unit = units[exponent]

  return (neg ? '-' : '') + num + ' ' + unit
})

Vue.config.errorHandler = async (error, vm, info) => {
  if (error.response?.status === 401) return
  console.error(error)
  return vm.$router.push({ name: 'ErrorPage', params: { error } })
}

window.addEventListener('offline', () => {
  vue.$notification.send({ key: 'no.network', type: 'warn', dismiss: false })
}, false)

window.addEventListener('online', () => {
  if (store.state.topMessage.active && store.state.topMessage.msg === 'no.network') {
    vue.$notification.dismiss()
  }
}, false)

/* eslint-disable no-new */
const vue = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  metaInfo: {
    titleTemplate: '%s – Scholar'
  },
  async beforeMount () {
    let profilePromise
    // Load custom translations, instance configurations and profile in parallel
    const configPromises = Promise.all([getStaticConfig(), getConfig()])

    if (this.$auth?.isLogged()) {
      profilePromise = ProfileAPI.get()
      // Verifies if user has emails pending validation
      // this.$route returns '/' as the path inside this as such the window.location was used to retrieve the real location
      if (window.location.pathname !== '/email-verification') {
        const emails = await ProfileAPI.getEmails()
        if (emails.some(item => !item.verified)) {
          this.$notification.send({ key: 'verify.email.warning', type: 'warn', dismiss: 'true' })
        }
      }
    }

    // Await and process server responses
    try {
      const [staticConfig, config] = await configPromises
      await store.dispatch('setConfigurationState', { configuration: { ...config, ...staticConfig } })
      if (profilePromise) {
        const profile = await profilePromise
        if (profile?.roles?.includes('pre-opt-in-author') || profile?.roles?.includes('pre-setup-author')) {
          this.$router.replace({ name: 'SetupAccountPage' })
        }
      }
    } catch (err) {
      console.error(err)
    }
  },
  render (createElement) {
    return createElement(App, {})
  }
})
