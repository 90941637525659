<template>
  <div class="container">
    <article class="publication publication-article">
      <header class="publication-header">
        <slot name="header" />
      </header>
      <main class="publication-main">
        <div class="publication-main__left">
          <warning-box
            v-if="record.metadata.state === 'invalid' && $auth.isLogged()"
            type="warn"
            style="margin-bottom: 1rem;"
          >
            <span slot="badge">{{ $t('warningBox.badge') }}</span>
            <template slot="message">
              <p>{{ $t('warningBox.text') }}</p>
            </template>
          </warning-box>
          <div class="card">
            <div class="card-row">
              <div class="card-row__text">
                <h2
                  class="h4"
                  style="margin: 0;"
                >
                  {{ $t('sections.keyInformation') }}
                </h2>
              </div>
            </div>
            <div class="card-row">
              <div class="card-row__text">
                <slot name="keyInformation" />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-row">
              <div class="card-row__text">
                <h2
                  class="h4"
                  style="margin: 0;"
                >
                  {{ $t('sections.publicationDetails') }}
                </h2>
              </div>
            </div>
            <div class="card-row">
              <div class="card-row__text">
                <slot name="publicationDetails" />
              </div>
            </div>
          </div>

          <div class="card">
            <slot name="content" />
          </div>
        </div>
        <aside class="publication-main__right">
          <div class="card">
            <div class="card-row">
              <div class="card-row__text">
                <files-part
                  class="record-field"
                  :record-id="record.metadata.id"
                  :files="record.files"
                  :date-available="record.dateAvailable"
                  :rights="record.rights"
                />
                <div class="record-field">
                  <button
                    class="btn btn--light"
                    @click="isModalOpen = true"
                  >
                    {{ $t('sections.citePublication') }}
                  </button>

                  <Modal
                    v-if="isModalOpen"
                    v-model="isModalOpen"
                    class="modal--md"
                  >
                    <div
                      slot="modal-panel"
                    >
                      <h1>{{ $t('modal.title') }}</h1>
                      <div class="flex">
                        <h2 class="h5--ssp">
                          {{ $t('modal.apa') }}
                        </h2>
                        <button
                          class="copy"
                          @click="copyToClipboard(formatAPA(record))"
                        >
                          {{ copied ? $t('modal.copied') : $t('modal.copy') }}
                        </button>
                      </div>
                      <p class="apa">
                        {{ formatAPA(record) }}
                      </p>

                      <h2 class="h5--ssp">
                        {{ $t('modal.download') }}
                      </h2>
                      <a
                        class="export-link"
                        :href="`/api/records/${record.metadata.id}/format/bibtex`"
                      >
                        {{ $t('modal.bibtex') }}
                      </a>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          <slot name="sideCard">
            <ReportPart
              v-if="$auth.isLogged()"
              :record="record"
            />
          </slot>
        </aside>
      </main>
    </article>
  </div>
</template>
<script>
import FilesPart from '@/components/records/views/parts/FilesPart.vue'
import ReportPart from '@/components/records/reports/ReportPart.vue'
import WarningBox from '@/components/utils/WarningBox.vue'
import Modal from '@/components/utils/Modal.vue'

export default {
  name: 'SingleRecordLayout',
  components: {
    Modal,
    FilesPart,
    ReportPart,
    WarningBox
  },
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isModalOpen: false,
      copied: false,
      formatAPA: null
    }
  },
  async created () {
    const { formatAPA } = await import('@scholar/metadata-utils/main')
    this.formatAPA = formatAPA
  },
  methods: {
    async copyToClipboard (text) {
      await navigator.clipboard.writeText(text)
      this.copied = true
    }
  },
  i18n: {
    messages: {
      pt: {
        sections: {
          keyInformation: 'Informações chave',
          publicationDetails: 'Detalhes da publicação',
          citePublication: 'Citar publicação'
        },
        warningBox: {
          badge: 'Atenção!',
          text: 'Esta publicação contém dados incompletos ou incorretos, de acordo com as “OpenAIRE Guidelines for Literature Repository Managers 4.0” — os campos com inconsistências detetadas serão destacados até que se resolvam as informações inconsistentes desta publicação.'
        },
        modal: {
          title: 'Citar publicação',
          download: 'Descarregar citação',
          apa: 'APA',
          bibtex: 'BibTeX',
          copy: 'Copiar',
          copied: 'Copiado'
        }
      },
      en: {
        sections: {
          keyInformation: 'Key information',
          publicationDetails: 'Publication details',
          citePublication: 'Cite publication'
        },
        warningBox: {
          badge: 'Attention!',
          text: 'This publication has incomplete or inconsistent data according to the OpenAIRE Guidelines for Literature Repository Managers 4.0 — the fields with detected inconsistencies will be highlighted until these inconsistencies are resolved.'
        },
        modal: {
          title: 'Cite publication',
          download: 'Download citation',
          apa: 'APA',
          bibtex: 'BibTeX',
          copy: 'Copy',
          copied: 'Copied'
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.export-link {
  text-decoration: underline #45555f dotted;
  text-underline-offset: 0.25rem;
  cursor: pointer;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.apa {
  margin-bottom: 1rem;
}

.copy {
  color: var(--blue);
}

</style>
