<script>
/**
 * Takes the root node as the `value` prop. Descendants of a node should exist
 * inside the node's `children` field (an array of similar nodes).
 *
 * The default slot must define how each node is rendered. The slot takes the
 * scoped context:
 * * `node` - the value of the node being rendered
 * * `isOpen` - whether the node is showing children nodes or not
 * * `toggle` - a function that toggles the open state of the node
 * * `level` - the node's depth within the tree, by default starts at 1 for the
 * root node.
 *
 * When the `toggle()` function is called for a node, Tree component will emit
 * the `open` event if the node is going to open, and the `close` event otherwise.
 * The value emitted with the event is the node that triggered the `toggle()` call.
 *
 * By default, Tree expects the node tree to be complete. However, you can also
 * load node children asynchronously, by making use of the `open` event. When
 * doing so, you must explicitly set the node's `children` array using
 * `Vue.set(node, 'children', childrenArray)`, otherwise the view update will
 * not be triggered.
 */
export default {
  name: 'Tree',
  props: {
    value: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    children () {
      return this.value.children ?? []
    }
  },
  methods: {
    toggleNode () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$emit('open', this.value)
      } else {
        this.$emit('close', this.value)
      }
    }
  },
  /**
   * Renders:
   *
   * <div class="tree__container"> <!-- div.tree__container wraps the whole tree, only wraps root node -->
   *   <div class="tree__node">
   *     <slot ... /> <!-- the default slot renders the node -->
   *   </div>
   *   <!-- if there are children to render -->
   *   <div class="tree__children [hidden]"> <!-- if node is closed, the "hidden" class is added -->
   *     <!-- for each child -->
   *     <div class="tree__child">
   *       <Tree .../> <!-- a Tree component renders a child -->
   *     </div>
   *   </div>
   * </div>
   */
  render (el) {
    const nodeChildren = this.children.length === 0
      ? []
      : el('div', {
        class: ['tree__children', { hidden: !this.isOpen }],
        ...!this.isOpen && { style: { display: 'none' } } // emulates v-show
      }, [
        this.children.map((child) =>
          el('Tree', {
            class: 'tree__child',
            props: { value: child, level: this.level + 1 },
            scopedSlots: { default: this.$scopedSlots.default },
            on: {
              open: (nodeValue) => {
                this.$emit('open', nodeValue)
              },
              close: (nodeValue) => {
                this.$emit('close', nodeValue)
              }
            }
          })
        )
      ])

    const node = el('div', { class: 'tree__node' }, [
      this.$scopedSlots.default({ node: this.value, isOpen: this.isOpen, toggle: this.toggleNode, level: this.level })
    ])

    return el('div', this.level === 1 ? { class: 'tree__container' } : null, [node].concat(nodeChildren))
  }
}
</script>
