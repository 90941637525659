import client from '@/api/client'

/**
 * Get a single researcher using his userId
 * @param {object} object Root object
 * @param {string} object.userId The id of the user/researcher to get
 * @param {boolean} object.countView If the viewed counter appears in the response
 * @returns returns with the researcher data
 */
export async function get ({ userId, countView }) {
  const response = await client.get(`/researchers/${userId}`, { params: { countView } })
  return response.data
}

/** @typedef {{ items: [], totalItems: number }} Page */

/**
 * Get an array of researchers based on an array of userIds or a query string
 * @param {object} object Root object
 * @param {string} [object.q] Query to do the search
 * @param {Array} [object.users]  A list of users to get (when set all other params are ignored)
 * @param {number} [object.page] The page number that you are trying to get
 * @param {number} [object.perPage] The number of results per page
 * @returns {Promise<Page>} researchersPage
 */
export async function list ({ users, page, perPage, q }) {
  let params
  if (users && users.length) {
    params = { users }
  } else {
    params = {
      ...(q && { q }),
      skip: (page - 1) * perPage,
      limit: perPage
    }
  }
  const response = await client.get('/researchers', { params })
  return response.data
}

/**
 * Get an array of records for a given user, a search query can be used
 * @param {object} object Root object
 * @param {string} object.userId The id of the user/researcher to get
 * @param {string} [object.q] Query to do the search
 * @param {number} object.page The page number that you are trying to get
 * @param {number} object.perPage The number of results per page
 * @param {any} [object.sort] Used to sort the final result
 * @param {Array<string>} [object.recordTypes] Used to filter by record types
 * @param {string} [object.start] Used to filter by start date
 * @param {string} [object.end] Used to filter by end date
 * @returns {Promise<Page>} recordsPage
 */
export async function getRecords ({ userId, page, perPage, sort, q, recordTypes, start, end }) {
  const response = await client.get(`/researchers/${userId}/records`, {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      ...(sort && { sort }),
      ...(q && { q }),
      ...(recordTypes && { type: recordTypes }),
      ...(start?.year && { start: `${start.year}-${start.month ?? 1}-${start.day ?? 1}` }),
      ...(end?.year && { end: `${end.year}-${end.month ?? 12}-${end.day ?? 31}` })
    }
  })
  return response.data
}

/**
 * Get an array of records for a given user, a search query can be used
 * @param {object} object Root object
 * @param {string} object.userId The id of the user/researcher to get
 * @param {string} [object.q] Query to do the search
 * @param {number} object.page The page number that you are trying to get
 * @param {number} object.perPage The number of results per page
 * @param {any} [object.sort] Used to sort the final result
 * @param {Array<string>} [object.recordTypes] Used to filter by record types
 * @returns {Promise<Page>} recordsPage
 */
export async function getAdvisedRecords ({ userId, page, perPage, sort, q, recordTypes }) {
  const response = await client.get(`/researchers/${userId}/advised-records`, {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      ...(sort && { sort }),
      ...(q && { q }),
      ...(recordTypes && { type: recordTypes })
    }
  })
  return response.data
}

/**
 * Gets the list of affiliations of a given user
 * @param {object} object Root object
 * @param {string} object.userId The id of the user/researcher to get
 * @param {number} object.page The page number that you are trying to get
 * @param {number} object.perPage The number of results per page
 * @param {any} [object.sort] Optional param used to sort the final result
 * @returns {Promise<Page>} affiliationsPage
 */
export async function getAffiliations ({ userId, page, perPage, sort }) {
  const response = await client.get(`/researchers/${userId}/affiliations`, {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      ...(sort && { sort })
    }
  })
  return response.data
}

/**
 * Gets an affiliation of a given user
 * @param {object} object Root object
 * @param {string} object.userId The id of the user/researcher
 * @param {string} object.affiliationId The id of the affiliation to get
 * @returns Response data
 */
export async function getAffiliation ({ userId, affiliationId }) {
  const response = await client.get(`/researchers/${userId}/affiliations/${affiliationId}`)
  return response.data
}

/** @typedef {{ year: number, month: number }} Date */

/**
 * Adds an affiliation to a given user
 * @param {object} object Root object
 * @param {string} object.userId The id of the user/researcher
 * @param {string} object.unitId The id of the unit that the researcher will be affiliated to
 * @param {Date} object.start The start date
 * @param {Date} object.end The end date
 * @returns Response data
 */
export async function addAffiliation ({ userId, unitId, start, end }) {
  const response = await client.post(`/researchers/${userId}/affiliations`, {
    unitId,
    start,
    end
  })
  return response.data
}

/**
 * Updates an affiliation of a given user
 * @param {object} object Root object
 * @param {string} object.userId The id of the user/researcher
 * @param {string} object.affiliationId The id of the affiliation that you are trying to update
 * @param {Date} [object.start] The start date
 * @param {Date} [object.end] The end date
 * @param {boolean} [object.verified] The verified flag
 * @returns Response data
 */
export async function updateAffiliation ({ userId, affiliationId, start, end, verified }) {
  const response = await client.put(`/researchers/${userId}/affiliations/${affiliationId}`, {
    ...(start !== undefined && { start }),
    ...(end !== undefined && { end }),
    ...(verified !== undefined && { verified })
  })
  return response.data
}

/**
 * Removes an affiliation of a given user
 * @param {object} object Root object
 * @param {string} object.userId The id of the user/researcher
 * @param {string} object.affiliationId The id of the affiliation that you are trying to delete
 * @returns Response data
 */
export async function removeAffiliation ({ userId, affiliationId }) {
  const response = await client.delete(`/researchers/${userId}/affiliations/${affiliationId}`)
  return response.data
}

/**
 * Gets the a paginated array of co-authors for a given user
 * @param {object} object Root object
 * @param {string} object.userId The id of the user/researcher to get
 * @param {string} [object.q] Query to do the search
 * @param {number} object.page The page number that you are trying to get
 * @param {number} object.perPage The number of results per page
 * @param {any} [object.sort] Optional param used to sort the final result
 * @returns {Promise<Page>} coAuthoursPage
 */
export async function getCoAuthors ({ userId, page, perPage, sort, q }) {
  const response = await client.get(`/researchers/${userId}/co-authors`, {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      ...(sort && { sort }),
      ...(q && { q })
    }
  })
  return response.data
}

/**
 * The biography of a researcher
 * @typedef {{short: string, extended: string}} Bio
 */

/**
 * Get the bio for a given user
 * @param {object} object Root object
 * @param {string} object.userId Id of the user to get the bio from
 * @returns {Promise<Bio>} Returns an object with a short and extended bio
 */
export async function getBio ({ userId }) {
  const response = await client.get(`/researchers/${userId}/bio`)
  return response.data
}

/**
 * Get a list of researchers based on interest
 * @param {object} object Root object
 * @param {string} object.scientificInterest The id of the user/researcher to get
 * @param {number} object.page Page to retrieve
 * @param {number} object.perPage Number of items to be returned per page
 * @returns returns with the researcher data
 */
export async function listByScientificInterest ({ scientificInterest, page, perPage }) {
  const response = await client.get('researchers', {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      scientificInterest
    }
  })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {string} root0.userId The user id compute the scientific production
 * @param {Date} root0.start Start of the date interval
 * @param {Date} root0.end End of the date interval
 */
export async function getScientificProduction ({ userId, start, end }) {
  const response = await client.get(`/researchers/${userId}/scientific-production`, {
    params: {
      ...(start && { start }),
      ...(end && { end })
    }
  })
  return response.data
}

export default {
  get,
  list,
  getRecords,
  getAffiliations,
  addAffiliation,
  updateAffiliation,
  getAffiliation,
  removeAffiliation,
  getCoAuthors,
  getBio,
  listByScientificInterest,
  getScientificProduction
}
