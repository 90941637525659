<template>
  <figure
    v-if="!src"
    class="figure figure--avatar"
  >
    <svg
      viewBox="0 0 64 58"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(2 1)"
        stroke="var(--gray)"
        stroke-width="2"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle
          cx="30.2"
          cy="27.931"
          r="4.286"
        />
        <ellipse
          cx="30.2"
          cy="28"
          rx="11.2"
          ry="28"
        />
        <ellipse
          transform="rotate(-60 30.2 28)"
          cx="30.2"
          cy="28"
          rx="11.2"
          ry="28"
        />
        <ellipse
          transform="rotate(60 30.2 28)"
          cx="30.2"
          cy="28"
          rx="11.2"
          ry="28"
        />
      </g>
    </svg>
    <div
      aria-hidden="true"
      style="visibility: hidden;"
    >
      {{ name.charAt(0) }}
    </div>
  </figure>
  <Avatar
    v-else
    :src="src"
    :name="name"
    :icon="icon"
  />
</template>

<script>
import Avatar from '@/components/utils/Avatar.vue'
export default {
  components: {
    Avatar
  },
  props: {
    src: {
      type: String,
      default: undefined
    },
    icon: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    }
  }
}
</script>
