<template>
  <div class="unsubscribe">
    <div class="layout-centered">
      <svg
        width="80"
        height="71"
        viewBox="0 0 80 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="email">
          <path
            id="Path 53"
            d="M40.125 69.5H3.25C2.55964 69.5 2 68.9404 2 68.25V27L11.9311 20.1342V9.5H28.25L40.125 2L52 9.5H68.25V20.1342L78.25 27"
            stroke="#666666"
            stroke-width="2.5"
          />
          <path
            id="Path 53_2"
            d="M2 27L12 20.1342V9.5H28.25L40.125 2L52 9.5H68.3189V20.1342L78.25 27V68.25C78.25 68.9404 77.6904 69.5 77 69.5H40.125V69.5"
            stroke="#666666"
            stroke-width="2.5"
          />
          <path
            id="Path 55"
            d="M12 36.375V9.5H68.25V35.125"
            stroke="#666666"
            stroke-width="2.5"
          />
          <path
            id="Path 54"
            d="M2 28.875L40.125 55.75L78.25 28.25"
            stroke="#666666"
            stroke-width="2.5"
          />
          <path
            id="Path 17"
            d="M43.4364 40.3149C43.4364 40.3149 40.9909 41.6088 38.3207 41.4011C35.6506 41.1935 30.1159 38.9396 30.716 32.4952C31.3162 26.0508 36.4076 22.3759 40.8222 22.6043C45.2369 22.8327 48.3779 25.423 48.4213 30.1865C48.4646 34.95 45.5116 36.1436 44.0618 36.2128C42.6121 36.2821 41.777 35.0347 41.9834 33.6468C42.1899 32.2588 43.2391 27.4178 43.2391 27.4178"
            stroke="#666666"
            stroke-width="2.5"
          />
          <path
            id="Path 56"
            d="M42.6669 29.7517C42.6669 29.7517 41.025 28.2588 39.7588 28.2588C38.4927 28.2588 35.7086 29.5372 36.0562 33.3754C36.2081 35.053 37.1524 36.2968 38.7829 35.8888C39.6394 35.6745 41.9113 33.7735 41.9113 33.7735"
            stroke="#666666"
            stroke-width="2.5"
          />
        </g>
      </svg>
      <h1>A sua subscrição foi anulada</h1>
      <p>A sua subscrição foi anulada com sucesso, não voltará a receber emails do Scholar. Poderá alterar as suas preferências a qualquer altura nas definições da sua conta.</p>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss">
.unsubscribe {
  display: flex;
  flex-grow: 1;
  background: #fff;
}
.unsubscribe .layout-centered {
  text-align: center;
  width: 100%;
  max-width: 44.125rem;
  padding: 1.5rem;
  margin: 3rem auto;

  @media screen and (width >= 47.5rem) {
    text-align: center;
    margin: auto;
    padding: 1rem;
  }
}

h1.unsubscribe__title {
  @media screen and (width <= 60rem) {
    margin: 2rem 0 0.5rem;
  }
}
</style>
