<template>
  <div
    class="checkbox-list"
    :class="{'checkbox-list--columns': columns > 0}"
    @change="emitChanges"
  >
    <template v-if="columns === 1">
      <div
        v-for="(optionsValue, optionKey) in optionsMap"
        :key="optionKey"
        class="f-field f-field--checkbox"
      >
        <input
          :id="`checkbox-list-${fieldName}-${optionKey}`"
          v-model="optionsMap[optionKey]"
          class="f-field__checkbox"
          type="checkbox"
        >
        <label
          class="f-field__label"
          :for="`checkbox-list-${fieldName}-${optionKey}`"
        >
          {{ $t(`${fieldName}.${optionKey}`) }}
        </label>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(row, index) in rows"
        :key="index"
        class="checkbox-list"
        :class="`cols-${columns}`"
      >
        <div
          v-for="optionKey in row"
          :key="optionKey"
          class="f-field f-field--checkbox"
        >
          <input
            :id="`checkbox-list-${fieldName}-${optionKey}`"
            v-model="optionsMap[optionKey]"
            class="f-field__checkbox"
            type="checkbox"
          >
          <label
            class="f-field__label"
            :for="`checkbox-list-${fieldName}-${optionKey}`"
          >
            {{ $t(`${fieldName}.${optionKey}`) }}
          </label>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import chunk from 'underscore/modules/chunk'

export default {
  props: {
    fieldName: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    columns: {
      type: Number,
      default: 1,
      validator: prop => Number.isInteger(prop) && prop > 0 && prop < 4
    }
  },
  data () {
    return {
      optionsMap: this.options.reduce((acc, type) => {
        acc[type] = false
        return acc
      }, {})
    }
  },
  computed: {
    rows () {
      if (this.columns === 1) {
        return this.options
      }
      return chunk(this.options, this.columns)
    },
    selectedOptions () {
      return Object.keys(this.optionsMap).filter(key => this.optionsMap[key])
    }
  },
  watch: {
    value (newVal) {
      newVal.forEach(type => {
        this.optionsMap[type] = true
      })
    }
  },
  created () {
    this.value.forEach(type => {
      this.optionsMap[type] = true
    })
  },
  methods: {
    emitChanges () {
      this.$emit('input', this.selectedOptions)
    }
  }
}
</script>
<style lang="scss">
.checkbox-list--columns {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  .cols-2,
  .cols-3,
  .cols-4,
  .cols-5,
  .cols-6 {
    width: 100%;
  }
  @media screen and (width >= 75rem) {
    .cols-2 {
      width: 50%;
    }
    .cols-3 {
      width: 33.33%;
    }
    .cols-4 {
      width: 25%;
    }
    .cols-5 {
      width: 20%;
    }
    .cols-6 {
      width: 16.66%;
    }
  }
}
</style>
