import Authors from '@/router/public/authors'
import Records from '@/router/public/records'
import Units from '@/router/public/units'

import { listByScientificInterest } from '@/api/researchers'
import { types as recordTypes } from '@scholar/metadata-utils/main'
import without from 'underscore/modules/without'

import SearchPage from '@/pages/SearchPage'
import ScientificInterestsPage from '@/pages/ScientificInterestsPage'
import UnsubscribeEmail from '@/pages/UnsubscribeEmailPage'
import { unsubscribeEmail } from '@/api/users'
import ErrorPage from '@/pages/ErrorPage.vue'

const SearchResultsPage = () => import('@/pages/SearchResultsPage')

/** @type {import('vue-router').Route} */
export default [
  {
    path: '/',
    name: 'SearchPage',
    component: SearchPage,
    meta: {
      pageInfo: 'search',
      layout: 'PageWithNavBarAndFooterLayout'
    }
  },
  {
    path: '/interests',
    name: 'ScientificInterestsPage',
    component: ScientificInterestsPage,
    props: route => ({
      page: Number(route.query.page) || 1,
      authors: route.params.authors,
      q: route.query.q,
      perPage: Number(route.query.perPage) || undefined
    }),
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      scrollBehavior: {
        routeUpdate: 'savedPosition'
      }
    },
    async beforeEnter (to, from, next) {
      const page = to.query.page || 1
      to.params.perPage = 10
      if (!to.query.q) {
        return next({ ...(from ?? { path: '/' }), replace: true })
      }
      to.params.authors = await listByScientificInterest({ scientificInterest: to.query.q, page, perPage: to.params.perPage })
      next()
    }
  },
  {
    path: '/search',
    name: 'SearchResultsPage',
    component: SearchResultsPage,
    props: route => ({
      q: route.query.q,
      domain: route.query.domain,
      page: Number(route.query.page) || 1,
      perPage: Number(route.query.perPage) || undefined,
      type: typeof route.query.type === 'string' ? [route.query.type] : route.query.type,
      sort: route.query.sort,
      openAdvancedSearch: route.query.advancedSearchOpen
    }),
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      scrollBehavior: {
        routeUpdate: 'savedPosition'
      }
    },
    beforeEnter (to, from, next) {
      if (!['records', 'units', 'authors'].includes(to.query.domain)) {
        // unknown domain
        return next({ name: 'SearchPage' })
      }
      const type = typeof to.query.type === 'string' ? [to.query.type] : to.query.type
      if (without(type, ...recordTypes).length > 0) {
        // there are unknown types in the query params
        return next({ name: 'SearchPage' })
      }
      next()
    }
  },
  {
    path: '/unsubscribe-reminder-email',
    name: 'Unsubscribe',
    component: UnsubscribeEmail,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      scrollBehavior: {
        routeUpdate: 'savedPosition'
      }
    },
    beforeEnter: async (to, from, next) => {
      await unsubscribeEmail(to.query.token)
      next()
    }
  },
  {
    path: '/error-page',
    name: 'ErrorPage',
    component: ErrorPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout'
    },
    props: route => ({ error: route.params.error }),
    beforeEnter: (to, from, next) => {
      if (!!to.params.error && to.params.error instanceof Error) {
        next()
      }
    }
  },
  ...Authors,
  ...Records,
  ...Units
]
