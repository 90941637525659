<template>
  <section>
    <header class="section-header">
      <div class="section-header__text">
        <h1 class="section-header__title">
          {{ $t('researcher.public.profile.affiliations.header') }}
        </h1>
        <p>{{ $t('researcher.public.profile.affiliations.description') }}</p>
      </div>
    </header>
    <Transition
      name="fade"
      mode="out-in"
    >
      <section
        v-if="isLoading || (affiliations && affiliations.totalItems > 0)"
        key="researcher-affiliations-ok-or-loading"
      >
        <Transition
          name="fade"
          mode="out-in"
        >
          <AffiliationSkeleton
            v-if="isLoading"
            key="researcher-affiliations-loading"
            :count="6"
            class="card-list"
          />
          <ul
            v-else
            class="card card-list"
          >
            <Affiliation
              v-for="affiliation in affiliations.items"
              :key="affiliation.id"
              tag="li"
              :value="affiliation"
              :clickable="true"
              class="card-row"
            />
          </ul>
        </Transition>
        <PaginationBar
          v-if="affiliations"
          :current-page="page"
          :total-items="affiliations.totalItems"
          :items-per-page="perPage"
        />
      </section>
      <AffiliationEmptyState
        v-else
        key="researcher-affiliations-empty"
      >
        <template #title>
          {{ $t('researcher.public.profile.affiliations.empty.state.header') }}
        </template>
        <template #description>
          {{ $t('researcher.public.profile.affiliations.empty.state.description') }}
        </template>
      </AffiliationEmptyState>
    </Transition>
  </section>
</template>

<script>
import PaginationBar from '@/components/utils/PaginationBar.vue'
import AffiliationEmptyState from '@/components/empty-states/Affiliation.vue'
import AffiliationSkeleton from '@/components/loading-skeletons/Affiliation.vue'
import { getAffiliations } from '@/api/researchers'
import Affiliation from '@/components/researchers/affiliations/Affiliation.vue'

export default {
  components: {
    PaginationBar,
    AffiliationEmptyState,
    AffiliationSkeleton,
    Affiliation
  },
  beforeRouteUpdate (to, from, next) {
    clearTimeout(this.navigationTimeout)
    this.isLoading = true
    this.navigationTimeout = setTimeout(async () => {
      await this.getAffiliations({ page: Number(to.query.page) || 1 })
    })
    localStorage.setItem('perPage', to.query.perPage)
    next()
  },
  props: {
    author: {
      type: Object,
      required: true
    },
    page: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: Number(localStorage.getItem('perPage')) || 10
    }
  },
  data () {
    return {
      affiliations: undefined,
      isLoading: true,
      navigationTimeout: undefined
    }
  },
  metaInfo: function () {
    return {
      title: this.$t('title'),
      meta: [{ name: 'description', content: this.$t('description') }]
    }
  },
  beforeDestroy () {
    clearTimeout(this.navigationTimeout)
  },
  async created () {
    await this.getAffiliations({ page: this.page })
  },
  methods: {
    async getAffiliations ({ page }) {
      this.isLoading = true
      this.affiliations = await getAffiliations({ userId: this.author.id, page, perPage: this.perPage })
      this.isLoading = false
    }
  },
  i18n: {
    messages: {
      pt: {
        title: 'Afiliações',
        description: 'Uma lista com todas as afiliações do autor'
      },
      en: {
        title: 'Affiliations',
        description: 'A list with all affiliations of the author'
      }
    }
  }
}
</script>
