<template>
  <div>
    <header class="section-header">
      <div class="section-header__text">
        <h1 class="section-header__title">
          {{ $t('unit.public.profile.members.header') }}
        </h1>
        <p>{{ $t('unit.public.profile.members.description') }}</p>
      </div>
    </header>
    <div class="tab-navigation">
      <ul class="tab-navigation__list">
        <li
          class="tab-navigation__list-item"
          :class="{'tab-navigation__list-item--selected': $route.name === 'UnitCurrentMembersPage'}"
        >
          <RouterLink
            class="tab-navigation__link"
            :to="{
              name: 'UnitCurrentMembersPage',
              ...(searchQuery !== '' && { query: { q: searchQuery } })
            }"
          >
            {{ $t('settings.management.unit.affiliations.tab.current') }}<span
              v-if="($route.name === 'UnitCurrentMembersPage' && members?.totalItems)"
              style="margin-left: 0.25rem;"
            > ({{ members.totalItems }})</span>
          </RouterLink>
        </li>
        <li
          class="tab-navigation__list-item"
          :class="{'tab-navigation__list-item--selected': $route.name === 'UnitPastMembersPage'}"
        >
          <RouterLink
            class="tab-navigation__link"
            :to="{
              name: 'UnitPastMembersPage',
              ...(searchQuery !== '' && { query: { q: searchQuery } })
            }"
          >
            {{ $t('settings.management.unit.affiliations.tab.past') }}<span
              v-if="($route.name === 'UnitPastMembersPage' && members?.totalItems)"
              style="margin-left: 0.25rem;"
            > ({{ members.totalItems }})</span>
          </RouterLink>
        </li>
      </ul>
    </div>
    <SearchPaginatedList
      :is-initial-loading="isInitialLoading"
      :is-searching="isSearching"
      :query="searchQuery"
      :page-data="members"
    >
      <template #search-bar="{buttonAttrs, inputAttrs}">
        <div class="f-field-search">
          <button
            class="f-field-search__submit"
            v-bind="buttonAttrs"
          />
          <input
            v-model.trim="searchQuery"
            :placeholder="$t('search.members')"
            type="search"
            class="f-field-search__input f-field-search__input--card"
            v-bind="inputAttrs"
          >
        </div>
      </template>
      <template #list-skeleton>
        <UserSkeleton
          :count="4"
          class="card-list"
        />
      </template>
      <template #list>
        <ul class="card card-list">
          <li
            v-for="member in members.items"
            :key="member.id"
            class="card-row"
          >
            <UserAvatar
              :src="member.avatar"
              class="card-row__figure"
              name="user.name"
            />
            <div class="card-row__text">
              <h1 class="card-row__title h5--ssp">
                <RouterLink
                  :to="{ name: 'ResearcherPage', params: { userId: member.username } }"
                  class="card-row__link"
                >
                  {{ member.name }}
                </RouterLink>
              </h1>
              <p>{{ member.username }}</p>
            </div>
            <div
              v-if="$auth.isLogged() &&(profile.roles.includes('admin') || profile.roles.includes('operator') || profile.units.includes(unitID))"
              class="card-row__meta card-row__meta--align-start"
            >
              <Dropdown>
                <button
                  slot="dropdown-trigger"
                  class="i-ellipsis"
                  aria-label="More options"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click.prevent=""
                />
                <template slot="dropdown-panel">
                  <ul class="dropdown-menu dropdown--xs">
                    <li class="dropdown-menu__item">
                      <button
                        class="dropdown-menu__link"
                        @click.prevent="editAffiliation(member)"
                      >
                        {{ $t('settings.management.unit.affiliations.edit.affiliation') }}
                      </button>
                    </li>
                    <li class="dropdown-menu__item">
                      <button
                        class="dropdown-menu__link u-text-danger"
                        @click.prevent="removeAffiliation(member)"
                      >
                        {{ $t('settings.management.unit.affiliations.remove.affiliation') }}
                      </button>
                    </li>
                  </ul>
                </template>
              </Dropdown>
            </div>
          </li>
        </ul>
      </template>
      <template #pagination>
        <PaginationBar
          :current-page="currentPage"
          :total-items="members.totalItems"
          :items-per-page="perPage"
        />
      </template>
      <template #empty-state="{ isAlwaysEmpty }">
        <PersonEmptyState>
          <template #title>
            <template v-if="isAlwaysEmpty">
              {{ $t('unit.public.profile.members.empty.state.always.empty.header') }}
            </template>
            <template v-else>
              {{ $t('unit.public.profile.members.empty.state.search.results.header', { query: emptyStateSearchQuery }) }}
            </template>
          </template>
          <template #description>
            <template v-if="isAlwaysEmpty">
              {{ $t('unit.public.profile.members.empty.state.always.empty.description') }}
            </template>
            <template v-else>
              {{ $t('unit.public.profile.members.empty.state.search.results.description') }}
            </template>
          </template>
        </PersonEmptyState>
      </template>
    </SearchPaginatedList>
  </div>
</template>
<script>
import UserAvatar from '@/components/utils/UserAvatar.vue'
import Dropdown from '@/components/utils/Dropdown.vue'
import PaginationBar from '@/components/utils/PaginationBar.vue'
import PersonEmptyState from '@/components/empty-states/Person.vue'
import UserSkeleton from '@/components/loading-skeletons/User.vue'
import { getMembers } from '@/api/units'
import SearchPaginatedList from '@/components/utils/SearchPaginatedList.vue'
import * as ResearcherAPI from '@/api/researchers'
import { mapState } from 'vuex'

export default {
  components: {
    UserAvatar,
    PaginationBar,
    PersonEmptyState,
    UserSkeleton,
    SearchPaginatedList,
    Dropdown
  },
  beforeRouteUpdate (to, from, next) {
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    this.isSearching = true
    this.members = undefined
    this.searchTimeout = setTimeout(async () => {
      this.emptyStateSearchQuery = to.query.q

      this.currentPage = Number(to.query.page) || 1
      this.members = await getMembers({ unitID: this.unitID, page: this.currentPage, perPage: this.perPage, q: to.query.q, ongoing: this.ongoing })
      this.isSearching = false
    }, 500)
    localStorage.setItem('perPage', to.query.perPage)
    next()
  },
  props: {
    unitID: {
      type: String,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    q: {
      type: String,
      default: ''
    },
    perPage: {
      type: Number,
      default: Number(localStorage.getItem('perPage')) || 10
    }
  },
  metaInfo: function () {
    return {
      title: this.$t('title'),
      meta: [{ name: 'description', content: this.$t('description') }]
    }
  },
  data () {
    return {
      isInitialLoading: true,
      isSearching: false,
      currentPage: this.page,
      members: undefined,
      searchQuery: this.q,
      searchTimeout: undefined

    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    ongoing () {
      return this.$route.path.endsWith('current')
    }
  },
  watch: {
    async searchQuery (newValue, oldValue) {
      if (newValue === oldValue) return

      const query = { ...this.$route.query, q: newValue }
      if (newValue === '') {
        delete query.q
      }
      delete query.page
      await this.$router.replace({ query })
    }
  },
  beforeDestroy () {
    clearTimeout(this.searchTimeout)
  },
  async mounted () {
    this.members = await getMembers({ unitID: this.unitID, page: this.currentPage, perPage: this.perPage, q: this.q, ongoing: this.ongoing })
    this.isInitialLoading = false
    // purposefully makes search query non-reactive to display on empty state
    this.emptyStateSearchQuery = this.searchQuery
  },
  methods: {
    async editAffiliation (member) {
      const affiliation = member.affiliations.find(a => a.unitId === this.unitID)
      this.$router.push({ name: 'EditUnitAffiliationPage', params: { unitId: this.unitID, affiliationId: affiliation.id, userId: member.id } })
    },
    async removeAffiliation (member) {
      const affiliation = member.affiliations.find(a => a.unitId === this.unitID)
      await ResearcherAPI.removeAffiliation({ userId: member.id, affiliationId: affiliation.id })
      this.members = await getMembers({ unitID: this.unitID, page: this.currentPage, perPage: this.perPage, q: this.q })
    }
  },
  i18n: {
    messages: {
      pt: {
        title: 'Membros',
        description: 'Uma lista com todos os membros desta unidade'
      },
      en: {
        title: 'Members',
        description: 'A list with all publications made by members of this unit'
      }
    }
  }
}
</script>
