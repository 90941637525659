<template>
  <section>
    <header class="layout-sidebar__main-header section-header">
      <div class="section-header__text">
        <h1 class="section-header__title">
          {{ $t('researcher.public.profile.bio.header') }}
        </h1>
        <p>{{ $t('researcher.public.profile.bio.description') }}</p>
      </div>
    </header>
    <div
      v-if="author.bio"
      class="card"
    >
      <div class="card-row">
        <div class="card-row__text">
          <div class="card-row__title h4">
            {{ $t('researcher.public.profile.bio.card.header') }}
          </div>
        </div>
      </div>

      <div class="card-row">
        <div class="card-row__text">
          <!-- Bellow is a hot glue fix to render '\n', use proper style class later -->
          <p style="white-space: pre-line;">
            {{ author.bio.extended }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    author: {
      type: Object,
      required: true
    }
  },
  metaInfo: function () {
    return {
      title: this.$t('title'),
      meta: [{ name: 'description', content: this.$t('description') }]
    }
  },
  i18n: {
    messages: {
      pt: {
        title: 'Biografia',
        description: 'Uma breve biografia do autor'
      },
      en: {
        title: 'Biography',
        description: 'A short biography of the author'
      }
    }
  }
}
</script>
