<template>
  <div class="record-skeleton card-row">
    <div class="card-row__text">
      <p class="small record-skeleton__type loading-text">
        Loading publication type
      </p>
      <p class="h4 record-skeleton__title">
        <span class="record-skeleton__title-link loading-text">
          Loading the publication title
        </span>
      </p>
      <div class="record-skeleton__publishing-data">
        <p class="record-skeleton__academic-journal loading-text">
          Loading template jornal
        </p>
        <p>
          <span class="record-skeleton__publication-date loading-text">
            year
          </span>
          <span class="record-skeleton__authors">
            <span class="record-skeleton__author loading-text">
              Author's Name ,
            </span>
            <span class="loading-text">
              et al.
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.record-skeleton__type {
  text-transform: capitalize;
}
.record-skeleton__title {
  margin-top: 0.5rem;
  margin-bottom: 0;
  position: relative;
}
.record-skeleton__title-link {
  cursor: pointer;
  color: $dark;
  &::before {
    content: "";
    position: absolute;
    cursor: pointer;
    padding-top: 2rem;
    inset: -2rem 0 0;

    // background-color: rgba($blue, .4);
  }
}
.record-skeleton__academic-journal {
  color: $gray;
  line-height: 1.2;
}
.record-skeleton__author {
  color: $slate;
}
.record-skeleton__publishing-data {
  p {
    margin: 0.25rem 0;
  }
}
</style>
