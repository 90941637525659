<template>
  <div
    v-if="$slots.default"
    ref="tooltipRef"
    class="tooltip"
    :class="tooltipClasses"
  >
    <button
      class="tooltip__trigger"
      @click.prevent="toggleTooltip"
    >
      i
    </button>
    <div
      ref="tooltipbox"
      class="tooltip__box"
    >
      <div class="tooltip__container">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import { createFocusTrap } from 'focus-trap'
export default {
  props: {
    openLeft: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isOpenLeft: this.openLeft,
      isTooltipOpen: false,
      resultingTop: undefined,
      trap: null
    }
  },
  computed: {
    tooltipClasses () {
      return [
        this.openLeft ? 'tooltip--opens-to-left' : 'tooltip--opens-to-right',
        this.isTooltipOpen ? 'tooltip--open' : ''
      ]
    }
  },
  mounted () {
    this.setPosition()
    window.addEventListener('resize', this.setPosition)
    this.trap = createFocusTrap(this.$refs.tooltipRef, { clickOutsideDeactivates: true, onActivate: () => (this.isTooltipOpen = true), onDeactivate: () => (this.isTooltipOpen = false) })
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.setPosition)
  },
  methods: {
    toggleTooltip () {
      if (this.isTooltipOpen) {
        this.trap.deactivate()
      } else {
        this.trap.activate()
      }
    },
    closeTooltip () {
      this.trap.deactivate()
    },
    setPosition () {
      const elLeft = this.$refs.tooltipbox.getBoundingClientRect().left
      const elRight = this.$refs.tooltipbox.getBoundingClientRect().right
      const windowWidth = document.body.clientWidth
      if (windowWidth <= elRight || elLeft <= 0) {
        this.isOpenLeft = !this.isOpenLeft
      }
    }
  }
}
</script>
<style lang="scss">
@use "sass:color";
@import "@/assets/scss/variables";
.tooltip {
  width: 1.25em;
  height: 1.25em;
  position: relative;
}
.tooltip__trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 700;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  color: $light-blue-700;
  border: 2px solid currentcolor;
  background-color: color.change($blue-600, $alpha: 0);
  transition-property: background-color, color, border-color;
  @include md-transition;
  &:focus {
    color: $blue-600;
    border-color: $blue-600;
    box-shadow: none;
    outline: none;
  }
}

.tooltip--primary {
  .tooltip__trigger:not(:focus) {
    font-weight: 600;
    border-width: 1px;
    border-color: $blue;
    color: $blue;
  }
}

.tooltip__box {
  position: absolute;
  max-width: 40vw;
  z-index: 10000;
  width: calc(100vw - 4rem);
  padding: 1rem 0.75rem 1rem 1.5rem;
  border-radius: 6px;
  background-color: $light-blue;
  right: calc(100% + 1rem);
  top: -1rem;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-1rem);
  pointer-events: none;
  transition-property: all;
  @include md-transition;

  &::before {
    content: "";
    left: 100%;
    top: calc(1rem);
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 0 9px 9px;
    border-color: transparent transparent transparent $light-blue;
    line-height: 0;
  }
}
@media screen and (width >= 50rem) {
  .tooltip__box {
    box-sizing: border-box;
    width: 364px;
  }
}
.tooltip__container {
  color: $slate;
  font-size: 1rem;
  line-height: 25px;
  padding-right: 0.75rem;
  max-height: 9rem;
  overflow: hidden;
  &::-webkit-scrollbar-track {
    background-color: $gray-300;
  }

  &::-webkit-scrollbar {
    width: 0.125rem;
    background-color: $gray-300;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
  }
}

.tooltip--opens-to-right {
  .tooltip__box {
    left: calc(100% + 0.75rem);
    transform: translateX(-0.5rem);
    right: unset;
    &::before {
      right: 100%;
      left: unset;
      border-width: 9px 9px 9px 0;
      border-color: transparent $light-blue transparent transparent;
    }
  }
}
.tooltip--opens-to-left {
  .tooltip__box {
    right: calc(100% + 0.75rem);
    transform: translateX(0.5rem);
    left: unset;
    &::before {
      left: 100%;
      right: unset;
      border-width: 9px 0 9px 9px;
      border-color: transparent transparent transparent $light-blue;
    }
  }
}
.tooltip--open .tooltip__trigger {
  color: white;
  background-color: color.change($blue, $alpha: 1);
  &:focus {
    border-color: $blue;
    color: white;
  }
}
.tooltip--open .tooltip__box {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
}
</style>
