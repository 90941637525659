import client from '@/api/client'
import axios from 'axios'

/**
 *  Fetch dynamic configuration from the backend server
 */
export async function get () {
  const response = await client.get('/configuration')
  return response.data
}

/**
 *  Fetch static configuration, uses axios directly because configuration is not under /api/
 */
export async function getStaticConfig () {
  const response = await axios.get('/configuration.json')
  return response.data
}

export default {
  get,
  getStaticConfig
}
