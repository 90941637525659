<template>
  <div class="card">
    <div
      v-for="x in count"
      :key="x"
      class="card-row"
    >
      <avatar
        name=""
        class="card-row__figure figure--letter"
      />
      <div class="card-row__text">
        <p class="card-row__title h5--ssp">
          <span class="card-row__link">
            <span class="loading-text">
              Loading Name
            </span>
          </span>
        </p>
        <p>
          <span class="loading-text">
            Loading username
          </span>
        </p>
        <ul
          v-if="showRoles"
          class="l-list l-list--inline"
        >
          <li class="l-list__item">
            <span class="label label--sm u-text-literal">
              <span class="loading-button">
                Loading label
              </span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from '@/components/utils/Avatar.vue'

export default {
  components: {
    Avatar
  },
  props: {
    count: {
      type: Number,
      required: true,
      validator: prop => Number.isInteger(prop) & prop > 0
    },
    showRoles: {
      type: Boolean,
      default: false
    }
  }
}
</script>
