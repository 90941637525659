<template>
  <component
    :is="tag"
    class="user-tag"
    :class="{ 'user-tag--removable': removable }"
  >
    <div class="user-tag__avatar">
      <UserAvatar
        class="figure--24"
        :name="user.name"
        :src="user.avatar"
      />
    </div>
    <div class="user-tag__name">
      <p>
        {{ user.name }}
      </p>
      <template v-if="myProfile && user.id === myProfile.id">
        <div class="label label--sm label--primary">
          {{ $t('me') }}
        </div>
      </template>
    </div>
    <button
      v-if="removable"
      class="user-tag__remove-btn"
      :aria-label="$t('actions.remove')"
      @click="removeUser"
    />
  </component>
</template>

<script>
import { mapState } from 'vuex'
import UserAvatar from '@/components/utils/UserAvatar.vue'
export default {
  components: {
    UserAvatar
  },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    user: {
      type: Object,
      required: true
    },
    removable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      myProfile: state => state.profile
    })
  },
  methods: {
    removeUser () {
      this.$emit('removeuser')
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables";

.user-tag {
  margin-right: auto;
  background: $light-blue;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  border-radius: 1.5rem;
  position: relative;
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;
}

.user-tag--removable {
  padding-right: 1rem;
}

.user-tag__avatar {
  margin-right: 0.5rem;
}
.user-tag__name {
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  .label {
    margin-left: 0.5rem;
    margin-right: 0.25rem;
  }
}
.user-tag__remove-btn {
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  overflow: hidden;
  background-color: white;
  border-radius: 100%;
  text-indent: 100%;
  border: 1px solid $light-blue;
  visibility: hidden;
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.7071.2929L3.121 1.706 4.53553.2929c.39053-.39053 1.0237-.39053 1.41422 0 .39052.39052.39052 1.02368 0 1.4142L4.535 3.121l1.41475 1.41453c.39052.39053.39052 1.0237 0 1.41422-.39053.39052-1.0237.39052-1.41422 0L3.121 4.535 1.7071 5.94975c-.39052.39052-1.02368.39052-1.4142 0-.39053-.39053-.39053-1.0237 0-1.41422L1.706 3.121.2929 1.7071c-.39053-.39052-.39053-1.02368 0-1.4142.39052-.39053 1.02368-.39053 1.4142 0z' fill='%238F95A1' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-size: 0.5rem 0.5rem;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: white;
  }
}
.user-tag:hover .user-tag__remove-btn {
  visibility: visible;
}

// advanced search modifiers
.user-tag--adv-search {
  width: 60%;
  padding: 0.75rem;
}
</style>
