/**
 * A function that fetches a page of data
 * @template T
 * @callback GetPageCallback
 * @param {number} page The page number
 * @returns {Promise<{ items: Array<T>, totalItems: number }>}
 */

/**
 * Returns all items of a paginated list in a single page. Takes a function
 * that fetches a page, and calls that function for however many pages there are.
 * Each page must be an object with keys `items` and `totalItems`, where `items`
 * are the items in the page, and `totalItems` is the total number of items in
 * the paginated list.
 * @template T
 * @param {GetPageCallback<T>} fetchPage A function that fetches a specific page
 */
export const fetchAllPages = async (fetchPage) => {
  const firstPage = await fetchPage(1)
  if (firstPage.items.length === firstPage.totalItems) {
    return firstPage
  }
  const total = firstPage.totalItems
  const perPage = firstPage.items.length
  const totalPages = Math.ceil(total / perPage)
  const remainingPagesPromises = []
  for (let p = 2; p <= totalPages; p++) {
    remainingPagesPromises.push(fetchPage(p))
  }
  const responses = await Promise.all(remainingPagesPromises)
  const allItems = responses.reduce((acc, page) => {
    acc.push(...page.items)
    return acc
  }, firstPage.items)
  return {
    totalItems: allItems.length,
    items: allItems
  }
}

/**
 * A function that applies a predicate to a page of data
 * @template T
 * @callback PagePredicateCallback
 * @param {{ items: Array<T>, totalItems: number }} page The page of data
 * @returns {boolean}
 */

/**
 * Checks if any page in a paginated list matches the given predicate.
 * Fetches pages one at a time, and applies the predicate to each, returning
 * true as soon as any page satisfies the predicate. If none of the pages
 * satisfies the predicate, returns false.
 * @template T
 * @param {GetPageCallback<T>} fetchPage Function to fetch the page
 * @param {PagePredicateCallback<T>} predicate Function to verify match
 * @returns True if any page satisfies the predicate, otherwise returns false.
 */
export const anyPageMatches = async (fetchPage, predicate) => {
  let p = 0
  let page, perPage
  do {
    p++
    page = await fetchPage(p)
    if (predicate(page)) return true
    if (p === 1) perPage = page.items.length
  } while (page.totalItems > p * perPage)
  return false
}

/**
 * A function that handles the page reload
 * @param {object} param0 Root object
 * @param {import('vue').Component} param0.component the current component that is rendering the page (this)
 * @param {number} param0.numberOfItemsInPage Number of items perPage
 * @param {import('vue-router').default} param0.router The router of the application
 * @param {boolean} param0.keepPage if you want to keep in the same page after the refresh
 * @param {boolean} param0.isDeletion if the refresh is due to an item deletion
 * @param {number} param0.timeout A timeout
 * @param {Promise<any>} param0.getItems method that loads the current page
 */
export const handlePageReload = async ({
  component,
  numberOfItemsInPage,
  router,
  keepPage = false,
  isDeletion = false,
  timeout = 1000,
  getItems
}) => {
  component.isSearching = true
  component.isLoading = true
  setTimeout(async () => {
    if (keepPage) {
      const wasLastInPage = numberOfItemsInPage === 1
      if (isDeletion && wasLastInPage) {
        await router.replace({ query: { page: component.currentPage - 1 } })
      } else {
        await getItems()
      }
    } else await router.replace({})
    component.isLoading = false
    component.isSearching = false
  }, timeout)
}
