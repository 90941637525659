<template>
  <div
    :key="source.id"
    class="container"
  >
    <div class="page">
      <header class="section-header">
        <p class="h5 section-header__parent-page">
          <button @click.prevent="$router.push(`/records/${source.record.metadata.id}/edits`)">
            <span class="i-back-arrow" />
            <span>{{ $t('editions') }}</span>
          </button>
        </p>
        <h1 class="h1 section-header__title">
          {{ fullDate }}
          <span v-if="source.preferredSource">({{ $t('current') }})</span>
        </h1>
        <p>{{ $t("sourceType." + source.sourceType) }}</p>
      </header>
      <div class="l-flex l-space-between">
        <button
          class="btn btn--light"
          @click="openRestoreModal"
        >
          {{ $t('restore') }}
        </button>
        <div class="l-flex right-section mobile">
          <router-link
            v-if="previousVersion"
            :to="{ name: 'RecordEditionPage', params: { id: source.record.metadata.id, editId: previousVersion.id } }"
          >
            <button class="btn btn--light">
              {{ $t('previousVersion') }}
            </button>
          </router-link>
          <router-link
            v-if="nextVersion"
            :to="{ name: 'RecordEditionPage', params: { id: source.record.metadata.id, editId: nextVersion.id } }"
          >
            <button class="btn btn--light">
              {{ $t('nextVersion') }}
            </button>
          </router-link>
        </div>
      </div>
      <RecordPage :record="source.record">
        <template #sideCard>
          <div class="card">
            <div class="card__section">
              <h2 class="card__section-title h5--ssp">
                {{ $t('import.label') }}
              </h2>
              <p>
                {{ $t('import.description.part1') }}
                <span
                  class="export-link"
                  @click="openRawModal"
                >{{ $t('import.description.part2') }}</span>
                {{ $t('import.description.part3') }}
              </p>
            </div>
          </div>
        </template>
      </RecordPage>
      <div class="l-flex right-section not-mobile">
        <router-link
          v-if="previousVersion"
          :to="{ name: 'RecordEditionPage', params: { id: source.record.metadata.id, editId: previousVersion.id } }"
        >
          <button class="btn btn--light">
            {{ $t('previousVersion') }}
          </button>
        </router-link>
        <router-link
          v-if="nextVersion"
          :to="{ name: 'RecordEditionPage', params: { id: source.record.metadata.id, editId: nextVersion.id } }"
        >
          <button class="btn btn--light">
            {{ $t('nextVersion') }}
          </button>
        </router-link>
      </div>
    </div>
    <modal
      v-model="restoreModal"
      class="modal--md"
    >
      <div slot="modal-panel">
        <h1>
          {{ $t('modal.header') }}
        </h1>
        <p>
          {{ $t('modal.text') }} <span class="u-text-strong">"{{ source.record.title }}"</span> {{ $t("sourceType.adjective." + source.sourceType) }}
          {{ $t('at') }}
          {{ shortDate }}
          {{ $t('by') }}
          {{ source.author.name }}.
        </p>
        <div
          class="btn-group"
          style="margin-top: 1rem;"
        >
          <button
            class="btn btn--primary"
            @click="restoreVersion"
          >
            {{ $t('restore') }}
          </button>
        </div>
      </div>
    </modal>
    <modal
      v-model="rawModal"
      class="modal--full"
    >
      <div
        slot="modal-panel"
        class="code"
      >
        <button
          class="btn clipboard-btn btn--primary btn--outline"
          @click="copyToClipboard"
        >
          {{ copied ? $t('copied') : $t('copyToClipboard') }}
        </button>
        <code>{{ recordRawData }}</code>
      </div>
    </modal>
  </div>
</template>

<script>
import RecordPage from '@/pages/RecordPage.vue'
import { guardWithErrorHandling, fetchRecord, fetchAuthorByUserId } from '@/router/navigation-guards'
import Modal from '@/components/utils/Modal.vue'
import { setPreferredSource } from '@/api/records'
import dayjs from 'dayjs'

export default {
  components: {
    RecordPage,
    Modal
  },
  beforeRouteUpdate: guardWithErrorHandling(
    async function (to, from, next) {
      const record = await fetchRecord({ id: to.params.id, sources: true, countView: true })
      const sources = record.metadata.sources.sort((a, b) => {
        if (new Date(a.updated) > new Date(b.updated)) {
          return 1
        } else {
          return -1
        }
      })
      const sourceIndex = sources.findIndex(({ id }) => id === to.params.editId)
      const source = sources[sourceIndex]
      source.author = await fetchAuthorByUserId({ userId: source.author, countView: false })
      source.record.metadata = record.metadata
      to.params.previousVersion = sources[sourceIndex - 1] || null
      to.params.nextVersion = sources[sourceIndex + 1] || null
      to.params.source = source
      next()
    }
  ),
  props: {
    source: {
      required: true,
      type: Object
    },
    nextVersion: {
      default: undefined,
      required: false,
      type: Object || undefined
    },
    previousVersion: {
      default: undefined,
      required: false,
      type: Object || undefined
    }
  },
  data () {
    return {
      restoreModal: false,
      rawModal: false,
      copied: false
    }
  },
  computed: {
    fullDate () {
      if (!this.source.updated) return undefined
      const date = dayjs(this.source.updated)
      return date.format(this.$t('date.helpers.moment.format.datetime'))
    },
    shortDate () {
      if (!this.source.updated) return undefined
      const date = dayjs(this.source.updated)
      return date.format(this.$t('date.helpers.moment.format.year.month.day'))
    },
    recordRawData () {
      const cache = new Set()
      // allows to stringify objects with circular references
      return JSON.stringify(this.source.record, (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (cache.has(value)) {
            // Circular reference found, discard key
            return
          }
          cache.add(value)
        }
        return value
      }, 2)
    }
  },
  methods: {
    async copyToClipboard () {
      await navigator.clipboard.writeText(this.recordRawData)
      this.copied = true
    },
    openRestoreModal () {
      this.restoreModal = true
    },
    openRawModal () {
      this.rawModal = true
      this.copied = false
    },
    async restoreVersion () {
      this.restoreModal = false
      await setPreferredSource({ id: this.source.record.metadata.id, author: this.source.author.id, sourceType: this.source.sourceType })
      this.$router.push(`/records/${this.source.record.metadata.id}`)
    }
  },
  i18n: {
    messages: {
      pt: {
        editions: 'Edições',
        current: 'Versão atual',
        restore: 'Restaurar versão',
        previousVersion: 'Versão anterior',
        nextVersion: 'Próxima versão',
        at: 'a',
        by: 'por',
        modal: {
          header: 'Quer restaurar esta versão?',
          text: 'Ao prosseguir, estará a restaurar a versão da publicação'
        },
        import: {
          label: 'Dados de importação',
          description: {
            part1: 'Pode aceder aos',
            part2: 'dados em bruto',
            part3: 'cedidos na importação desta publicação'
          }
        },
        copyToClipboard: 'Copiar para a área de transferência',
        copied: 'Copiado!'
      },
      en: {
        editions: 'Editions',
        current: 'Current version',
        restore: 'Restore version',
        previousVersion: 'Previous version',
        nextVersion: 'Next version',
        at: 'at',
        by: 'by',
        modal: {
          header: 'Do you want to restore this version?',
          text: 'By proceeding, you will restore the version of the publication'
        },
        import: {
          label: 'Imported data',
          description: {
            part1: 'You can access the',
            part2: 'raw data',
            part3: 'provided in the import of this publication'
          }
        },
        copyToClipboard: 'Copy to clipboard',
        copied: 'Copied!'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.page {
  padding: 1.5rem 0;
}

@media (width >= 75rem) {
  .page {
    padding: 3.5rem 0;
  }
}

::v-deep .publication {
  padding-top: 1.5rem;
}

::v-deep .container {
  padding: 0;
}
.right-section {
  gap: 1rem;
}
.not-mobile {
  @media screen and (width >= 75rem) {
    display: none;
  }
}
.mobile {
  @media screen and (width <= 75rem) {
    display: none;
  }
}
.code {
  position: relative;
  background-color: $light-blue;
  padding: 1rem;
  padding-top: 2.5rem;
  overflow-x: auto;
  white-space: pre;
  word-break: normal;
  word-wrap: normal;
}
.clipboard-btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
</style>
