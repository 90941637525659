import * as types from './mutation-types'
import * as ProfileAPI from '@/api/profile'
import UnitsAPI from '@/api/units'

export const setTopMessage = async ({ commit }, { active, msg, dismiss, type }) => {
  commit(types.SET_TOP_MESSAGE, { active, msg, dismiss, type })
}

export const fetchProfile = async ({ commit }) => {
  const data = await ProfileAPI.get()
  commit(types.RECEIVED_PROFILE, { profile: data })
}

export const fetchManagedUnits = async ({ commit, state }) => {
  const unitIds = state.profile.units
  if (!unitIds || unitIds.length === 0) {
    commit(types.RECEIVED_MANAGED_UNITS, { managedUnits: [] })
    return
  }
  const promises = []
  for (const id of unitIds) {
    promises.push(UnitsAPI.get({ unitID: id }))
  }
  const units = await Promise.all(promises)
  commit(types.RECEIVED_MANAGED_UNITS, { managedUnits: units })
}

export const fetchExternalProfiles = async ({ commit }) => {
  const data = await ProfileAPI.getExternalProfiles()
  commit(types.RECEIVED_EXTERNAL_PROFILES, data)
}

export const setConfigurationState = ({ commit }, { configuration }) => {
  commit(types.SET_CONFIGURATION_STATE, configuration)
}

export const setProgressBar = ({ commit }, progress) => {
  commit(types.SET_PROGRESS_BAR_STATE, progress)
}

export const completeProgressBar = ({ commit }) => {
  commit(types.SET_PROGRESS_BAR_STATE, 100)
}
