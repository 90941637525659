import dayjs from 'dayjs'

export default {
  methods: {
    generateDateString (date, formatKey) {
      if (!date || !date.year) return ''
      if (date.year && date.month && date.day) {
        const format = this.$t(`date.helpers.moment.format.${formatKey ?? 'year.month.day'}`)
        return dayjs([date.year, date.month - 1, date.day]).format(format)
      } else if (date.year && date.month) {
        const format = this.$t(`date.helpers.moment.format.${formatKey ?? 'year.month'}`)
        const formattedStr = dayjs([date.year, date.month - 1]).format(format)
        return formattedStr[0].toUpperCase() + formattedStr.slice(1)
      } else if (date.year) {
        const format = this.$t(`date.helpers.moment.format.${formatKey ?? 'year'}`)
        return dayjs([date.year]).format(format)
      } else {
        return ''
      }
    },
    objectDateToString (date) {
      if (date.year && date.month && date.day) {
        return `${date.year}-${date.month}-${date.day}`
      } else if (date.year && date.month) {
        return `${date.year}-${date.month}`
      } else if (date.year) {
        return String(date.year)
      } else {
        return ''
      }
    },
    dateStringToObjectString (dateString) {
      const parsedDate = dateString.split('-')
      return {
        year: parsedDate[0],
        month: parsedDate[1],
        day: parsedDate[2]
      }
    }
  }
}
