<template>
  <div class="card">
    <div
      v-for="x in count"
      :key="x"
      class="card-row"
    >
      <avatar
        name=""
        class="card-row__figure figure--letter"
      />
      <div class="card-row__text">
        <p class="card-row__title h5--ssp">
          <span class="loading-text">
            Loading unit name
          </span>
        </p>
        <p class="u-text-secondary">
          <span class="loading-text">
            mo year - mo year
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from '@/components/utils/Avatar.vue'

export default {
  components: {
    Avatar
  },
  props: {
    count: {
      type: Number,
      required: true,
      validator: prop => Number.isInteger(prop) & prop > 0
    }
  }
}
</script>
