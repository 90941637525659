import Vue from 'vue'
import Vuex from 'vuex'

import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

const state = {
  topMessage: { active: false, msg: { pt: ' ', en: '' }, dismiss: false, type: '' },
  profile: {},
  managedUnits: [],
  externalProfiles: undefined,
  configuration: {},
  progressBar: 0
}

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})

export default store
