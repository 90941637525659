import client from '@/api/client'

/**
 * Fetch a specific record
 * @param {object} root0 Root object
 * @param {string} root0.id The id of the record to fetch
 * @param {boolean} [root0.allowDraft] If records in draft can be retrieved
 * @param {boolean} [root0.sources] If the sources should be included
 * @param {boolean} [root0.countView] If the count of views should appear in the response
 * @param {boolean} [root0.mergeHistory] If the history of merges should also be included
 */
export async function get ({ id, allowDraft, sources, countView, mergeHistory }) {
  const response = await client.get(`/records/${id}`, {
    params: {
      allowDraft,
      sources,
      countView,
      mergeHistory
    }
  })
  return response.data
}

/**
 * Lists records with base in some search params
 * @param {object} root0 Root object
 * @param {number} root0.page The page number to retrieve
 * @param {number} root0.perPage The number of result per page
 * @param {string} root0.q A query string to use to search for records
 * @param {string} root0.sort The sorting to be used
 * @param {string[]} root0.recordTypes A filter by type of record (ge. master-thesis)
 * @param {boolean} root0.allowDraft IF records in draft should be included in the listings
 */
export async function list ({ page, perPage, q, sort, recordTypes, allowDraft }) {
  const response = await client.get('/records', {
    params: {
      ...(q && { q }),
      ...(recordTypes && { recordTypes }),
      ...(allowDraft && { allowDraft }),
      skip: (page - 1) * perPage,
      limit: perPage,
      sort
    }
  })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {number} root0.page The page number to retrieve
 * @param {number} root0.perPage The number of items per page
 * @param {string} root0.q The search query
 * @param {string} root0.sort The sorting to be used
 * @param {string[]} root0.recordTypes A list of record types (eg. master-thesis)
 * @param {boolean} root0.allowDraft If a records in draft should be included
 */
export async function find ({ page, perPage, q, sort, recordTypes, allowDraft = false }) {
  const response = await client.get('/records/find', {
    params: {
      ...(q && { q }),
      recordTypes,
      allowDraft,
      sort,
      skip: (page - 1) * perPage,
      limit: perPage
    }
  })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {number} root0.page The page number to be retrieved
 * @param {number} root0.perPage The number of elements per page
 * @param {string} root0.q the search query
 * @param {string} root0.sort The sorting to be used
 * @param {boolean} root0.allowDraft If record in draft can appear in the results
 */
export async function advancedFind ({ page, perPage, q, sort, allowDraft = true }) {
  const response = await client.post('/records/advanced-find', { q }, {
    params: {
      allowDraft,
      sort,
      skip: (page - 1) * perPage,
      limit: perPage
    }
  })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {object} root0.record The record to be created
 */
export async function create ({ record }) {
  const response = await client.post('/records', { record })
  return response.data
}

/**
 * Updates a record in the scholar backend, returns the id in case of success
 * @param {object} object Root object
 * @param {string} object.id ID of the record to update
 * @param {object} object.record Object with all data of the record
 * @returns {Promise<string>} The id of the record updated
 */
export async function update ({ id, record }) {
  const response = await client.put(`/records/${id}`, { record })
  return response.data.id
}

/**
 * Sets the preferred source of a record
 * @param {object} object Root object
 * @param {string} object.id ID of the record to update
 * @param {string} object.author ID of the author of the source
 * @param {string} object.sourceType Type of the source
 * @returns {Promise<string>} The id of the record updated
 */
export async function setPreferredSource ({ id, author, sourceType }) {
  const response = await client.put(`/records/${id}/source`, { author, sourceType })
  return response.data
}

/**
 * Deletes the record with the given id
 * @param {object} root0 Root object
 * @param {string} root0.id Id of the record to delete
 */
export async function remove ({ id }) {
  const response = await client.delete(`/records/${id}`)
  return response.data
}

export default {
  get,
  list,
  create,
  update,
  remove
}
