<template>
  <div class="container">
    <article class="publication publication-article">
      <header class="section-header">
        <p class="h5 section-header__parent-page">
          <button @click.prevent="$router.push(`/records/${record.metadata.id}`)">
            <span class="i-back-arrow" />
            <span>{{ $t('record') }}</span>
          </button>
        </p>
        <h1 class="h1 section-header__title">
          {{ $t('title') }} "{{ record.title }}"
        </h1>
        <p>{{ $t('description') }}</p>
      </header>
      <Dropdown
        size="md"
        class="dropdown--with-check"
      >
        <button
          slot="dropdown-trigger"
          class="tab-navigation__options-link"
        >
          {{ $t('order') }}
        </button>
        <div slot="dropdown-panel">
          <ul class="dropdown-menu">
            <li class="dropdown-menu__item">
              <button
                :class="{'dropdown-menu__link--selected': order === 'desc'}"
                class="dropdown-menu__link"
                @click.prevent="setOrder('desc')"
              >
                <span class="link-text">{{ $t('recent') }}</span>
              </button>
            </li>
            <li class="dropdown-menu__item">
              <button
                :class="{'dropdown-menu__link--selected': order === 'asc'}"
                class="dropdown-menu__link"
                @click.prevent="setOrder('asc')"
              >
                <span class="link-text">{{ $t('oldest') }}</span>
              </button>
            </li>
          </ul>
        </div>
      </Dropdown>
      <ul>
        <router-link
          v-for="source in sortedSources"
          :key="source.id"
          class="card card-row"
          :to="{ name: 'RecordEditionPage', params: { id: record.metadata.id, editId: source.id } }"
        >
          <div>
            <UserTag
              :user="source.author"
              :removable="false"
            />
            {{ $t("sourceType." + source.sourceType) }}
            {{ $t('at') }}
            <span class="u-text-strong">
              {{ displayDate(source.updated) }}
            </span>
          </div>
          <p
            v-if="source.preferredSource"
            class="label label--primary label--sm"
          >
            {{ $t('currentVersion') }}
          </p>
        </router-link>
      </ul>

      <div
        v-if="record.metadata.mergeHistory.length > 0"
        class="merge-history"
      >
        <h2 class="h4">
          {{ $t('mergeHistory') }}
        </h2>
        <ul>
          <div
            v-for="mergedRecord in record.metadata.mergeHistory"
            :key="mergedRecord.id"
            class="card"
          >
            <div class="card-row">
              <p class="card-row__text">
                "<span class="merge-title">{{ mergedRecord.title }}</span>"
                {{ $t("sourceType.adjective." + mergedRecord.metadata.preferredSource.sourceType) }}
                {{ $t('at') }}
                <span class="u-text-strong">
                  {{ displayDate(mergedRecord.metadata.updated) }}
                </span>
              </p>
            </div>
          </div>
        </ul>
      </div>
    </article>
  </div>
</template>

<script>
import DateHelpers from '@/mixins/DateHelpers'
import Dropdown from '@/components/utils/Dropdown.vue'
import UserTag from '@/components/utils/UserTag.vue'
import dayjs from 'dayjs'

export default {
  components: {
    UserTag,
    Dropdown
  },
  mixins: [DateHelpers],
  provide () {
    return {
      ignoredFields: this.record.metadata?.ignored ?? []
    }
  },
  props: {
    record: {
      required: true,
      type: Object
    }
  },
  metaInfo: function () {
    return {
      title: this.record.title,
      meta: [{
        name: 'description', content: this.$t('description', { title: this.record.title })
      }],
      script: [{
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'Article',
          headline: this.record.title,
          description: this.record.description,
          inLanguage: this.record.language,
          author: this.record.creators.filter(c => c.userId).map(creator => ({
            '@type': 'Person',
            name: creator.name,
            image: creator.user.avatar,
            url: `https://scholar.tecnico.ulisboa.pt/authors/${creator.userId}`
          }))
        }
      }]
    }
  },
  data () {
    return {
      customSubtitleFields: [],
      order: 'desc'
    }
  },
  computed: {
    sortedSources () {
      const sources = this.record.metadata.sources
      return sources.sort((a, b) => {
        if (this.order === 'desc') {
          return new Date(b.updated) - new Date(a.updated)
        } else {
          return new Date(a.updated) - new Date(b.updated)
        }
      })
    }
  },
  methods: {
    assignCustomSubtitleFields (value) {
      this.customSubtitleFields = value
    },
    setOrder (value) {
      this.order = value
    },
    displayDate (editionDate) {
      if (!editionDate) return null
      const date = dayjs(editionDate)
      return date.format(this.$t('date.helpers.moment.format.datetime'))
    }
  },
  i18n: {
    messages: {
      pt: {
        record: 'Publicação',
        title: 'Edições à publicação',
        description: 'Reveja as edições feitas à publicação',
        currentVersion: 'Versão atual',
        at: 'a',
        order: 'Ordenar',
        recent: 'Mais recentes',
        oldest: 'Mais antigos',
        mergeHistory: 'Histórico das publicações associadas'
      },
      en: {
        record: 'Publication',
        title: 'Edits to the publication',
        description: 'Review the edits made to the publication',
        currentVersion: 'Current Version',
        at: 'at',
        order: 'Order',
        recent: 'Most recent',
        oldest: 'Oldest',
        mergeHistory: 'History of associated publications'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.card-row {
  justify-content: space-between;
}

.tab-navigation__options-link {
  display: block;
  margin-left: auto;
  margin-right: 1rem;
}
.merge-history {
  margin-top: 4rem;
}
.merge-title {
  padding-bottom: 0.125rem;

  // border-bottom: 1px dotted $slate;
}
</style>
