import * as types from './mutation-types'

export default {
  [types.SET_TOP_MESSAGE] (state, { active, msg, dismiss, type }) {
    state.topMessage = { active, msg, dismiss, type }
  },
  [types.RECEIVED_PROFILE] (state, { profile }) {
    state.profile = profile
  },
  [types.RECEIVED_MANAGED_UNITS] (state, { managedUnits }) {
    state.managedUnits = managedUnits
  },
  [types.RECEIVED_EXTERNAL_PROFILES] (state, { externalProfiles }) {
    state.externalProfiles = externalProfiles
  },
  [types.SET_CONFIGURATION_STATE] (state, configuration) {
    state.configuration = configuration
  },
  [types.SET_PROGRESS_BAR_STATE] (state, progress) {
    state.progressBar = progress
  }
}
