<template>
  <span class="small u-text-secondary">
    {{ path.join(' &gt; ') }}
  </span>
</template>
<script>
export default {
  props: {
    unit: {
      type: Object,
      required: true
    }
  },
  computed: {
    path () {
      if (!this.unit.path) {
        return [this.unit.acronym]
      }
      return [...this.unit.path.map(u => u.acronym), this.unit.acronym]
    }
  }
}
</script>
