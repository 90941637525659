<template>
  <div class="explore-units-page">
    <div class="container">
      <SearchPaginatedList
        :is-searching="isSearching"
        :is-initial-loading="false"
        :query="q"
        :page-data="authors"
      >
        <template #list-header>
          <header class="card">
            <div class="card-row">
              <div class="card-row__text">
                <p>{{ $t('scientific.interest') }}</p>
                <h1 class="card-row__title">
                  {{ q }}
                </h1>
                <p>
                  <b>{{ $tc('scientific.interest.total.p1', totalResults) }}</b>
                  {{ $tc('scientific.interest.total.p2', totalResults) }}
                </p>
              </div>
            </div>
          </header>
        </template>
        <template #list-skeleton>
          <UserSkeleton
            :count="6"
            class="card-list"
          />
        </template>
        <template #list>
          <ul class="card card-list">
            <li
              v-for="user in users"
              :key="user.id"
              class="card-row"
            >
              <UserAvatar
                :name="user.name"
                :src="user.avatar"
                class="card-row__figure"
              />
              <div class="card-row__text">
                <h1 class="card-row__title h5--ssp">
                  <RouterLink
                    :to="{ name: 'ResearcherPage', params: { userId: user.username } }"
                    class="card-row__link"
                  >
                    {{ user.name }}
                  </RouterLink>
                </h1>
                <p>{{ user.username }}</p>
              </div>
            </li>
          </ul>
        </template>
        <template #pagination>
          <PaginationBar
            v-if="users"
            :current-page="currentPage"
            :total-items="totalResults"
            :items-per-page="perPage"
          />
        </template>
      </SearchPaginatedList>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/utils/UserAvatar.vue'
import PaginationBar from '@/components/utils/PaginationBar.vue'
import SearchPaginatedList from '@/components/utils/SearchPaginatedList.vue'
import UserSkeleton from '@/components/loading-skeletons/User.vue'

import { listByScientificInterest } from '@/api/researchers'

export default {
  components: {
    UserAvatar,
    PaginationBar,
    SearchPaginatedList,
    UserSkeleton
  },
  async beforeRouteUpdate (to, from, next) {
    this.currentPage = Number(to.query.page) || 1
    this.isSearching = true
    to.params.perPage = Number(this.perPage)
    to.params.authors = await listByScientificInterest({ scientificInterest: to.query.q, page: this.currentPage, perPage: this.perPage })
    this.isSearching = false
    localStorage.setItem('perPage', to.query.perPage)
    next()
  },
  props: {
    page: {
      type: Number,
      required: true
    },
    authors: {
      type: Object,
      required: true
    },
    perPage: {
      type: Number,
      default: Number(localStorage.getItem('perPage')) || 10
    },
    q: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currentPage: this.page,
      isSearching: false
    }
  },
  computed: {
    users () {
      return this.authors.items
    },
    totalResults () {
      return this.authors.totalItems
    }
  },
  metaInfo: function () {
    return {
      title: this.$t('title'),
      meta: [{ name: 'description', content: this.$t('description') }]
    }
  },
  i18n: {
    messages: {
      pt: {
        title: 'Interesses científicos',
        description: 'Página com o resultado da pesquisa de publicações por um dado interesse científico'
      },
      en: {
        title: 'Scientific interests',
        description: 'Result of a publication search of a given scientific interest'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.card-list {
  margin-top: 2rem;
}
</style>
