import { fetchRootUnit, fetchAllSubUnits, fetchUnit, guardWithErrorHandling } from '@/router/navigation-guards'
import { getScientificProduction } from '@/api/units'

import UnitPage from '@/pages/UnitPage.vue'
import UnitRecordsPage from '@/pages/UnitRecordsPage.vue'
import UnitMembersPage from '@/pages/UnitMembersPage.vue'
import UnitSubunitsPage from '@/pages/UnitSubunitsPage.vue'
import ExploreUnitsPage from '@/pages/ExploreUnitsPage.vue'

import DateHelpers from '@/mixins/DateHelpers'

export default [
  {
    path: '/explore/units',
    name: 'ExploreUnitsPage',
    component: ExploreUnitsPage,
    meta: {
      pageInfo: 'explore.units',
      layout: 'PageWithNavBarAndFooterLayout'
    },
    props: route => ({
      rootUnit: route.params.rootUnit,
      subUnitsPage: route.params.subUnitsPage
    }),
    async beforeEnter (to, from, next) {
      try {
        const rootUnit = await fetchRootUnit()
        to.params.rootUnit = rootUnit
      } catch (err) {
        if (err.response.data.key === 'unit-not-found') {
          to.params.rootUnit = null
          to.params.subUnitsPage = { totalItems: 0, items: [] }
          next()
          return
        }
      }
      const subUnitsPage = await fetchAllSubUnits({ parentId: to.params.rootUnit.id })
      to.params.subUnitsPage = subUnitsPage
      next()
    }
  },
  {
    path: '/units/:unitID',
    name: 'UnitPage',
    component: UnitPage,
    redirect: { name: 'UnitRecordsPage' },
    meta: {
      layout: 'PageWithNavBarAndFooterLayout'
    },
    props: route => ({
      unitID: route.params.unitID,
      unit: route.params.unit,
      scientificProduction: route.params.scientificProduction
    }),
    beforeEnter: guardWithErrorHandling(
      async (to, from, next) => {
        const [unit, scientificProduction] = await Promise.all([
          fetchUnit({ id: to.params.unitID, countView: true }),
          getScientificProduction({ unitID: to.params.unitID })
        ])
        const predecessors = await Promise.all(unit.predecessorUnits.map(predecessor => fetchUnit({ id: predecessor })))
        const successors = await Promise.all(unit.successorUnits.map(successor => fetchUnit({ id: successor })))
        to.params.scientificProduction = scientificProduction
        to.params.unit = unit
        to.params.unit.predecessorUnits = predecessors
        to.params.unit.successorUnits = successors
        next()
      }
    ),
    children: [
      {
        path: 'records',
        name: 'UnitRecordsPage',
        component: UnitRecordsPage,
        props: route => ({
          unitID: route.params.unitID,
          page: Number(route.query.page) || 1,
          q: route.query.q,
          type: typeof route.query.type === 'string' ? [route.query.type] : route.query.type,
          start: route.query.start ? DateHelpers.methods.dateStringToObjectString(route.query.start) : null,
          end: route.query.end ? DateHelpers.methods.dateStringToObjectString(route.query.end) : null,
          perPage: Number(route.query.perPage) || undefined
        }),
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'members',
        name: 'UnitMembersPage',
        redirect: { name: 'UnitCurrentMembersPage' },
        component: UnitMembersPage,
        props: route => ({
          unitID: route.params.unitID,
          page: Number(route.query.page) || 1,
          perPage: Number(route.query.perPage) || undefined,
          q: route.query.q
        }),
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        },
        children: [
          {
            path: 'current',
            name: 'UnitCurrentMembersPage',
            component: UnitMembersPage
          },
          {
            path: 'past',
            name: 'UnitPastMembersPage',
            component: UnitMembersPage
          }
        ]
      },
      {
        path: 'subunits',
        name: 'UnitSubunitsPage',
        component: UnitSubunitsPage,
        props: route => ({
          unitID: route.params.unitID,
          page: Number(route.query.page) || 1,
          perPage: Number(route.query.perPage) || undefined,
          q: route.query.q
        }),
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        },
        beforeEnter (to, from, next) {
          if (!to.params.unit.hasSubUnits) {
            return next({ name: 'PageNotFound', params: [to.path] })
          }
          next()
        }
      }
    ]
  }
]
