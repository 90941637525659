<template>
  <component
    :is="tag"
    class="card-row record"
  >
    <div class="card-row__text">
      <p class="small record__item-type">
        {{ $t(`record.view.types.${record.type}`) }}
        <ExternalRepositoriesBadge
          v-if="record.metadata && record.metadata.externalSources"
          :external-sources="record.metadata.externalSources"
        />
      </p>
      <component
        :is="recordTitleTag"
        class="h4 record__item-title"
      >
        <Component
          :is="clickable ? 'router-link' : 'span'"
          :to="clickable ? { name: 'RecordPage', params: { id: record.metadata.id } } : false"
          :class="{ 'record__item-title-link': clickable }"
        >
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="title" />
        </Component>
      </component>
      <slot />
      <div class="record__item-publishing-data">
        <p
          v-if="record.citationTitle"
          class="record__item-academic-journal"
        >
          {{ record.citationTitle.trim() }}
        </p>
        <p>
          <span
            v-if="record.creators"
            class="record__item-author"
          >
            <!-- Show authors -->
            <template v-for="(author, index) in showAuthors">
              <Component
                :is="clickable && author.userId ? 'router-link' : 'span'"
                :key="index"
                :to="clickable && author.userId ? { name: 'ResearcherPage', params: { userId: author.userId } } : false"
              >{{ author.name }}</Component>{{ record.creators.length - 1 !== index ? ', ' : ' ' }}
            </template>
            <!-- Show toggle to expand/collapse authors -->
            <button
              v-if="record.creators.length > 3"
              @click="authorsExpanded = !authorsExpanded"
            >
              <strong>{{ authorsExpanded ? $t('hideAuthors') : $tc('showAuthors', record.creators.length - 3) }}</strong>
              <span
                v-if="!authorsExpanded"
                style="white-space: pre;"
              >, </span>
            </button>
            <!-- Show last element -->
            <template v-if="record.creators.length > 2 && !authorsExpanded && record.creators.at">
              <Component
                :is="clickable && record.creators.at(-1).userId ? 'router-link' : 'span'"
                :to="clickable && record.creators.at(-1).userId ? { name: 'ResearcherPage', params: { userId: record.creators.at(-1).userId } } : false"
              >{{ record.creators.at(-1).name.trim() }}</Component>
            </template>
          </span>
          <template v-if="record.dateIssued && record.dateIssued.year">
            &mdash;
            <span class="record__item-publication-date">
              {{ record.dateIssued.year }}
            </span>
          </template>
          <template v-if="record.publisher">
            &mdash;
            <span class="record__item-publisher">
              {{ record.publisher.trim() }}
            </span>
          </template>
        </p>
      </div>
    </div>
    <div
      v-if="editable || removable"
      class="card-row__meta card-row__meta--align-start"
    >
      <Dropdown>
        <button
          slot="dropdown-trigger"
          class="i-ellipsis"
          aria-label="More options"
          aria-haspopup="true"
          aria-expanded="false"
          @click.prevent=""
        />
        <template slot="dropdown-panel">
          <ul class="dropdown-menu dropdown--xs">
            <li
              v-if="editable"
              class="dropdown-menu__item"
            >
              <router-link
                tag="button"
                class="dropdown-menu__link"
                :to="{ name: 'EditRecordPage', params: { id: record.metadata.id } }"
              >
                {{ $t('actions.edit') }}
              </router-link>
            </li>
            <li
              v-if="editable"
              class="dropdown-menu__item"
            >
              <router-link
                tag="button"
                class="dropdown-menu__link"
                :to="{ name: 'RecordEditionsPage', params: { id: record.metadata.id } }"
              >
                {{ $t('actions.view.edits') }}
              </router-link>
            </li>
            <li
              v-if="removable"
              class="dropdown-menu__item"
            >
              <button
                class="dropdown-menu__link u-text-danger"
                @click="removeRecord"
              >
                {{ $t('actions.remove') }}
              </button>
            </li>
          </ul>
        </template>
      </Dropdown>
    </div>
  </component>
</template>

<script>
import Dropdown from '@/components/utils/Dropdown.vue'
import ExternalRepositoriesBadge from '@/components/records/ExternalRepositoriesBadge.vue'
import { escape as htmlEscape } from 'html-escaper'

export default {
  components: {
    Dropdown,
    ExternalRepositoriesBadge
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    tag: {
      type: String,
      default: 'article'
    },
    recordTitleTag: {
      type: String,
      default: 'p'
    },
    removable: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      authorsExpanded: false,
      latex2utf8: null
    }
  },
  computed: {
    showAuthors () {
      return this.authorsExpanded ? this.record.creators : this.record.creators.slice(0, 2)
    },
    title () {
      const safeTitle = htmlEscape(this.record?.title.trim())
      if (!this.latex2utf8) {
        return safeTitle
      } else {
        return this.latex2utf8(safeTitle)
      }
    }
  },
  async created () {
    const { latex2utf8 } = await import('@/utils/Latex2Utf8')
    this.latex2utf8 = latex2utf8
  },
  methods: {
    async removeRecord () {
      this.$emit('delete', this.record.metadata.id)
    }
  },
  i18n: {
    messages: {
      pt: {
        showAuthors: '+ {count} autores',
        hideAuthors: 'Ocultar autores'
      },
      en: {
        showAuthors: '+ {count} authors',
        hideAuthors: 'Hide authors'
      }
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.record__item-type {
  text-transform: capitalize;
}
.record__item-title {
  margin-top: 0.5rem;
  margin-bottom: 0;
  position: relative;
}
.record__item-title-link {
  cursor: pointer;
  color: $dark;
  &::before {
    content: "";
    position: absolute;
    cursor: pointer;
    padding-top: 2rem;
    inset: -2rem 0 0;

    // background-color: rgba($blue, .4);
  }
}
.record__item-academic-journal {
  color: $gray;
  line-height: 1.2;
}
.record__item-author {
  color: $slate;
}
.record__item-publishing-data {
  p {
    margin: 0.25rem 0;
  }
}
</style>
