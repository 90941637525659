export default {
  methods: {
    getFieldType (field) {
      /* TODO:
        files
        peerreviewed
      */
      if ([
        'title',
        'titleAlternative',
        'degreeName',
        'authorEmail',
        'publisher',
        'citationTitle',
        'citationVolume',
        'citationIssue',
        'citationStartPage',
        'citationEndPage',
        'institutionName',
        'fundingReferences',
        'citationConferencePlace',
        'fundingReferences.funderName',
        'fundingReferences.awardTitle',
        'fundingReferences.fundingStream'
      ].includes(field)) {
        return 'string-field'
      }
      if (['creators', 'editors', 'advisors'].includes(field)) {
        return 'user-field'
      }
      /**
       * keyword-field:
       *  should be similar to subject field, but doesn't
       *  support the 'all' option, because each record only has 1
       *  the following data-types should use this field:
       * integer-fields:
       *  same as keyword fields but input only works with numbers
       * subject-field:
       *  this field is similar to keyword field, but supports 'all'
       *  option because each record can have multiple subjects
       * enum-with-search:
       *  used for every enum-field (to see whether supports 'all' or not)
       */
      if ([
        'tid',
        'identifierUri',
        'issn',
        'doi',
        'govdoc',
        'isbn',
        'publisherVersionUri',
        'fundingReferences.awardNumber',
        'fundingReferences.funderIdentifier'
      ].includes(field)) {
        return 'keyword-field'
      }
      if (['citationEdition', 'pmid', 'wos'].includes(field)) {
        return 'number-field'
      }
      if (['subject'].includes(field)) {
        return 'subject-field'
      }
      if ([
        'rights',
        'licenseCondition',
        'version',
        'language',
        'type',
        'subjectFos'
      ].includes(field)) {
        return 'enum-field'
      }
      if ([
        'dateIssued',
        'dateAccepted',
        'dateAvailable',
        'citationConferenceDateStart',
        'citationConferenceDateEnd'
      ].includes(field)) {
        return 'date-field'
      }
      // FIXME: When pressing enter: "Assertion failed: Input argument is not an HTMLInputElement", it is submitting the form
      if (['description', 'embargofct'].includes(field)) {
        return 'text-area-field'
      }
      if (['hasFiles'].includes(field)) {
        return 'boolean-field'
      }
    },
    getFieldLabelName (field) {
      const fields = {
        language: 'languageIso',
        dateIssued: 'issuedDate',
        dateAccepted: 'submittedDate',
        titleAlternative: 'alternativeTitle',
        publisherVersionUri: 'publisherVersion',
        description: 'abstract', // FIXME: not always ??
        licenseCondition: 'license'
      }
      return fields[field] ?? field
    },
    isFieldEmpty (field) {
      const fieldType = this.getFieldType(field.name)
      if (field.value.value === null) {
        return true
      }
      const isEmptyObject = (obj) => Object.keys(obj).length === 0

      switch (fieldType) {
        case 'string-field':
        case 'text-area-field': return /^\s*$/.test(field.value.value)

        case 'keyword-field':
        case 'number-field':
        case 'subject-field':
        case 'enum-field': return field.value.value.length === 0

        case 'date-field': return isEmptyObject(field.value.value.start) &&
          (field.value.value.option !== 'between' || isEmptyObject(field.value.value.end))
        case 'user-field': return (Object.keys(field.value.value).length === 0) ||
          (field.value.value.name !== undefined && /^\s*$/.test(field.value.value.name))

        case 'boolean': return false
      }
    }
  }
}
