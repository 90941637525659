<template>
  <div class="card">
    <div class="card__section">
      <h2 class="card__section-title h5--ssp">
        {{ $t('record.report.card.header') }}
      </h2>
      <p>{{ $t('record.report.card.description') }}</p>
      <button class="btn btn--link">
        <RouterLink
          :to="{ name: 'ReportRecordPage', params: { id: record.metadata.id } }"
          class="btn--fake-link u-text-danger"
        >
          {{ $t('record.report.card.action') }}
        </routerlink>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
      required: true
    }
  }
}
</script>
