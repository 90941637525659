import Vue from 'vue'
import Router from 'vue-router'

import PublicRoutes from '@/router/public'
import PrivateRoutes from '@/router/private'

import store from '@/store'

import PageNotFoundPage from '@/pages/PageNotFoundPage.vue'
import { setLocale, langInit } from '@/i18n'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    // allows saving position when navigation happens between same route
    if (to.meta?.scrollBehavior?.routeUpdate === 'savedPosition' && to.path === from.path) {
      return savedPosition
    }
    // allows saving position when navigation happens between different routes
    if (to.meta?.scrollBehavior?.routeEnter === 'savedPosition' && to.path !== from.path) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
  routes: [...PublicRoutes, ...PrivateRoutes]
})

// Move into vue-porg/sparrow
/**
 *
 */
export function afterVuePorg () {
  router.addRoutes([
    {
      path: '*',
      name: 'PageNotFound',
      component: PageNotFoundPage,
      meta: {
        layout: 'PageWithNavBarAndFooterLayout'
      }
    }
  ])
  router.beforeEach(async function (to, from, next) {
    // Redo hreflang links
    document.getElementById('pt_hreflang').setAttribute('href', `${window.location.protocol}//${window.location.hostname}${to.path}?lang=pt`)
    document.getElementById('en_hreflang').setAttribute('href', `${window.location.protocol}//${window.location.hostname}${to.path}?lang=en`)
    await langInit
    if (to.query.lang) {
      setLocale(to.query.lang)
    }

    if (to.path === '/error-page') {
      return next()
    }

    await store.dispatch('setProgressBar', 10)
    // we want this here because we want vue-porg's permission system to run before this global navigation guard
    if (from.name && to.path === from.path) {
      // don't run when only the query params change
      return next()
    }
    if (router.app.$auth.isLogged()) {
      await store.dispatch('fetchProfile')
      await store.dispatch('setProgressBar', 60)
    }
    next()
  })

  router.afterEach(async (to, from) => {
    if (!from.name || (to.fullPath !== from.fullPath)) {
      await store.dispatch('completeProgressBar')
    }
  })
}

export default router
