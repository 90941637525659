<template>
  <div class="card">
    <RecordItem
      v-for="x in count"
      :key="x"
    />
  </div>
</template>
<script>
import RecordItem from '@/components/loading-skeletons/RecordItem.vue'

export default {
  components: {
    RecordItem
  },
  props: {
    count: {
      type: Number,
      required: true,
      validator: prop => Number.isInteger(prop) & prop > 0
    }
  }
}
</script>
