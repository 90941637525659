<template>
  <div
    v-if="value || showInvalidWarning"
    class="record-field"
  >
    <div :class="{ 'invalid-field': showInvalidWarning }">
      <h2
        class="h5--ssp"
        :class="{ 'invalid-field__label': showInvalidWarning }"
      >
        {{ $t(`fields.record.${fieldName}.label`) }}
      </h2>
      <div
        v-if="showInvalidWarning"
        class="invalid-field__warn"
      >
        <svg
          height="18"
          width="18"
          viewBox="0 0 18 18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            stroke="var(--orange)"
            fill="none"
            fill-rule="evenodd"
          >
            <circle
              stroke-width="1.046"
              cx="9"
              cy="9"
              r="8.477"
            />
            <path
              d="M7.2 6.514c0-1.254.99-1.984 1.98-2.013.99-.03 1.98.66 1.98 1.894 0 1.999-2.148 1.122-2.2 3.625"
              stroke-width="1.046"
              stroke-linecap="round"
            />
            <path
              d="M9 13.185a.54.54 0 1 1 0-1.08.54.54 0 0 1 0 1.08Z"
              stroke-width=".25"
              fill="var(--orange)"
            />
          </g>
        </svg>
        <span>
          {{ $t('record.view.inconsistency.label') }}
        </span>
      </div>
    </div>
    <!-- Bellow is a hot glue fix to render '\n' in the preview, use proper style class later -->
    <!-- eslint-disable vue/no-v-html -->
    <p
      v-if="!invalid"
      style="white-space: pre-line;"
      v-html="output"
    />
    <!-- eslint-enable vue/no-v-html -->
    <p v-else>
      {{ $t('record.view.inconsistency.value') }}
    </p>
  </div>
</template>

<script>
import { escape as htmlEscape } from 'html-escaper'

export default {
  inject: ['ignoredFields'],
  props: {
    value: {
      type: String,
      default: () => ''
    },
    fieldName: {
      type: String,
      required: true
    },
    layout: {
      type: String,
      default: 'long'
    }
  },
  data () {
    return {
      latex2utf8: null
    }
  },
  computed: {
    invalid () {
      if (this.ignoredFields) {
        return this.ignoredFields.includes(this.fieldName)
      }
      return false
    },
    showInvalidWarning () {
      return this.invalid && this.$auth.isLogged()
    },
    output () {
      const safeValue = htmlEscape(this.value.trim())
      if (!this.latex2utf8) {
        return safeValue
      } else {
        return this.latex2utf8(safeValue)
      }
    }
  },
  async created () {
    const { latex2utf8 } = await import('@/utils/Latex2Utf8')
    this.latex2utf8 = latex2utf8
  }
}
</script>
