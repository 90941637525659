import { guardWithErrorHandling, fetchRecord, fetchAuthorByUserId } from '@/router/navigation-guards'

import RecordEditionsPage from '@/pages/RecordEditionsPage.vue'
import RecordEditionPage from '@/pages/RecordEditionPage.vue'

export default [
  {
    path: '/records/:id/edits',
    name: 'RecordEditionsPage',
    component: RecordEditionsPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['user']
      }
    },
    props: route => ({
      record: route.params.record
    }),
    beforeEnter: guardWithErrorHandling(
      async function (to, from, next) {
        to.params.record = await fetchRecord({ id: to.params.id, sources: true, countView: true, mergeHistory: true })
        for (const source of to.params.record.metadata.sources) {
          source.author = await fetchAuthorByUserId({ userId: source.author, countView: false })
        }
        for (const merge of to.params.record.metadata.mergeHistory) {
          merge.metadata.preferredSource.author = await fetchAuthorByUserId({ userId: merge.metadata.preferredSource.author, countView: false })
        }
        next()
      }
    )
  },
  {
    path: '/records/:id/edits/:editId',
    name: 'RecordEditionPage',
    component: RecordEditionPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['user']
      }
    },
    props: route => ({
      source: route.params.source,
      previousVersion: route.params.previousVersion,
      nextVersion: route.params.nextVersion
    }),
    beforeEnter: guardWithErrorHandling(
      async function (to, from, next) {
        const record = await fetchRecord({ id: to.params.id, sources: true, countView: true })
        const sources = record.metadata.sources.sort((a, b) => {
          if (new Date(a.updated) > new Date(b.updated)) {
            return 1
          } else {
            return -1
          }
        })
        const sourceIndex = sources.findIndex(({ id }) => id === to.params.editId)
        const source = sources[sourceIndex]
        source.author = await fetchAuthorByUserId({ userId: source.author, countView: false })
        source.record.metadata = record.metadata
        to.params.previousVersion = sources[sourceIndex - 1] || null
        to.params.nextVersion = sources[sourceIndex + 1] || null
        to.params.source = source
        next()
      }
    )
  }
]
