<template>
  <div
    class="f-field"
    :class="errorClasses"
  >
    <div
      class="f-field__input-wrapper"
      :class="{ 'f-field__input-wrapper--hint': !!$scopedSlots && !!$scopedSlots.hint}"
    >
      <input
        :id="fieldName"
        v-model="textInputVal"
        :placeholder="placeholder ? placeholder : $t(`fields.${fieldName}.placeholder`)"
        :name="fieldName"
        :required="isRequired"
        :inputmode="inputMode"
        class="f-field__input"
        :class="{ 'f-field__input--is-filled': value !== '' }"
        type="text"
        autocomplete=""
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        v-on="listeners"
      >
      <slot name="hint" />
    </div>
    <label
      v-if="showLabel"
      :for="fieldName"
      :class="{ 'f-field__label--required' : isRequired }"
      class="f-field__label"
    >
      {{ label ? label : $t(`fields.${fieldName}.label`) }}
    </label>

    <div
      v-if="$slots && $slots.default && !!$slots.default[0]"
      class="f-field__tooltip"
    >
      <Tooltip>
        <slot />
      </Tooltip>
    </div>
    <Transition name="validation-fade">
      <!-- f-field--danger -->
      <p
        v-if="error && error.message"
        class="f-field__validation"
      >
        {{ error.message }}
      </p>
    </Transition>
  </div>
</template>

<script>
import Tooltip from '@/components/utils/Tooltip.vue'

export default {
  components: {
    Tooltip
  },
  props: {
    value: {
      default: null,
      type: String
    },
    fieldName: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    error: {
      type: Object,
      default: () => {}
    },
    inputMode: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  computed: {
    errorClasses () {
      return this.error?.type ? `f-field--${this.error.type}` : ''
    },
    listeners () {
      return {
        ...this.$listeners,
        input: event =>
          this.$emit('input', event.target.value)
      }
    },
    textInputVal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>
