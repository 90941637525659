import { getUserRolesFromCookie } from '@/router/navigation-guards'
import intersection from 'underscore/modules/intersection'
import { types as recordTypes } from '@scholar/metadata-utils/main'

const ManageDuplicatedRecordsTabPage = () => import('@/pages/ManageDuplicatedRecordsTabPage.vue')
const ManageInconsistenciesTabPage = () => import('@/pages/ManageInconsistenciesTabPage.vue')
const ManageDuplicatedRecordsPage = () => import('@/pages/ManageDuplicatedRecordsPage.vue')
const ManageInconsistenciesPage = () => import('@/pages/ManageInconsistenciesPage.vue')
const ManageComplaintsTabPage = () => import('@/pages/ManageComplaintsTabPage.vue')
const ManageComplaintsPage = () => import('@/pages/ManageComplaintsPage.vue')

export default [
  {
    path: 'tasks',
    name: 'TasksPage',
    redirect: { name: 'ManageDuplicatedRecordsPage' }
  },
  {
    path: 'tasks/duplicates',
    name: 'ManageDuplicatedRecordsPage',
    component: ManageDuplicatedRecordsPage,
    redirect: { name: 'ManageSystemDuplicatedRecordsPage' },
    meta: {
      activeLinkStrategy: 'routeAndChildren'
    },
    props: route => ({
      page: Number(route.query.page) || 1,
      q: route.query.q,
      perPage: Number(route.query.perPage) || undefined,
      type: typeof route.query.type === 'string' ? [route.query.type] : route.query.type,
      recordType: intersection(recordTypes,
        typeof route.query.recordType === 'string' ? [route.query.recordType] : route.query.recordType)
    }),
    children: [
      {
        path: 'system',
        name: 'ManageSystemDuplicatedRecordsPage',
        component: ManageDuplicatedRecordsTabPage,
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        },
        beforeEnter (to, from, next) {
          const roles = getUserRolesFromCookie()
          if (roles.includes('operator') || roles.includes('author')) {
            next()
          } else {
            next({ name: 'PageNotFound', params: [to.path] })
          }
        }
      },
      {
        path: 'user',
        name: 'ManageUserDuplicatedRecordsPage',
        component: ManageDuplicatedRecordsTabPage,
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        },
        beforeEnter (to, from, next) {
          const roles = getUserRolesFromCookie()
          if (roles.includes('operator')) {
            next()
          } else {
            next({ name: 'PageNotFound', params: [to.path] })
          }
        }
      },
      {
        path: 'taken',
        name: 'ManageTakenDuplicatedRecordsPage',
        component: ManageDuplicatedRecordsTabPage,
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        },
        beforeEnter (to, from, next) {
          const roles = getUserRolesFromCookie()
          if (roles.includes('operator') || roles.includes('author')) {
            next()
          } else {
            next({ name: 'PageNotFound', params: [to.path] })
          }
        }
      }
    ]
  },
  {
    path: 'tasks/inconsistencies',
    name: 'ManageInconsistenciesPage',
    component: ManageInconsistenciesPage,
    redirect: { name: 'ManageSystemInconsistenciesPage' },
    meta: {
      activeLinkStrategy: 'routeAndChildren'
    },
    props: route => ({
      page: Number(route.query.page) || 1,
      q: route.query.q,
      perPage: Number(route.query.perPage) || undefined || 10,
      type: typeof route.query.type === 'string' ? [route.query.type] : route.query.type,
      recordType: intersection(recordTypes, typeof route.query.recordType === 'string' ? [route.query.recordType] : route.query.recordType)
    }),
    children: [
      {
        path: 'system',
        name: 'ManageSystemInconsistenciesPage',
        component: ManageInconsistenciesTabPage,
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        },
        beforeEnter (to, from, next) {
          const roles = getUserRolesFromCookie()
          if (roles.includes('author') || roles.includes('operator')) {
            next()
          } else {
            next({ name: 'PageNotFound', params: [to.path] })
          }
        }
      },
      {
        path: 'user',
        name: 'ManageUserInconsistenciesPage',
        component: ManageInconsistenciesTabPage,
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        },
        beforeEnter (to, from, next) {
          const roles = getUserRolesFromCookie()
          if (roles.includes('operator')) {
            next()
          } else if (roles.includes('author')) {
            next({ name: 'ManageSystemInconsistenciesPage', replace: false })
          } else {
            next({ name: 'PageNotFound', params: [to.path] })
          }
        }
      },
      {
        path: 'taken',
        name: 'ManageTakenInconsistenciesPage',
        component: ManageInconsistenciesTabPage,
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        },
        beforeEnter (to, from, next) {
          const roles = getUserRolesFromCookie()
          if (roles.includes('author') || roles.includes('operator')) {
            next()
          } else {
            next({ name: 'PageNotFound', params: [to.path] })
          }
        }
      }
    ]
  },
  {
    path: 'tasks/complaints',
    name: 'ManageComplaintsPage',
    component: ManageComplaintsPage,
    redirect: { name: 'ManageOperatorComplaintsPage' },
    meta: {
      'porg-auth': {
        roles: ['operator']
      },
      activeLinkStrategy: 'routeAndChildren'
    },
    props: route => ({
      page: Number(route.query.page) || 1,
      q: route.query.q,
      recordType: intersection(recordTypes, typeof route.query.recordType === 'string' ? [route.query.recordType] : route.query.recordType),
      perPage: Number(route.query.perPage) || undefined
    }),
    children: [
      {
        path: 'all',
        name: 'ManageOperatorComplaintsPage',
        component: ManageComplaintsTabPage,
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'taken',
        name: 'ManageTakenComplaintsPage',
        component: ManageComplaintsTabPage,
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      }
    ]
  }
]
