import { isUnitManager, fetchUnit, fetchAllUnitManagers, guardWithErrorHandling, getUserRolesFromCookie } from '@/router/navigation-guards'
import { get as getResearcher, getAffiliation } from '@/api/researchers'

const ManageUnitInfoPage = () => import('@/pages/ManageUnitInfoPage.vue')
const ManageUnitAffiliationsPage = () => import('@/pages/ManageUnitAffiliationsPage.vue')
const ManageUnitAffiliationsTabPage = () => import('@/pages/ManageUnitAffiliationsTabPage.vue')
const EditUnitAffiliationPage = () => import('@/pages/EditUnitAffiliationPage.vue')

export default [
  {
    path: 'management/:unitId',
    name: 'ManageUnitPage',
    redirect: { name: 'ManageUnitInfoPage' },
    props: route => ({
      unitId: route.params.unitId
    }),
    beforeEnter: async (to, from, next) => {
      if (await isUnitManager({ unitId: to.params.unitId })) {
        return next()
      }
      next({ name: 'PageNotFound', params: [to.path] })
    }
  },
  {
    path: 'management/:unitId/info',
    name: 'ManageUnitInfoPage',
    component: ManageUnitInfoPage,
    props: route => ({
      unitId: route.params.unitId,
      currentUnitData: route.params.currentUnitData,
      currentManagersPage: route.params.currentManagersPage
    }),
    beforeEnter: async (to, from, next) => {
      if (await isUnitManager({ unitId: to.params.unitId })) {
        const [unit, managersPage] = await Promise.all([
          fetchUnit({ id: to.params.unitId }),
          fetchAllUnitManagers({ id: to.params.unitId })
        ])
        to.params.currentUnitData = unit
        to.params.currentManagersPage = managersPage
        return next()
      }
      next({ name: 'PageNotFound', params: [to.path] })
    }
  },
  {
    path: 'management/:unitId/affiliations',
    name: 'ManageUnitAffiliationsPage',
    redirect: { name: 'ManageUnitCurrentAffiliationsPage' },
    component: ManageUnitAffiliationsPage,
    props: route => ({
      unit: route.params.unit,
      page: Number(route.query.page) || 1,
      q: route.query.q,
      perPage: Number(route.query.perPage) || undefined,
      verified: route.meta.verified,
      ongoing: route.meta.ongoing
    }),
    beforeEnter: guardWithErrorHandling(
      async (to, from, next) => {
        if (!await isUnitManager({ unitId: to.params.unitId })) {
          next({ name: 'PageNotFound', params: [to.path] })
        }
        const unit = await fetchUnit({ id: to.params.unitId })
        to.params.unit = unit
        return next()
      }
    ),
    meta: {
      activeLinkStrategy: 'routeAndChildren'
    },
    children: [
      {
        path: 'pending',
        name: 'ManageUnitPendingAffiliationsPage',
        component: ManageUnitAffiliationsTabPage,
        meta: {
          verified: false,
          ongoing: undefined,
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'current',
        name: 'ManageUnitCurrentAffiliationsPage',
        component: ManageUnitAffiliationsTabPage,
        meta: {
          verified: true,
          ongoing: true,
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'past',
        name: 'ManageUnitPastAffiliationsPage',
        component: ManageUnitAffiliationsTabPage,
        meta: {
          verified: true,
          ongoing: false,
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      }
    ]
  },
  {
    path: 'management/:unitId/affiliations/:userId/:affiliationId',
    name: 'EditUnitAffiliationPage',
    component: EditUnitAffiliationPage,
    props: route => ({
      researcher: route.params.researcher,
      unit: route.params.unit,
      affiliation: route.params.affiliation
    }),
    beforeEnter: guardWithErrorHandling(
      async (to, from, next) => {
        const roles = getUserRolesFromCookie()
        if (!await isUnitManager({ unitId: to.params.unitId }) && !roles.includes('operator')) {
          next({ name: 'PageNotFound', params: [to.path] })
        }
        const [researcher, affiliation] = await Promise.all([
          getResearcher({ userId: to.params.userId }),
          getAffiliation({ userId: to.params.userId, affiliationId: to.params.affiliationId })
        ])
        to.params.researcher = researcher
        to.params.unit = affiliation.unit
        delete affiliation.unit
        to.params.affiliation = affiliation
        return next()
      }
    )
  }
]
