<template>
  <component
    :is="layout"
    v-if="layout"
  >
    <router-view />
  </component>

  <router-view v-else />
</template>

<script>
import PageWithNavBarAndFooterLayout from '@/layouts/PageWithNavBarAndFooterLayout.vue'
import PageWithNavBarLayout from '@/layouts/PageWithNavBarLayout.vue'
import PreviewRecordLayout from '@/layouts/PreviewRecordLayout.vue'
import SingleRecordLayout from '@/layouts/SingleRecordLayout.vue'

export default {
  components: {
    PageWithNavBarAndFooterLayout,
    PageWithNavBarLayout,
    PreviewRecordLayout,
    SingleRecordLayout
  },
  data () {
    return {
      layout: undefined,
      navigation: {
        // must be nested to preserve reactivity
        previous: undefined
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        const metas = to.matched.filter(r => r.meta.layout).map(r => r.meta.layout)
        const layout = metas.length > 0 ? metas[metas.length - 1] : undefined
        if (layout !== this.layout) {
          this.layout = layout
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/resets";
@import "@/assets/scss/typography";
@import "@/assets/scss/lists";
@import "@/assets/scss/cards";
@import "@/assets/scss/buttons";
@import "@/assets/scss/labels";
@import "@/assets/scss/utilities";
@import "@/assets/scss/helpers";
@import "@/assets/scss/loading";
@import "@/assets/scss/icons";
@import "@/assets/scss/avatars";
@import "@/assets/scss/empty-states";

// Global component styles
@import "@/assets/scss/tabs";
@import "@/assets/scss/forms";

// Global layout styles
@import "@/assets/scss/grid";
@import "@/assets/scss/layouts/layout-sidebar";
@import "@/assets/scss/layouts/layout-search-results";
@import "@/assets/scss/layouts/layout-wizard";

body {
  background-color: $light-blue;
  overflow-x: hidden;
}

.scrollLock {
  overflow: hidden;
}
</style>
