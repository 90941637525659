<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <component
    :is="tag"
    class="card-row record"
  >
    <div class="card-row__text">
      <p class="small record__item-type">
        {{ $t(`record.view.types.${record.type}`) }}
        <ExternalRepositoriesBadge
          v-if="record.metadata && record.metadata.externalSources"
          :external-sources="record.metadata.externalSources"
        />
      </p>
      <component
        :is="recordTitleTag"
        class="h4 record__item-title"
        :class="{ 'record__item-title--search': !highlightTitle }"
      >
        <Component
          :is="clickable ? 'router-link' : 'span'"
          :to="clickable ? { name: 'RecordPage', params: { id: record.metadata.id } } : false"
          :class="{ 'record__item-title-link': clickable }"
          v-html="title"
        />
      </component>
      <slot />
      <div class="record__item-publishing-data">
        <p
          v-if="record.citationTitle"
          class="record__item-academic-journal"
          v-html="citationTitle"
        />
        <p>
          <span
            v-if="record.creators"
            class="record__item-author"
          >
            <!-- Show authors -->
            <template v-for="(author, index) in showAuthors">
              <Component
                :is="clickable && author.userId ? 'router-link' : 'span'"
                :key="index"
                :to="clickable && author.userId ? { name: 'ResearcherPage', params: { userId: author.userId } } : false"
                v-html="matchedAuthor(index)"
              />{{ record.creators.length - 1 !== index ? ', ' : ' ' }}
            </template>
            <!-- Show toggle to expand/collapse authors -->
            <button
              v-if="record.creators.length > 3"
              @click="authorsExpanded = !authorsExpanded"
            >
              <strong>{{ authorsExpanded ? $t('hideAuthors') : $tc('showAuthors', record.creators.length - 3) }}</strong>
              <span
                v-if="!authorsExpanded"
                style="white-space: pre;"
              >, </span>
            </button>
            <!-- Show last element -->
            <template v-if="record.creators.length > 2 && !authorsExpanded">
              <Component
                :is="clickable && record.creators.at(-1).userId ? 'router-link' : 'span'"
                :to="clickable && record.creators.at(-1).userId ? { name: 'ResearcherPage', params: { userId: record.creators.at(-1).userId } } : false"
                v-html="matchedAuthor(record.creators.length-1)"
              />
            </template>
          </span>
          <template v-if="record.dateIssued && record.dateIssued.year">
            &mdash;
            <span class="record__item-publication-date">
              {{ record.dateIssued.year }}
            </span>
          </template>
          <template v-if="record.publisher">
            &mdash;
            <span
              class="record__item-publisher"
              v-html="publisher"
            />
          </template>
        </p>
        <template v-if="dynamicMatch">
          <p v-html="dynamicMatch" />
        </template>
      </div>
    </div>
    <div
      v-if="editable || removable"
      class="card-row__meta card-row__meta--align-start"
    >
      <Dropdown>
        <button
          slot="dropdown-trigger"
          class="i-ellipsis"
          aria-label="More options"
          aria-haspopup="true"
          aria-expanded="false"
          @click.prevent=""
        />
        <template slot="dropdown-panel">
          <ul class="dropdown-menu dropdown--xs">
            <li
              v-if="editable"
              class="dropdown-menu__item"
            >
              <router-link
                tag="button"
                class="dropdown-menu__link"
                :to="{ name: 'EditRecordPage', params: { id: record.metadata.id } }"
              >
                {{ $t('actions.edit.record') }}
              </router-link>
            </li>
            <li
              v-if="removable"
              class="dropdown-menu__item"
            >
              <button
                class="dropdown-menu__link u-text-danger"
                @click="removeRecord"
              >
                {{ $t('actions.remove.record') }}
              </button>
            </li>
          </ul>
        </template>
      </Dropdown>
    </div>
  </component>
</template>

<script>
import { escape as htmlEscape } from 'html-escaper'
import Dropdown from '@/components/utils/Dropdown.vue'
import ExternalRepositoriesBadge from '@/components/records/ExternalRepositoriesBadge.vue'
import RecordSearchFieldsHelpers from '@/mixins/RecordSearchFieldsHelpers'

export default {
  components: {
    Dropdown,
    ExternalRepositoriesBadge
  },
  mixins: [RecordSearchFieldsHelpers],
  props: {
    record: {
      type: Object,
      required: true
    },
    highlight: {
      type: Object,
      default: () => ({})
    },
    tag: {
      type: String,
      default: 'article'
    },
    recordTitleTag: {
      type: String,
      default: 'p'
    },
    removable: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: true
    },
    highlightTitle: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: true
    },
    units: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      authorsExpanded: false,
      matchedAuthors: { creators: new Set(), advisors: new Set(), editors: new Set() },
      latex2utf8: null
    }
  },
  computed: {
    showAuthors () {
      return this.authorsExpanded ? this.record.creators : this.record.creators.slice(0, 2)
    },
    title () {
      return this.matchedOrDefault('title')
    },
    citationTitle () {
      return this.matchedOrDefault('citationTitle')
    },
    publisher () {
      return this.matchedOrDefault('publisher')
    },
    dynamicMatch () {
      if (this.highlight.description) {
        return this.truncatedDescription
      }

      // TODO: add more than one matched field?
      // eslint-disable-next-line no-unused-vars
      const { description, title, citationTitle, publisher, 'creators.name': name, 'creators.userId': userId, ...rest } = this.highlight
      const remainingKeys = Object.keys(rest).filter(k => !k.includes('ngrams'))
      if (remainingKeys.length > 0) {
        let key = remainingKeys[0]
        if (key.includes('affiliations')) {
          key = key.split('.')[0]
          const match = this.matchedAffiliations[key].map(name => `<mark>${name}</mark>`).join(', ')
          return this.$t(`fields.record.${this.getFieldLabelName(key)}.affiliations.label`) + `: ${match}`
        } else if (key.startsWith('advisors') || key.startsWith('editors')) {
          key = key.split('.')[0]
          const indices = [...this.matchedAuthors[key]]
          const signatures = indices.map(idx => this.record[key][idx].name)
          const match = signatures.map(name => `<mark>${name}</mark>`).join(', ')
          return this.$t(`fields.record.${this.getFieldLabelName(key)}.label`) + `: ${match}`
        } else {
          return this.$t(`fields.record.${this.getFieldLabelName(key)}.label`) + ': ' + this.matchedOrDefault(key)
        }
      }

      if (!this.compact && this.record.description) {
        return this.truncatedDescription
      }
      return undefined
    },
    truncatedDescription () {
      let result = this.matchedOrDefault('description')
      if (result === undefined) {
        return result
      }

      // this 300 is the same set up in the elastic search query, maybe these could be unified better
      if (this.record.description.length > 300 && !this.highlight.description) {
        return result.slice(0, 300) + '…'
      }

      const strippedMatch = result.replaceAll(/<\/?mark>/g, '')
      const original = this.escape(this.record.description)
      if (strippedMatch.slice(0, 10) !== original.slice(0, 10)) {
        // if the beginnings are different, a part of the start was stripped
        result = '…' + result
      }
      if ([...strippedMatch].reverse().join('').slice(0, 10) !== [...original].reverse().join('').slice(0, 10)) {
        // if the endings are different, a part of the end was stripped
        result = result + '…'
      }
      return result
    },
    matchedAffiliations () {
      const matchedAffiliations = { creators: [], advisors: [], editors: [] }
      if (this.units.length > 0) {
        const unitIdsSet = new Set();
        ['creators', 'advisors', 'editors'].forEach(field => {
          if (this.record[field] === undefined) {
            return
          }
          if (this.highlight[`${field}.affiliations`]) {
            this.highlight[`${field}.affiliations`].map(u => this.removeSearchTags(u)).map(id => unitIdsSet.add(id))
            matchedAffiliations[field].push(...Array.from(unitIdsSet).map(id => this.units.find(u => u.id === id).name))
          }
        })
      }
      return matchedAffiliations
    }
  },
  async created () {
    ['creators', 'advisors', 'editors'].forEach(field => {
      if (this.record[field] === undefined) {
        return
      }
      this.checkMatchingAuthors(field)
    })
    const { latex2utf8 } = await import('@/utils/Latex2Utf8')
    this.latex2utf8 = latex2utf8
  },
  methods: {
    async removeRecord () {
      this.$emit('delete', this.record.metadata.id)
    },
    removeSearchTags (val) {
      return val.replaceAll('SEARCH-MATCH-START', '').replaceAll('SEARCH-MATCH-END', '')
    },
    escape (val) {
      if (val !== undefined) {
        return htmlEscape(val).replaceAll('SEARCH-MATCH-START', '<mark>').replaceAll('SEARCH-MATCH-END', '</mark>')
      }
      return undefined
    },
    matchedOrDefault (fieldName) {
      if (this.highlight[fieldName]?.[0]) {
        if (!this.latex2utf8) return this.escape(this.highlight[fieldName]?.[0])
        return this.latex2utf8(this.escape(this.highlight[fieldName]?.[0]))
      } else {
        if (!this.latex2utf8) return this.escape(this.record[fieldName])
        return this.latex2utf8(this.escape(this.record[fieldName]))
      }
    },
    matchedAuthor (idx) {
      const author = this.record.creators[idx]
      if (this.matchedAuthors.creators.has(idx)) {
        return `<span><mark>${htmlEscape(author.name)}</mark><span>`
      } else {
        return `<span>${htmlEscape(author.name)}<span>`
      }
    },
    checkMatchingAuthors (field) {
      if (this.record[field] === undefined) {
        return
      }

      // must use indices because userId might not exist
      const nUsers = this.record[field].length
      if (this.record[field]) {
        for (let i = 0; i < nUsers; i++) {
          const author = this.record[field][i]
          if (this.authorHighlighted(author, field)) {
            this.matchedAuthors[field].add(i)
            if (i > 1 && i < nUsers - 1) {
              this.authorsExpanded = true
            }
          }
        }
      }
    },
    authorHighlighted (author, field) {
      if (this.highlight[`${field}.userId`] !== undefined &&
          this.highlight[`${field}.userId`].map(id => this.removeSearchTags(id)).includes(author.userId)) {
        return true
      }

      if (this.highlight[`${field}.name`] !== undefined) {
        for (const name of this.highlight[`${field}.name`]) {
          if (this.removeSearchTags(name) === author.name) {
            return true
          }
        }
      }
      return false
    }
  },
  i18n: {
    messages: {
      pt: {
        showAuthors: '+ {count} autores',
        hideAuthors: 'Ocultar autores'
      },
      en: {
        showAuthors: '+ {count} authors',
        hideAuthors: 'Hide authors'
      }
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.record__item-type {
  text-transform: capitalize;
}
.record__item-title {
  margin-top: 0.5rem;
  margin-bottom: 0;
  position: relative;

  &--search {
    font-weight: 400;
  }
}
.record__item-title-link {
  cursor: pointer;
  color: $dark;
  &::before {
    content: "";
    position: absolute;
    cursor: pointer;
    padding-top: 2rem;
    inset: -2rem 0 0;

    // background-color: rgba($blue, .4);
  }
}
.record__item-academic-journal {
  color: $gray;
  line-height: 1.2;
}
.record__item-author {
  color: $slate;
}
.record__item-publishing-data {
  p {
    margin: 0.25rem 0;
  }
}
</style>
