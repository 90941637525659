<template>
  <div
    class="warning-box"
    :class="{
      'warning-box--inline': inline,
      [`warning-box--${type}`]: true
    }"
  >
    <div class="warning-box__container">
      <div
        v-if="$slots.badge"
        class="label warning-box__badge"
        :class="`label--${type}`"
      >
        <slot name="badge" />
      </div>
      <div
        v-if="$slots.title"
        class="warning-box__title"
      >
        <slot name="title" />
      </div>
      <div class="warning-box__message">
        <slot name="message" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'danger',
      validator (prop) { return ['info', 'warn', 'danger'].includes(prop) }
    },
    inline: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

.warning-box__container {
  border-radius: 0.25rem;
  padding: 1.5rem;
}
.warning-box__badge {
  margin-bottom: 1rem;
}

.warning-box__message * {
  color: inherit;
}

.warning-box__title {
  // title styles
  font-family: $title-font;
  font-weight: 500;
  line-height: 120%;
  margin: 0;

  // h3 size
  font-size: 1.4375rem;

  @media screen and (width <= 40rem) {
    font-size: 1.6875rem;
  }
}

.warning-box__title + .warning-box__message {
  margin-top: 1rem;
}

.warning-box--danger {
  .warning-box__title {
    color: $magenta;
  }
  .warning-box__message {
    color: $magenta-600;
  }
  .warning-box__container {
    background-color: #f9e9eb;
    border: 1px solid $magenta;
  }
  .warning-box__badge {
    width: max-content;
  }
}

.warning-box--warn {
  .warning-box__title {
    color: $orange;
  }
  .warning-box__message {
    color: $orange-600;
  }
  .warning-box__container {
    background-color: #fbeee5;
    border: 1px solid $orange;
  }
  .warning-box__badge {
    width: max-content;
  }
}

.warning-box--info {
  .warning-box__title {
    color: $blue-600;
  }
  .warning-box__message p {
    color: $blue-700;
  }
  .warning-box__container {
    background-color: #e6effa;
    border: 1px solid $blue-700;
  }
  .warning-box__badge {
    width: max-content;
  }
}
.warning-box--inline {
  .warning-box__container {
    padding: 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    max-width: 100%;

    .warning-box__message {
      white-space: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .warning-box__badge {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
}
</style>
