<template>
  <Transition
    name="fade"
    mode="out-in"
  >
    <p
      v-if="isLoading"
      key="counter-loading"
      v-bind="$attrs"
    >
      {{ $t('loading...') }}
    </p>
    <p
      v-else-if="isSearching"
      key="counter-searching"
      v-bind="$attrs"
    >
      {{ $t('searching...') }}
    </p>
    <p
      v-else
      key="counter-results"
      v-bind="$attrs"
    >
      {{ $tc(entriesString, count, { count }) }}
    </p>
  </Transition>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    isSearching: {
      type: Boolean,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    entriesString: {
      type: String,
      default: 'entries.count'
    }
  }
}
</script>
