<template>
  <Dropdown
    ref="dropdown"
    size="lg"
    @hide="closeMenu"
    @open="$emit('open')"
    @trap="setFocusTrap"
  >
    <button
      slot="dropdown-trigger"
      :aria-label="$t('menu.account.aria-label')"
      class="user-trigger"
      aria-haspopup="true"
    >
      <UserAvatar
        :name="profile.name"
        :src="profile.avatar"
        class="figure--36"
      />
    </button>
    <div
      ref="dropdownPanel"
      slot="dropdown-panel"
      class="user-dropdown-menu__panel"
    >
      <ul
        ref="menu"
        class="dropdown-menu"
      >
        <li class="dropdown-user__details">
          <UserAvatar
            :name="profile.name"
            :src="profile.avatar"
            class="figure--48"
          />
          <div class="dropdown-user__text">
            <div class="name">
              {{ profile.name }}
            </div>
            <div
              v-if="profile.username"
              class="username"
            >
              {{ profile.username }}
            </div>
          </div>
        </li>
        <!-- VIEW PROFILE -->
        <li
          v-if="profile.roles.includes('author')"
          class="dropdown-menu__item"
        >
          <RouterLink
            :to="{ name: 'ResearcherPage', params: { userId: profile.username } }"
            class="dropdown-menu__link"
          >
            <span class="link-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="22"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path d="M-2-1h24v24H-2z" />
                  <g
                    stroke="var(--gray-400)"
                    stroke-width="1.8307692"
                    transform="translate(1 1)"
                  >
                    <path d="M8.4332777 13C3.775707 13 0 15.205184 0 19.9591273h16.8665554C16.8665554 15.205184 13.0908484 13 8.4332777 13z" />
                    <circle
                      cx="8.6060606"
                      cy="5"
                      r="5"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <span class="link-text">
              {{ $t('menu.view.profile') }}
            </span>
          </RouterLink>
        </li>

        <!-- CREATE RECORD -->
        <li
          v-if="profile.roles.includes('author') || profile.roles.includes('operator') || (managedUnits && managedUnits.length > 0)"
          class="dropdown-menu__item"
        >
          <RouterLink
            :to="{ name: 'CreateRecordPage' }"
            class="dropdown-menu__link"
          >
            <span class="link-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
              >
                <g
                  fill="var(--gray-400)"
                  fill-rule="evenodd"
                >
                  <rect
                    width="18"
                    height="2.4"
                    y="8"
                    rx="1.2"
                  />
                  <rect
                    width="18"
                    height="2.4"
                    y="8"
                    rx="1.2"
                    transform="rotate(90 9 9.2)"
                  />
                </g>
              </svg>
            </span>
            <span class="link-text">
              {{ $t('menu.create.record') }}
            </span>
          </RouterLink>
        </li>

        <!-- ACCOUNT SUBMENU -->
        <li class="dropdown-menu__item has-children">
          <button
            v-if="$auth.isLogged"
            aria-controls="my-account-menu"
            class="dropdown-menu__link"
            @click.prevent.stop="openSubMenu"
          >
            <span class="link-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path d="M-1-1h24v24H-1z" />
                  <path
                    stroke="var(--gray-400)"
                    stroke-width="1.6"
                    d="M4.7734498 2.9929783c-.2273648.1161469-.567943.3765366-.985939.7945325-.417996.417996-.6783856.7585742-.7945325.985939L4.469358 6.9625645l-.2277796.4251855c-.236851.4421188-.4297518.9067712-.5754995 1.388053L3.52635 9.23721l-2.5910052.5038065C.8567018 9.983916.8 10.4088645.8 11c0 .5911356.0567019 1.0160841.1353448 1.2589835L3.52635 12.76279l.139729.461407c.1457476.4812818.3386484.9459342.5754994 1.388053l.2277796.4251855-1.4763797 2.1891147c.1161469.2273647.3765366.567943.7945325.985939.417996.4179959.7585742.6783856.985939.7945325l2.1891147-1.4763797.4251855.2277796c.4421188.236851.9067712.4297518 1.388053.5754994l.461407.139729.5038065 2.5910052C9.983916 21.1432981 10.4088645 21.2 11 21.2c.5911356 0 1.0160841-.0567019 1.2589835-.1353448L12.76279 18.47365l.461407-.139729c.4812818-.1457476.9459342-.3386484 1.388053-.5754994l.4251855-.2277796 2.1891147 1.4763797c.2273647-.1161469.567943-.3765366.985939-.7945325.4179959-.417996.6783856-.7585743.7945325-.985939l-1.4763797-2.1891147.2277796-.4251855c.236851-.4421188.4297518-.9067712.5754994-1.388053l.139729-.461407 2.5910052-.5038065C21.1432981 12.0160841 21.2 11.5911356 21.2 11s-.0567019-1.016084-.1353448-1.2589835L18.47365 9.23721l-.139729-.461407c-.1457476-.4812818-.3386484-.9459342-.5754994-1.388053l-.2277796-.4251855 1.4763797-2.1891147c-.1161469-.2273648-.3765366-.567943-.7945325-.985939-.417996-.417996-.7585743-.6783856-.985939-.7945325L15.0374355 4.469358l-.4251855-.2277796c-.4421188-.236851-.9067712-.4297518-1.388053-.5754995L12.76279 3.52635 12.2589835.9353448C12.0160841.8567018 11.5911356.8 11 .8S9.983916.8567019 9.7410165.9353448L9.23721 3.52635l-.461407.139729c-.4812818.1457476-.9459342.3386484-1.388053.5754994l-.4251855.2277796-2.1891147-1.4763797zM11 15.6076923c-2.5447582 0-4.6076923-2.0629341-4.6076923-4.6076923S8.4552418 6.3923077 11 6.3923077 15.6076923 8.4552418 15.6076923 11 13.5447582 15.6076923 11 15.6076923z"
                  />
                </g>
              </svg>

            </span>
            <span class="link-text">
              {{ $t('menu.account.header') }}
            </span>
          </button>
          <ul
            id="my-account-menu"
            class="dropdown-menu__secondary-nav is-hidden"
          >
            <li class="dropdown-menu__item go-back">
              <button
                class="dropdown-menu__link"
                aria-controls="my-account-menu"
                @click.prevent.stop="closeSubMenu"
              >
                <span class="link-text">
                  {{ $t('menu.back') }}
                </span>
              </button>
            </li>
            <li class="dropdown-menu__item">
              <RouterLink
                :to="{ name: 'EditProfilePage' }"
                class="dropdown-menu__link"
              >
                <span class="link-text">
                  {{ $t('menu.account.submenu.profile') }}
                </span>
              </RouterLink>
            </li>
            <li class="dropdown-menu__item">
              <RouterLink
                :to="{ name: 'ManageEmailsPage' }"
                class="dropdown-menu__link"
              >
                <span class="link-text">
                  {{ $t('menu.account.submenu.emails') }}
                </span>
              </RouterLink>
            </li>
            <template v-if="profile.roles.includes('author')">
              <li class="dropdown-menu__item">
                <RouterLink
                  :to="{ name: 'ManageAliasesPage' }"
                  class="dropdown-menu__link"
                >
                  <span class="link-text">
                    {{ $t('menu.account.submenu.aliases') }}
                  </span>
                </RouterLink>
              </li>
              <li class="dropdown-menu__item">
                <RouterLink
                  :to="{ name: 'ManageExternalRepositoriesPage' }"
                  class="dropdown-menu__link"
                >
                  <span class="link-text">
                    {{ $t('menu.account.submenu.external.repositories') }}
                  </span>
                </RouterLink>
              </li>
              <li class="dropdown-menu__item">
                <RouterLink
                  :to="{ name: 'ManageRecordsPage' }"
                  class="dropdown-menu__link"
                >
                  <span class="link-text">
                    {{ $t('menu.account.submenu.records') }}
                  </span>
                </RouterLink>
              </li>
              <li class="dropdown-menu__item">
                <RouterLink
                  :to="{ name: 'ManageAffiliationsPage' }"
                  class="dropdown-menu__link"
                >
                  <span class="link-text">
                    {{ $t('menu.account.submenu.affiliations') }}
                  </span>
                </RouterLink>
              </li>
            </template>
          </ul>
        </li>

        <!-- TASKS SUBMENU -->
        <li
          v-if="profile.roles.includes('author') || profile.roles.includes('operator')"
          class="dropdown-menu__item has-children"
        >
          <button
            aria-controls="tasks-menu"
            class="dropdown-menu__link"
            @click.prevent.stop="openSubMenu"
          >
            <span class="link-icon">
              <svg
                width="24"
                height="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path d="M0 1h24v24H0z" />
                  <g stroke="var(--gray-400)">
                    <path
                      d="M2.077 1.12h20.086c.529 0 .957.352.957.786v20.428c0 .434-.428.786-.957.786H2.077c-.529 0-.957-.352-.957-.786V1.906c0-.434.428-.786.957-.786z"
                      stroke-width="1.6"
                    />
                    <g transform="translate(5.52 7.28)">
                      <path
                        stroke-width="1.4"
                        stroke-linecap="round"
                        d="M6.16 8.36h7.04M6.16 1.76h7.04"
                      />
                      <path
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M0 1.93l1.426 1.59L3.52 0"
                      />
                      <circle
                        stroke-width="1.2"
                        cx="1.76"
                        cy="8.36"
                        r="1.76"
                      />
                    </g>
                  </g>
                </g>
              </svg>

            </span>
            <span class="link-text">
              {{ $t('menu.tasks.header') }}
            </span>
          </button>
          <ul
            id="tasks-menu"
            class="dropdown-menu__secondary-nav is-hidden"
          >
            <li class="dropdown-menu__item go-back">
              <button
                class="dropdown-menu__link"
                aria-controls="tasks-menu"
                @click.prevent.stop="closeSubMenu"
              >
                <span class="link-text">
                  {{ $t('menu.back') }}
                </span>
              </button>
            </li>
            <li class="dropdown-menu__item">
              <RouterLink
                :to="{ name: 'ManageDuplicatedRecordsPage' }"
                class="dropdown-menu__link"
              >
                <span class="link-text">
                  {{ $t('menu.tasks.submenu.duplicates') }}
                </span>
              </RouterLink>
            </li>
            <li class="dropdown-menu__item">
              <RouterLink
                :to="{ name: 'ManageInconsistenciesPage' }"
                class="dropdown-menu__link"
              >
                <span class="link-text">
                  {{ $t('menu.tasks.submenu.inconsistencies') }}
                </span>
              </RouterLink>
            </li>
            <li
              v-if="profile.roles.includes('operator')"
              class="dropdown-menu__item"
            >
              <RouterLink
                :to="{ name: 'ManageComplaintsPage' }"
                class="dropdown-menu__link"
              >
                <span class="link-text">
                  {{ $t('menu.tasks.submenu.complaints') }}
                </span>
              </RouterLink>
            </li>
          </ul>
        </li>

        <!-- UNIT MANAGEMENT SUBMENU -->
        <li
          v-if="managedUnits && managedUnits.length > 0"
          class="dropdown-menu__item has-children"
        >
          <button
            class="dropdown-menu__link"
            aria-controls="unit-management-menu"
            @click.stop.prevent="openSubMenu"
          >
            <span class="link-icon">
              <svg
                width="25"
                height="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  stroke="var(--gray-400)"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(.3 .3)"
                    stroke-width="1.7"
                  >
                    <circle
                      cx="11.667"
                      cy="3"
                      r="2.15"
                    />
                    <circle
                      transform="rotate(60 19.172 7.333)"
                      cx="19.172"
                      cy="7.333"
                      r="2.15"
                    />
                    <circle
                      transform="rotate(120 19.172 16)"
                      cx="19.172"
                      cy="16"
                      r="2.15"
                    />
                    <circle
                      transform="rotate(180 11.667 20.333)"
                      cx="11.667"
                      cy="20.333"
                      r="2.15"
                    />
                    <circle
                      transform="rotate(-120 4.161 16)"
                      cx="4.161"
                      cy="16"
                      r="2.15"
                    />
                    <circle
                      transform="rotate(-60 4.161 7.333)"
                      cx="4.161"
                      cy="7.333"
                      r="2.15"
                    />
                  </g>
                  <path
                    d="M12 5.333v4.5m5.774-1.166l-3.898 2.25m3.898 4.416l-3.898-2.25M12 18.667v-4.5m-5.774 1.166l3.898-2.25M6.226 8.667l3.898 2.25"
                    stroke-width="1.32"
                    stroke-linecap="round"
                  />
                  <circle
                    stroke-width="1.697"
                    cx="12"
                    cy="12"
                    r="2.151"
                  />
                </g>
              </svg>
            </span>
            <span class="link-text">
              {{ $t('menu.unit.management.header') }}
            </span>
          </button>
          <ul
            id="unit-management-menu"
            class="dropdown-menu__secondary-nav is-hidden"
          >
            <li class="dropdown-menu__item go-back">
              <button
                class="dropdown-menu__link"
                aria-controls="tasks-menu"
                @click.prevent.stop="closeSubMenu"
              >
                <span class="link-text">
                  {{ $t('menu.back') }}
                </span>
              </button>
            </li>
            <li
              v-for="unit in managedUnits"
              :key="unit.id"
              class="dropdown-menu__item has-children"
            >
              <button
                class="dropdown-menu__link"
                :aria-controls="`${unit.acronym}-management-menu`"
                @click.prevent.stop="openSubMenu"
              >
                <span class="link-text">
                  {{ unit.acronym }}
                </span>
              </button>
              <ul
                :id="`${unit.acronym}-management-menu`"
                class="dropdown-menu__secondary-nav is-hidden"
              >
                <li class="dropdown-menu__item go-back">
                  <button
                    :aria-controls="`${unit.acronym}-management-menu`"
                    class="dropdown-menu__link"
                    @click.prevent.stop="closeSubMenu"
                  >
                    <span class="link-text">
                      {{ $t('menu.back') }}
                    </span>
                  </button>
                </li>
                <li class="dropdown-menu__item">
                  <RouterLink
                    :to="{ name: 'ManageUnitInfoPage', params: { unitId: unit.id } }"
                    class="dropdown-menu__link"
                  >
                    <span class="link-text">
                      {{ $t('menu.unit.management.submenu.unit') }}
                    </span>
                  </RouterLink>
                </li>
                <li class="dropdown-menu__item">
                  <RouterLink
                    :to="{ name: 'ManageUnitAffiliationsPage', params: { unitId: unit.id } }"
                    class="dropdown-menu__link"
                  >
                    <span class="link-text">
                      {{ $t('menu.unit.management.submenu.affiliations') }}
                    </span>
                  </RouterLink>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <!-- ADMINISTRATION SUBMENU -->
        <li
          v-if="profile.roles.includes('scholar-admin')"
          class="dropdown-menu__item has-children"
        >
          <button
            v-if="$auth.isLogged"
            aria-controls="administration-menu"
            class="dropdown-menu__link"
            @click.prevent.stop="openSubMenu"
          >
            <span class="link-icon">
              <svg
                width="26"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path d="M1 0h24v24H1z" />
                  <g
                    transform="translate(1 2)"
                    stroke="var(--gray-400)"
                  >
                    <path
                      d="M13.78 13.026c1.041-1.37 2.747-2.263 4.675-2.263 3.172 0 5.744 2.419 5.744 5.402h-7.926 0"
                      stroke-width="1.3"
                    />
                    <ellipse
                      stroke-width="1.2"
                      cx="18.455"
                      cy="6.001"
                      rx="3.191"
                      ry="3.001"
                    />
                    <path
                      d="M16.881 19.094c0-4.31-3.74-7.803-8.356-7.803-4.614 0-8.355 3.493-8.355 7.803H16.88z"
                      stroke-width="1.5"
                    />
                    <ellipse
                      stroke-width="1.5"
                      cx="8.525"
                      cy="4.335"
                      rx="4.642"
                      ry="4.335"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <span class="link-text">
              {{ $t('menu.administration.header') }}
            </span>
          </button>
          <ul
            id="administration-menu"
            class="dropdown-menu__secondary-nav is-hidden"
          >
            <li class="dropdown-menu__item go-back">
              <button
                class="dropdown-menu__link"
                aria-controls="administration-menu"
                @click.prevent.stop="closeSubMenu"
              >
                <span class="link-text">
                  {{ $t('menu.back') }}
                </span>
              </button>
            </li>
            <li class="dropdown-menu__item">
              <RouterLink
                :to="{ name: 'AdministrateUsersPage' }"
                class="dropdown-menu__link"
              >
                <span class="link-text">
                  {{ $t('menu.administration.submenu.users') }}
                </span>
              </RouterLink>
            </li>
            <li class="dropdown-menu__item">
              <RouterLink
                :to="{ name: 'AdministrateUnitsPage' }"
                class="dropdown-menu__link"
              >
                <span class="link-text">
                  {{ $t('menu.administration.submenu.units') }}
                </span>
              </RouterLink>
            </li>
          </ul>
        </li>
        <li class="dropdown-menu__item">
          <button
            v-if="$auth.isLogged()"
            class="dropdown-menu__link"
            @click.stop="$auth.logout()"
          >
            <span class="link-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path d="M-6-3h24v24H-6z" />
                  <g
                    stroke="var(--gray-400)"
                    stroke-linecap="round"
                  >
                    <path
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9.076923 1.5h-8V18h7.5750767"
                    />
                    <path
                      stroke-width="1.8"
                      d="M12 6l4 4.0122369L12.0256659 14M6 10h9"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <span class="link-text">
              {{ $t('menu.logout') }}
            </span>
          </button>
        </li>
      </ul>
    </div>
  </Dropdown>
</template>
<script>
import { mapState } from 'vuex'
import Dropdown from '@/components/utils/Dropdown.vue'
import UserAvatar from '@/components/utils/UserAvatar.vue'

export default {
  components: {
    Dropdown,
    UserAvatar
  },
  data () {
    return {
      trap: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      managedUnits: state => state.managedUnits
    })
  },
  methods: {
    setFocusTrap (trap) {
      this.trap = trap
    },
    async openSubMenu (event) {
      const selectedElement = event.target.parentNode
      const subMenuElement = selectedElement.querySelector('ul')
      const parentNav = selectedElement.parentNode
      // adjust height of nav to selected content
      const dropdownPanel = this.$refs.dropdownPanel.parentNode
      dropdownPanel.style.height = `${subMenuElement.offsetHeight}px`
      dropdownPanel.style.maxHeight = '80vh'

      if (subMenuElement.classList.contains('is-hidden')) {
        selectedElement.classList.add('selected')
        subMenuElement.classList.remove('is-hidden')
        parentNav.classList.add('moves-out')
      } else {
        selectedElement.classList.remove('selected')
        parentNav.classList.add('moves-out')
      }
      await this.$nextTick()
      setTimeout(() => this.trap.updateContainerElements(subMenuElement), 100)
    },
    closeSubMenu (event) {
      const selectedElement = event.target.parentNode
      const currentNav = selectedElement.parentNode
      const previousNav = currentNav.parentNode.parentNode

      currentNav.classList.add('is-hidden')
      currentNav.parentNode.classList.remove('selected')
      previousNav.classList.remove('moves-out')

      // adjust height of nav to selected content
      const dropdownPanel = this.$refs.dropdownPanel.parentNode
      dropdownPanel.style.height = `${previousNav.offsetHeight}px`
      dropdownPanel.style.maxHeight = '80vh'
      this.trap.updateContainerElements(previousNav)
    },
    closeMenu () {
      const menu = this.$refs.menu
      if (!menu) return
      const navigations = menu.querySelectorAll('.has-children ul')
      const selectedNavigations = menu.querySelectorAll('.has-children button')
      const elementsMovedOut = menu.querySelectorAll('.moves-out')

      for (const navigation of navigations) {
        navigation.classList.add('is-hidden')
      }
      for (const selectedNavigation of selectedNavigations) {
        selectedNavigation.classList.remove('selected')
      }
      for (const elementMovedOut of elementsMovedOut) {
        elementMovedOut.classList.remove('moves-out')
      }

      setTimeout(() => {
      // reset height of nav to automatic detection
        const dropdownPanel = this.$refs.dropdownPanel.parentNode
        dropdownPanel.setAttribute('style', '')
      }, 100)

      menu.classList.remove('moves-out')
    }
  }
}
// TODO: move styles from TopNavBar to this component?
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

.user-dropdown-menu__panel {
  overflow: hidden auto;
  height: 100%;
  max-height: 80vh;
}

// FIXME: remove !important when menu styles are moved from TopNavBar to menu components
.dropdown-menu__secondary-nav {
  position: absolute !important;
  top: 0 !important;
  left: 100% !important;
  width: 100% !important;
}

.dropdown-menu__item .link-text {
  // disables clicks on the spans, bc we want clicks to fire on the buttons
  pointer-events: none;
}

.dropdown-menu__secondary-nav > .dropdown-menu__item a {
  padding-left: 2.5rem;
}

.dropdown-menu .has-children > button::after,
.dropdown-menu .go-back > button::after {
  position: absolute;
  content: "";
  top: 50%;
  height: 0.625rem;
  width: 0.625rem;
  border: 0.125rem solid $gray-400;
}

.dropdown-menu .has-children > button::after {
  border-left: 0;
  border-bottom: 0;
  right: 2rem;
  transform: rotate(45deg) translateY(-50%);
}

.dropdown-menu .go-back > button::after {
  border-right: 0;
  border-top: 0;
  left: 1.25rem;
  transform: rotate(45deg) translateY(-50%);
}
</style>
