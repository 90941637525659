import { guardWithErrorHandling, fetchAuthorByUserId } from '@/router/navigation-guards'
import intersection from 'underscore/modules/intersection'
import { types as recordTypes } from '@scholar/metadata-utils/main'
import { getScientificProduction } from '@/api/researchers'

import ResearcherPage from '@/pages/ResearcherPage.vue'
import ResearcherRecordsPage from '@/pages/ResearcherRecordsPage.vue'
import ResearcherAdvisedRecordsPage from '@/pages/ResearcherAdvisedRecordsPage.vue'
import ResearcherAffiliationsPage from '@/pages/ResearcherAffiliationsPage.vue'
import ResearcherCoAuthorsPage from '@/pages/ResearcherCoAuthorsPage.vue'
import ResearcherBioPage from '@/pages/ResearcherBioPage.vue'

import DateHelpers from '@/mixins/DateHelpers'

export default [
  // TODO: migrate to matching by username by default
  // {
  //   path: 'authors/:username',
  //   name: 'ResearcherPage',
  //   component: ResearcherPage,
  //   props: route => ({
  //     username: route.params.username
  //   }),
  //   children: [
  //     ...
  //   ]
  // },
  // {
  //   path: '/u/:userId',
  //   beforeEnter: async (to, from, next) => {
  //     const username = await getUsernameForUserId({ userId: to.params.userId })
  //     return next({ name: 'ResearcherPage', params: { username } })
  //   }
  // },
  {
    path: '/u/:username',
    beforeEnter: async (to, from, next) => {
      return next({ name: 'ResearcherPage', params: { userId: to.params.username } })
    }
  },
  {
    path: '/authors/:userId',
    name: 'ResearcherPage',
    component: ResearcherPage,
    redirect: { name: 'ResearcherRecordsPage' },
    meta: {
      layout: 'PageWithNavBarAndFooterLayout'
    },
    props: route => ({
      author: route.params.author,
      scientificProduction: route.params.scientificProduction
    }),
    beforeEnter: guardWithErrorHandling(
      async (to, from, next) => {
        const [author, scientificProduction] = await Promise.all([
          fetchAuthorByUserId({ userId: to.params.userId, countView: true }),
          getScientificProduction({ userId: to.params.userId })
        ])
        to.params.author = author
        to.params.scientificProduction = scientificProduction
        next()
      }
    ),
    children: [
      {
        path: 'records',
        name: 'ResearcherRecordsPage',
        component: ResearcherRecordsPage,
        props: route => ({
          userId: route.params.userId,
          page: Number(route.query.page) || 1,
          q: route.query.q,
          type: intersection(recordTypes,
            typeof route.query.type === 'string' ? [route.query.type] : route.query.type),
          perPage: Number(route.query.perPage) || undefined,
          start: route.query.start ? DateHelpers.methods.dateStringToObjectString(route.query.start) : null,
          end: route.query.end ? DateHelpers.methods.dateStringToObjectString(route.query.end) : null
        }),
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'supervised-records',
        name: 'ResearcherAdvisedRecordsPage',
        component: ResearcherAdvisedRecordsPage,
        props: route => ({
          userId: route.params.userId,
          page: Number(route.query.page) || 1,
          q: route.query.q,
          perPage: Number(route.query.perPage) || undefined,
          type: intersection(recordTypes,
            typeof route.query.type === 'string' ? [route.query.type] : route.query.type)
        }),
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'affiliations',
        name: 'ResearcherAffiliationsPage',
        component: ResearcherAffiliationsPage,
        props: route => ({
          userId: route.params.userId,
          page: Number(route.query.page) || 1,
          perPage: Number(route.query.perPage) || undefined
        }),
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'co-authors',
        name: 'ResearcherCoAuthorsPage',
        component: ResearcherCoAuthorsPage,
        props: route => ({
          userId: route.params.userId,
          page: Number(route.query.page) || 1,
          q: route.query.q,
          perPage: Number(route.query.perPage) || undefined
        }),
        meta: {
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'bio',
        name: 'ResearcherBioPage',
        component: ResearcherBioPage,
        props: route => ({
          userId: route.params.userId
        })
      }
    ]
  }
]
