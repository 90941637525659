<script>
import Vue from 'vue'
import FundingReferenceItem from '@/components/records/views/parts/FundingReferenceItem.vue'
Vue.component('FundingReferenceItem', FundingReferenceItem)

export default {
  functional: true,
  props: {
    fundingReferences: {
      type: Array,
      default: () => []
    }
  },
  render (h, { props }) {
    return props.fundingReferences.map(funding =>
      h('FundingReferenceItem', { class: ['record-field'], props: { funding } })
    )
  }
}
</script>
