import client from '@/api/client'

/**
 *
 * @param {object} root0 Root object
 * @param {number} root0.page The number of the page to retrieve from the server
 * @param {number} root0.perPage The number of items in each page
 * @param {boolean} root0.hidden If true shows hidden users
 * @param {string} root0.q The query to search users
 * @param {string} root0.sort The sorting of the result list
 * @param {string[]} root0.roles The list of roles to search for
 */
export async function list ({ page, perPage, hidden, q, sort, roles }) {
  const response = await client.get('/users', {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      hidden,
      ...(q && { q }),
      ...(sort && { sort }),
      ...(roles && { roles })
    }
  })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {string} root0.id The id of the user to get
 */
export async function get ({ id }) {
  const response = await client.get(`/users/${id}`)
  return response.data
}

/**
 * Updates a user
 * @param {object} root0 Root object
 * @param {string} root0.id The id of the user to update
 * @param {string[]} root0.roles The list of roles to give the user
 * @param {boolean} root0.blocked If true the user will be blocked
 */
export async function update ({ id, roles, blocked }) {
  const response = await client.put(`/users/${id}`, { roles, blocked })
  return response.data
}

/**
 * Update user related settings
 * @param {object} root0 Root object
 * @param {string} root0.id The id of the user
 * @param {boolean} root0.hidden If true the user will be hidden
 * @param {boolean} root0.editsHidden If true all user sources in records will be hidden
 */
export async function updateSettings ({ id, hidden, editsHidden }) {
  const response = await client.put(`/researchers/${id}/admin-settings`, { hidden, editsHidden })
  return response.data
}

/**
 * Unsubscribes a user from the emails
 * @param {string} token The token to allow unsubscription
 */
export async function unsubscribeEmail (token) {
  return await client.put('/emails/unsubscribe', null, { params: { token } })
}

export default {
  list,
  get,
  update,
  updateSettings
}
