<template>
  <div
    v-if="value || showInvalidWarning"
    class="record-field"
  >
    <div :class="{ 'invalid-field': showInvalidWarning }">
      <h2
        class="h5--ssp"
        :class="{ 'invalid-field__label': showInvalidWarning }"
      >
        {{ $t(`fields.record.${fieldName}.label`) }}
      </h2>
      <div
        v-if="showInvalidWarning"
        class="invalid-field__warn"
      >
        <svg
          height="18"
          width="18"
          viewBox="0 0 18 18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            stroke="var(--orange)"
            fill="none"
            fill-rule="evenodd"
          >
            <circle
              stroke-width="1.046"
              cx="9"
              cy="9"
              r="8.477"
            />
            <path
              d="M7.2 6.514c0-1.254.99-1.984 1.98-2.013.99-.03 1.98.66 1.98 1.894 0 1.999-2.148 1.122-2.2 3.625"
              stroke-width="1.046"
              stroke-linecap="round"
            />
            <path
              d="M9 13.185a.54.54 0 1 1 0-1.08.54.54 0 0 1 0 1.08Z"
              stroke-width=".25"
              fill="var(--orange)"
            />
          </g>
        </svg>
        <span>
          {{ $t('record.view.inconsistency.label') }}
        </span>
      </div>
    </div>
    <p v-if="!invalid">
      {{ $t(`fields.record.${fieldName}.values.${value}`) }}
    </p>
    <p v-else>
      {{ $t('record.view.inconsistency.value') }}
    </p>
  </div>
</template>
<script>
export default {
  inject: ['ignoredFields'],
  props: {
    value: {
      type: String,
      default: null
    },
    fieldName: {
      type: String,
      required: true
    }
  },
  computed: {
    invalid () {
      if (this.ignoredFields) {
        return this.ignoredFields.includes(this.fieldName)
      }
      return false
    },
    showInvalidWarning () {
      return this.invalid && this.$auth.isLogged()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/record";
</style>
