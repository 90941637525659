<template>
  <ul
    ref="menu"
    class="mobile-side-menu"
  >
    <template v-if="$auth.isLogged() && profile">
      <li
        hidden
        class="mobile-only mobile-side-menu__user"
      >
        <div class="link-icon">
          <UserAvatar
            :src="profile.avatar"
            :name="profile.name"
            class="user-avatar user-avatar--lg"
          />
        </div>
        <div class="mobile-side-menu__username">
          <div class="link-text">
            {{ profile.name }}
          </div>
          <div
            v-if="profile.username"
            class="link-text link-text--username"
          >
            {{ profile.username }}
          </div>
        </div>
      </li>
      <template v-if="profile.roles.includes('author')">
        <li
          hidden
          class="mobile-only"
        >
          <RouterLink
            :to="{ name: 'ResearcherPage', params: { userId: profile.username } }"
            active-class="active"
            @click.native="closeMenu"
          >
            <span class="link-text">
              {{ $t('menu.view.profile') }}
            </span>
          </RouterLink>
        </li>
      </template>
      <template v-if="profile.roles.includes('author') || profile.roles.includes('operator') || (managedUnits && managedUnits.length > 0)">
        <li
          hidden
          class="mobile-only"
        >
          <RouterLink
            :to="{ name: 'CreateRecordPage' }"
            active-class="active"
            @click.native="closeMenu"
          >
            <span class="link-text">
              {{ $t('menu.create.record') }}
            </span>
          </RouterLink>
        </li>
      </template>
      <!-- ACCOUNT SUBMENU -->
      <li
        hidden
        class="has-children mobile-only"
      >
        <button
          aria-controls="my-account-menu"
          @click.prevent="openSubMenu"
        >
          <span class="link-text">
            {{ $t('menu.account.header') }}
          </span>
        </button>
        <ul
          id="my-account-menu"
          class="secondary-nav is-hidden"
        >
          <li class="go-back mobile-only">
            <button
              aria-controls="my-account-menu"
              @click.prevent="closeSubMenu"
            >
              <span class="link-text">
                {{ $t('menu.back') }}
              </span>
            </button>
          </li>
          <li>
            <RouterLink
              :to="{ name: 'EditProfilePage' }"
              active-class="active"
              @click.native="closeMenu"
            >
              <span class="link-text">
                {{ $t('menu.account.submenu.profile') }}
              </span>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              :to="{ name: 'ManageEmailsPage' }"
              active-class="active"
              @click.native="closeMenu"
            >
              <span class="link-text">
                {{ $t('menu.account.submenu.emails') }}
              </span>
            </RouterLink>
          </li>
          <template v-if="profile.roles.includes('author')">
            <li>
              <RouterLink
                :to="{ name: 'ManageAliasesPage' }"
                active-class="active"
                @click.native="closeMenu"
              >
                <span class="link-text">
                  {{ $t('menu.account.submenu.aliases') }}
                </span>
              </RouterLink>
            </li>
            <li>
              <RouterLink
                :to="{ name: 'ManageExternalRepositoriesPage' }"
                active-class="active"
                @click.native="closeMenu"
              >
                <span class="link-text">
                  {{ $t('menu.account.submenu.external.repositories') }}
                </span>
              </RouterLink>
            </li>
            <li>
              <RouterLink
                :to="{ name: 'ManageRecordsPage' }"
                active-class="active"
                @click.native="closeMenu"
              >
                <span class="link-text">
                  {{ $t('menu.account.submenu.records') }}
                </span>
              </RouterLink>
            </li>
            <li>
              <RouterLink
                :to="{ name: 'ManageAffiliationsPage' }"
                active-class="active"
                @click.native="closeMenu"
              >
                <span class="link-text">
                  {{ $t('menu.account.submenu.affiliations') }}
                </span>
              </RouterLink>
            </li>
          </template>
        </ul>
      </li>
      <!-- TASKS SUBMENU -->
      <li
        v-if="profile.roles.includes('author') || profile.roles.includes('operator')"
        hidden
        class="has-children mobile-only"
      >
        <button
          aria-controls="tasks-menu"
          @click.prevent="openSubMenu"
        >
          <span class="link-text">
            {{ $t('menu.tasks.header') }}
          </span>
        </button>
        <ul
          id="tasks-menu"
          class="secondary-nav is-hidden"
        >
          <li class="go-back mobile-only">
            <button
              aria-controls="tasks-menu"
              @click.prevent="closeSubMenu"
            >
              <span class="link-text">
                {{ $t('menu.back') }}
              </span>
            </button>
          </li>
          <li>
            <RouterLink
              :to="{ name: 'ManageDuplicatedRecordsPage' }"
              active-class="active"
              @click.native="closeMenu"
            >
              <span class="link-text">
                {{ $t('menu.tasks.submenu.duplicates') }}
              </span>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              :to="{ name: 'ManageInconsistenciesPage' }"
              active-class="active"
              @click.native="closeMenu"
            >
              <span class="link-text">
                {{ $t('menu.tasks.submenu.inconsistencies') }}
              </span>
            </RouterLink>
          </li>
          <li v-if="profile.roles.includes('operator')">
            <RouterLink
              :to="{ name: 'ManageComplaintsPage' }"
              active-class="active"
              @click.native="closeMenu"
            >
              <span class="link-text">
                {{ $t('menu.tasks.submenu.complaints') }}
              </span>
            </RouterLink>
          </li>
        </ul>
      </li>
      <!-- UNIT MANAGEMENT SUBMENU -->
      <li
        v-if="managedUnits && managedUnits.length > 0"
        hidden
        class="has-children mobile-only"
      >
        <button
          aria-controls="unit-management-menu"
          @click.prevent="openSubMenu"
        >
          <span class="link-text">
            {{ $t('menu.unit.management.header') }}
          </span>
        </button>
        <ul
          id="unit-management-menu"
          class="secondary-nav is-hidden"
        >
          <li class="go-back mobile-only">
            <button
              aria-controls="unit-management-menu"
              @click.prevent="closeSubMenu"
            >
              <span class="link-text">
                {{ $t('menu.back') }}
              </span>
            </button>
          </li>
          <li
            v-for="unit in managedUnits"
            :key="unit.id"
            class="has-children mobile-only"
          >
            <button
              :aria-controls="`${unit.acronym}-management-menu`"
              @click.prevent="openSubMenu"
            >
              <span class="link-text">
                {{ unit.acronym }}
              </span>
            </button>
            <ul
              :id="`${unit.acronym}-management-menu`"
              class="secondary-nav is-hidden"
            >
              <li class="go-back mobile-only">
                <button
                  :aria-controls="`${unit.acronym}-management-menu`"
                  @click.prevent="closeSubMenu"
                >
                  <span class="link-text">
                    {{ $t('menu.back') }}
                  </span>
                </button>
              </li>
              <li>
                <RouterLink
                  :to="{ name: 'ManageUnitInfoPage', params: { unitId: unit.id } }"
                  active-class="active"
                  @click.native="closeMenu"
                >
                  <span class="link-text">
                    {{ $t('menu.unit.management.submenu.unit') }}
                  </span>
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  :to="{ name: 'ManageUnitAffiliationsPage', params: { unitId: unit.id } }"
                  active-class="active"
                  @click.native="closeMenu"
                >
                  <span class="link-text">
                    {{ $t('menu.unit.management.submenu.affiliations') }}
                  </span>
                </RouterLink>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <!-- ADMINISTRATION SUBMENU -->
      <li
        v-if="profile.roles.includes('scholar-admin')"
        hidden
        class="has-children mobile-only"
      >
        <button
          aria-controls="administration-menu"
          @click.prevent="openSubMenu"
        >
          <span class="link-text">
            {{ $t('menu.administration.header') }}
          </span>
        </button>
        <ul
          id="administration-menu"
          class="secondary-nav is-hidden"
        >
          <li class="go-back mobile-only">
            <button
              aria-controls="administration-menu"
              @click.prevent="closeSubMenu"
            >
              <span class="link-text">
                {{ $t('menu.back') }}
              </span>
            </button>
          </li>
          <li>
            <RouterLink
              :to="{ name: 'AdministrateUsersPage' }"
              active-class="active"
              @click.native="closeMenu"
            >
              <span class="link-text">
                {{ $t('menu.administration.submenu.users') }}
              </span>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              :to="{ name: 'AdministrateUnitsPage' }"
              active-class="active"
              @click.native="closeMenu"
            >
              <span class="link-text">
                {{ $t('menu.administration.submenu.units') }}
              </span>
            </RouterLink>
          </li>
        </ul>
      </li>
      <li
        hidden
        class="mobile-only"
      >
        <button @click.stop="$auth.logout()">
          <span class="link-text">
            {{ $t('menu.logout') }}
          </span>
        </button>
      </li>
    </template>
    <li
      hidden
      class="has-children mobile-only language-menu"
      :class="{ 'menu-entry--snap-to-bottom': $auth.isLogged() }"
    >
      <button
        aria-controls="language-menu"
        @click.prevent="openSubMenu"
      >
        <span class="link-text">
          {{ $t('menu.language.label') }}
        </span>
      </button>
      <ul
        id="language-menu"
        class="secondary-nav is-hidden"
      >
        <li class="go-back mobile-only">
          <button
            aria-controls="language-menu"
            @click.prevent="closeSubMenu"
          >
            <span class="link-text">
              {{ $t('menu.back') }}
            </span>
          </button>
        </li>
        <li>
          <button
            v-if="$i18n.locale === 'en'"
            @click.prevent="closeMenu() + setLocale('pt', $auth)"
          >
            <span class="link-text">
              {{ $t('menu.language.options.pt') }}
            </span>
          </button>
        </li>
        <li>
          <button
            v-if="$i18n.locale === 'pt'"
            @click.prevent="closeMenu() + setLocale('en', $auth)"
          >
            <span class="link-text">
              {{ $t('menu.language.options.en') }}
            </span>
          </button>
        </li>
      </ul>
    </li>
    <li
      v-if="!$auth.isLogged()"
      hidden
      class="login mobile-only menu-entry--snap-to-bottom"
    >
      <button
        class="btn--login"
        @click.prevent="fetchProfile()"
      >
        {{ $t('menu.login') }}
      </button>
    </li>
  </ul>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import UserAvatar from '@/components/utils/UserAvatar.vue'
export default {
  components: {
    UserAvatar
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    trap: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      managedUnits: state => state.managedUnits
    })
  },
  watch: {
    value (isOpen) {
      if (!isOpen) {
        this.closeMenu()
      }
    }
  },
  methods: {
    ...mapActions({
      fetchProfile: 'fetchProfile'
    }),
    async openSubMenu (event) {
      let selectedElement = event.target
      while (selectedElement.tagName !== 'LI') {
        selectedElement = selectedElement.parentNode
      }
      const subMenuElement = selectedElement.querySelector('ul')
      const parentNav = selectedElement.parentNode
      if (subMenuElement.classList.contains('is-hidden')) {
        selectedElement.classList.add('selected')
        subMenuElement.classList.remove('is-hidden')
        parentNav.classList.add('moves-out')
      } else {
        selectedElement.classList.remove('selected')
        parentNav.classList.add('moves-out')
      }
      await this.$nextTick()
      setTimeout(() => this.trap.updateContainerElements(subMenuElement), 100)
    },
    closeSubMenu (event) {
      let selectedElement = event.target
      while (selectedElement.tagName !== 'LI') {
        selectedElement = selectedElement.parentNode
      }
      const currentNav = selectedElement.parentNode
      const previousNav = currentNav.parentNode.parentNode
      currentNav.classList.add('is-hidden')
      currentNav.parentNode.classList.remove('selected')
      previousNav.classList.remove('moves-out')
      this.trap.updateContainerElements(previousNav)
    },
    closeMenu () {
      const mobileMenu = this.$refs.menu
      if (!mobileMenu) return
      const navigations = mobileMenu.querySelectorAll('.has-children ul')
      const selectedNavigations = mobileMenu.querySelectorAll('.has-children a')
      const elementsMovedOut = [mobileMenu, ...mobileMenu.querySelectorAll('.moves-out')]

      for (const navigation of navigations) {
        navigation.classList.add('is-hidden')
      }
      for (const selectedNavigation of selectedNavigations) {
        selectedNavigation.classList.remove('selected')
      }
      for (const elementMovedOut of elementsMovedOut) {
        elementMovedOut.classList.remove('moves-out')
      }
      this.$emit('closed')
    }
  }
}
// TODO: move styles from TopNavBar to this component?
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

.mobile-side-menu {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.menu-entry--snap-to-bottom {
  margin-top: auto;

  &.language-menu {
    border-top: 0.0625rem solid $dark;
  }
}

.mobile-side-menu__user {
  position: relative;
  color: #fff;
  display: flex;
  padding: 1rem;
  width: 100%;
  min-height: calc(2rem + #{$header-height});
  border-bottom: 0.0625rem solid $dark;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .mobile-side-menu__username {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .link-icon {
    margin-right: 1rem;
  }

  .link-text--username {
    color: $gray-400;
  }

  .user-avatar {
    border: none;
  }
}
</style>
