<template>
  <div>
    <button
      :aria-expanded="String(showList)"
      class="trigger"
      :class="{ 'trigger--closed': !showList, 'trigger--open': showList }"
      @click="showList = !showList"
    >
      <slot name="trigger-text" />
    </button>
    <transition name="slide">
      <section
        v-show="showList"
        class="collapsible-list__list-container"
      >
        <slot name="list" />
      </section>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showList: !this.collapsed
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

.trigger {
  color: $blue;
  position: relative;
  display: inline-block;
  padding: 0 1rem 0 0;
  outline: transparent;

  &::after {
    content: "";
    position: absolute;
    top: 0.5rem;
    right: 0;
    margin-left: 6px;
    width: 0;
    height: 0;
  }
}

.trigger--closed {
  @include md-transition;

  transition-property: color;
  &::after {
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: currentcolor transparent transparent;
  }
}

.trigger--open {
  @include md-transition;

  transition-property: color;
  &::after {
    border-style: solid;
    border-width: 0 4px 4px;
    border-color: transparent transparent currentcolor;
  }
}

.collapsible-list__list-container {
  // FIXME: needed to add this because otherwise it would jump after transition ended because of margins/padding
  overflow: hidden;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s;
  overflow: hidden;
  margin-bottom: 0;
}

.slide-enter,
.slide-leave-to {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.slide-leave,
.slide-enter-to {
  max-height: 350px;
}
</style>
