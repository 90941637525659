<template>
  <Component
    :is="`${record.type}-view`"
    v-if="record.creators"
    :record="record"
    layout="SingleRecordLayout"
  >
    <template #sideCard>
      <slot name="sideCard" />
    </template>
  </Component>
</template>

<script>
import MastersThesis from '@/components/records/views/MastersThesis.vue'
import Article from '@/components/records/views/Article.vue'
import Book from '@/components/records/views/Book.vue'
import BookPart from '@/components/records/views/BookPart.vue'
import Report from '@/components/records/views/Report.vue'
import Conference from '@/components/records/views/Conference.vue'
import DoctoralThesis from '@/components/records/views/DoctoralThesis.vue'
import Other from '@/components/records/views/Other.vue'
import Patent from '@/components/records/views/Patent.vue'

import DateHelpers from '@/mixins/DateHelpers'
import { baseURL } from '@/api/client'

export default {
  components: {
    'master-thesis-view': MastersThesis,
    'journal-article-view': Article,
    'book-view': Book,
    'book-part-view': BookPart,
    'report-view': Report,
    'other-view': Other,
    'conference-object-view': Conference,
    'doctoral-thesis-view': DoctoralThesis,
    'patent-view': Patent
  },
  mixins: [DateHelpers],
  provide () {
    return {
      ignoredFields: this.record.metadata?.ignored ?? []
    }
  },
  props: {
    record: {
      required: true,
      type: Object
    }
  },
  metaInfo: function () {
    const creatorsMeta = this.record.creators.map(c => {
      return { name: 'citation_author', content: c.name }
    })
    const meta = [
      { name: 'description', content: this.$t('description', { title: this.record.title }) }, // Basic information required from Search engines
      { name: 'citation_title', content: this.record.title }, // Google Scholar minimum requirements
      ...creatorsMeta, // Google Scholar minimum requirements
      { name: 'citation_publication_date', content: this.generateDateString(this.record.dateIssued) } // Google Scholar minimum requirements
    ]
    if (this.record.citationTitle) meta.push({ name: 'citation_journal_title', content: this.record.citationTitle })
    if (this.record.citationVolume) meta.push({ name: 'citation_volume', content: this.record.citationVolume })
    if (this.record.citationIssue) meta.push({ name: 'citation_issue', content: this.record.citationIssue })
    if (this.record.citationStartPage) meta.push({ name: 'citation_firstpage', content: this.record.citationStartPage })
    if (this.record.citationEndPage) meta.push({ name: 'citation_lastpage', content: this.record.citationEndPage })
    if (this.record.files) {
      this.record.files.forEach(f => {
        meta.push({ name: 'citation_pdf_url', content: `${window.location.protocol}//${window.location.host}${baseURL}records/${this.record.metadata.id}/file/${f.id}` })
      })
    }
    return {
      title: this.record.title,
      meta,
      script: [{
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'Article',
          headline: this.record.title,
          description: this.record.description,
          inLanguage: this.record.language,
          author: this.record.creators.filter(c => c.userId).map(creator => ({
            '@type': 'Person',
            name: creator.name,
            image: creator.user?.avatar,
            url: `https://scholar.tecnico.ulisboa.pt/authors/${creator.userId}`
          }))
        }
      }]
    }
  },
  data () {
    return {
      customSubtitleFields: []
    }
  },
  methods: {
    assignCustomSubtitleFields (value) {
      this.customSubtitleFields = value
    }
  },
  i18n: {
    messages: {
      pt: {
        description: 'Página com os meta dados da publicação {title}'
      },
      en: {
        description: 'Page with all metadata related to the publication "{title}"'
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";

.publication {
  padding: 1.5rem 0;
}

.publication .card + .card {
  margin-top: 1rem;
}

.publication .card-row {
  padding: 1.5rem;
}

.publication-main {
  margin-top: 1rem;
}

.publication-main__left {
  margin-bottom: 1rem;
}

.publication__title {
  margin: 1rem 0 0.5rem;
}

.download-item {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
}

.download-item + .download-item {
  margin-top: 1rem;
}

.download-icon {
  margin-right: 1rem;
}

.download-item__text {
  word-break: break-all;
}

.download-item--locked {
  display: block;
  position: relative;
}

@media (width >= 75rem) {
  .publication {
    padding: 3.5rem 0;
  }

  .publication-main {
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    flex-grow: 1;
    margin-top: 1rem;
  }

  .publication-main__left {
    flex-basis: 47.75rem;
    width: 47.75rem;
    margin: 0 1rem 0 0;
  }
  .publication-main__right {
    flex-basis: 22.5rem;
    min-width: 22.5rem;
    flex-grow: 1;
  }
}
</style>
