<template>
  <checkbox-list
    v-model="selectedTypes"
    :columns="columns"
    :options="recordTypes"
    field-name="record.view.types"
  />
</template>
<script>
import { types } from '@scholar/metadata-utils/main'
import CheckboxList from '@/components/utils/fields/CheckboxList.vue'

export default {
  components: {
    CheckboxList
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    columns: {
      type: Number,
      default: 1,
      validator: prop => Number.isInteger(prop) && prop > 0
    }
  },
  data () {
    return {
      recordTypes: types
    }
  },
  computed: {
    selectedTypes: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
