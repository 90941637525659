import Vue from 'vue'
// import translationsPt from '@/i18n/translations-pt.json'
import VueI18n from 'vue-i18n'
import dayjs from 'dayjs'
import axios from 'axios'
import * as ProfileAPI from '@/api/profile'

Vue.use(VueI18n)

const loadedLanguages = []
let customTranslations = axios.get('/translations.json')

/**
 * Sets the requested language, if the translations aren't yet loaded it loads them
 * @param {string} lang Language to set (eg. 'pt', 'en')
 * @param {object} auth Authentication data provided by the auth mixin (this.$auth)
 */
export async function setLocale (lang, auth) {
  if (customTranslations instanceof Promise) {
    customTranslations = (await customTranslations).data
  }

  for (const locale in customTranslations) {
    i18n.mergeLocaleMessage(locale, customTranslations[locale])
  }

  // If the same language
  if (i18n.locale === lang) {
    return setI18nLanguage(lang)
  }

  // Update lang in profile
  if (auth?.isLogged()) {
    ProfileAPI.update({ locale: lang })
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return setI18nLanguage(lang)
  }

  // If the language hasn't been loaded yet
  const messages = await import(`./translations-${lang}.json`)
  i18n.setLocaleMessage(lang, messages.default)
  i18n.mergeLocaleMessage(lang, customTranslations[lang])
  loadedLanguages.push(lang)
  return setI18nLanguage(lang)
}

/**
 * Does all required work to set the language, function does not handle translations async load
 * @param {string} lang Language to set (eg. 'pt', 'en')
 */
function setI18nLanguage (lang) {
  i18n.locale = lang
  dayjs.locale(lang)
  window.localStorage.setItem('language', lang)
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

/** Vue-i18n plugin instance */
const i18n = new VueI18n({
  silentFallbackWarn: true
})

// Set starting language
let language = window.localStorage.getItem('language') || window.navigator.language.split('-')[0]
language = language === 'pt' ? 'pt' : 'en'
export const langInit = setLocale(language)

export default i18n
