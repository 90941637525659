import { MetadataUtils, signatureFields } from '@scholar/metadata-utils/main'
import intersection from 'underscore/modules/intersection'

const metadataUtils = new MetadataUtils()

/**
 * Cleans a record to send to the server
 * @param {object} originalRecord The record that will have the fields cleaned
 */
export function cleanRecordFields (originalRecord) {
  // Deep record copy
  const record = JSON.parse(JSON.stringify(originalRecord))

  // Clean signatureFields
  for (const field of signatureFields) {
    if (record[field]) {
      if (record[field].length === 0) {
        delete record[field]
        continue
      }
      record[field] = record[field].map(user => {
        return { name: user.name, userId: user.userId }
      })
    }
  }

  // Delete key
  if (record.fundingReferences) {
    for (const reference of record.fundingReferences) {
      delete reference.key
    }
  }

  // Only exists in case for preexisting records (editions)
  if (record.metadata) {
    delete record.metadata
  }

  // Delete empty dates TODO: Remove this after making empty dates return null
  if (!record.dateSubmitted || !record.dateSubmitted.year) {
    delete record.dateSubmitted
  }
  if (!record.dateAvailable || !record.dateAvailable.year) {
    delete record.dateAvailable
  }

  for (const key in record) {
    if (Object.hasOwnProperty.call(record, key)) {
      if (record[key] === '') {
        delete record[key]
      }
    }
  }

  // Delete empty fields
  for (const key in record) {
    if (record[key] === null) {
      delete record[key]
    }
  }

  // Delete file extra fields
  if (record.files) {
    record.files = record.files.map(file => {
      delete file.state
      return file
    })
  }

  if (!record.files || !record.files.length) {
    record.rights = 'metadata-only-access'
  }

  if (record.description) {
    record.description = record.description.trim()
  }

  // Verify and delete extra fields
  const metadataUtils = new MetadataUtils()
  const result = metadataUtils.getParserByType(metadataUtils.getType(record)).validate(record)
  if (result.status === false) {
    const fieldNames = Object.keys(result.fields)
    Object.values(result.fields).filter(f => f.type === 'not-in-schema').forEach((f, i) => {
      delete record[fieldNames[i]]
    })
  }

  return record
}

/**
 *
 * @param {string} type Type of record to give the list of main fields
 */
export function requiredMainFormFields (type) {
  // Get Required fields from metadata-utils and filter fields from other steps
  const parser = metadataUtils.getParserByType(type)
  return parser.requiredFields.filter(field => !['title', 'type', ...userFieldNames].includes(field))
}

export const userFieldNames = signatureFields

/**
 *
 * @param {object} root0 Root object
 * @param {object} root0.record The object of record
 * @param {string} root0.userId The userId to see if a user is author
 */
export function isRecordAuthor ({ record, userId }) {
  const metadataUtils = new MetadataUtils()
  const authors = metadataUtils.getParserByType(metadataUtils.getType(record)).getUsers(record)
  return authors.includes(userId)
}

/**
 *
 * @param {object} root0 Root object
 * @param {object} root0.record Object of record
 * @param {object} root0.profile Object of the profile
 */
export function isUnitManagerOfAuthorMemberships ({ record, profile }) {
  return intersection(record.metadata?.authorMemberships ?? [], profile?.units ?? []).length > 0
}
