<template>
  <main class="search-page layout-centered">
    <header>
      <h1 class="search-page__title">
        {{ $t('header') }}
      </h1>
    </header>
    <form
      role="search"
      class="f-field-search"
      @focusin="isSearchFocused = true"
      @focusout="isSearchFocused = false"
      @keydown.up.exact.prevent.stop="selectPreviousOption"
      @keydown.down.exact.prevent.stop="selectNextOption"
      @keydown.esc.exact.prevent.stop="searchQuery = ''"
    >
      <button
        type="submit"
        class="f-field-search__submit"
        @click.prevent="performSearch(searchQuery, selectedDomain)"
      >
        {{ $t('search') }}
      </button>
      <RouterLink
        :to="{ name: 'SearchResultsPage', query: { q: searchQuery, domain: 'records', advancedSearchOpen: true } }"
        class="f-field-advanced-search__icon"
        :aria-label="$t('advancedSearch')"
      />
      <input
        ref="searchInput"
        v-model="searchQuery"
        :placeholder="$t('search...')"
        type="text"
        role="combobox"
        :aria-expanded="String(!isListboxHidden)"
        class="f-field-search__input f-field-search__input--card"
        aria-controls="search-listbox"
        :aria-activedescendant="`search-listbox-option-${selectedDomain}`"
        @keydown.enter="performSearch(searchQuery, selectedDomain)"
      >
      <Transition
        name="f-field-search-fade-down"
        mode="in-out"
      >
        <ul
          v-show="!isListboxHidden"
          id="search-listbox"
          class="f-field-search__dropdown card"
          role="listbox"
        >
          <!-- makes whole row clickable. keyboard interaction bubbles up to the container-->
          <li
            v-for="domain in searchDomains"
            :id="`search-listbox-option-${domain}`"
            :key="domain"
            class="card-row"
            role="option"
            :aria-selected="String(domain === selectedDomain)"
            @mouseover="selectedDomain = domain"
            @click.capture="selectedDomain = domain"
            @click="performSearch(searchQuery, domain)"
          >
            <i18n
              :path="`suggestions.in.${domain}`"
              tag="p"
              :locale="$i18n.locale"
              class="f-field-search__dropdown-link card-row__link"
            >
              <template #query>
                <span class="f-field-search__dropdown-query">
                  {{ searchQuery }}
                </span>
              </template>
            </i18n>
          </li>
        </ul>
      </Transition>
    </form>
    <p class="search-page__other-option">
      <RouterLink
        :to="{ name: 'ExploreUnitsPage' }"
        class="btn--arrow-right btn--fake-link"
      >
        {{ $t('exploreUnits') }}
      </RouterLink>
    </p>
  </main>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      isSearchFocused: false,
      searchQuery: '',
      selectedDomain: 'records'
    }
  },
  computed: {
    ...mapState(['configuration']),
    searchDomains () {
      return ['records', 'authors', 'units']
    },
    isEmptyQuery () {
      return !this.searchQuery || this.searchQuery.length === 0
    },
    isListboxHidden () {
      return this.isEmptyQuery || !this.isSearchFocused
    }
  },
  methods: {
    focusOn (ref) {
      ref.focus()
    },
    selectPreviousOption () {
      const index = Math.max(0, this.searchDomains.indexOf(this.selectedDomain) - 1)
      this.selectedDomain = this.searchDomains[index]
    },
    selectNextOption () {
      const index = Math.min(this.searchDomains.length - 1, this.searchDomains.indexOf(this.selectedDomain) + 1)
      this.selectedDomain = this.searchDomains[index]
    },
    performSearch (q, domain) {
      this.$router.push({ name: 'SearchResultsPage', query: { q: q.trim(), domain } })
    }
  },
  metaInfo: function () {
    return {
      title: this.$t('title'),
      meta: [{ name: 'description', content: this.$t('description', { institutionName: this.configuration.institutionName }) }]
    }
  },
  i18n: {
    messages: {
      pt: {
        title: 'Pesquisar',
        description: 'Pesquise no repositório da instituição {institutionName} por publicações, autores ou unidades',
        advancedSearch: 'Pesquisa avançada',
        header: 'Repositório de Documentos Científicos',
        exploreUnits: 'Explorar Instituições/Unidades de Investigação',
        suggestions: {
          in: {
            records: 'Pesquisar “{query}” em todos os campos das Publicações',
            units: 'Pesquisar “{query}” em Instituições/Unidades de Investigação',
            authors: 'Pesquisar “{query}” nos nomes e interesses cientificos dos Autores'
          }
        }
      },
      en: {
        title: 'Search',
        description: 'Search in the repository of the institution {institutionName} for publications, authors or units',
        advancedSearch: 'Advanced search',
        header: 'Scientific Repository',
        exploreUnits: 'Explore Research Institutions/Units',
        suggestions: {
          in: {
            records: 'Search “{query}” in all publication fields',
            units: 'Search “{query}” in Research Institutions/Units names',
            authors: 'Search “{query}” in authors names and scientific interests'
          }
        }

      }
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables";

.search-page.layout-centered {
  text-align: center;
  width: 100%;
  max-width: 46rem;
  padding: 5rem 1rem;
  margin: auto;
  @media screen and (width >= 47.5rem) and (height >= 40rem) {
    padding: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.search-page__title {
  font-size: 1.6875rem;
  @media screen and (width >= 47.5rem) {
    font-size: 2rem;
  }
}
.search-page__other-option {
  margin-top: 2rem;
}
/* stylelint-disable-next-line selector-class-pattern */
.f-field-search__dropdown {
  cursor: pointer;

  li[aria-selected="true"] {
    background-color: $light-blue;
  }
}
</style>
