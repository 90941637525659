<template>
  <div class="date-interval">
    {{ generateDateString(start) }} &mdash;
    <template v-if="end">
      {{ generateDateString(end) }}
    </template>
    <template v-else>
      {{ $t('date.interval.ongoing') }}
    </template>
  </div>
</template>
<script>
import DateHelpers from '@/mixins/DateHelpers'

export default {
  mixins: [
    DateHelpers
  ],
  props: {
    start: {
      type: Object,
      required: true
    },
    end: {
      validator: prop => prop instanceof Object || prop === null,
      required: true
    }
  }
}
</script>
