/**
 * @param {string} str String to be normalized
 */
export function normalize (str) {
  // https://en.wikipedia.org/wiki/Combining_Diacritical_Marks
  const diacriticsRegex = /[\u0300-\u036f]/g
  // Delete diacritics and change no breaking space for a normal space
  return str.toLowerCase().normalize('NFD').replace(diacriticsRegex, '').replace(/[\u00a0]/g, ' ')
}

/**
 * @callback getSearchName
 * @param {string} object string
 */

/**
 *
 * @param {string} search The search query
 * @param {object[]} objects A list of objects
 * @param {getSearchName} getSearchName function
 */
export function searchFilter (search, objects, getSearchName) {
  const matchesSearch = (target) => {
    const folded = normalize(target)
    return search === '' || folded.includes(normalize(search))
  }

  return objects
    .filter(obj => matchesSearch(getSearchName(obj)))
    .map(key => {
      const value = getSearchName(key)
      const folded = normalize(value)
      const match = { start: 0, end: 0 }
      if (search !== '') {
        const index = folded.indexOf(normalize(search))
        match.start = index
        match.end = index + search.length
      }
      return { key, value, match }
    })
}

/**
 *
 * @param {object} obj An object
 * @param {string} matchStart ?
 * @param {string} matchEnd ?
 * @param {string} diffStart ?
 * @param {string} diffEnd ?
 * @param {boolean} prettyOnNoMatch ?
 */
export function renderMatchResults (
  obj,
  matchStart = '',
  matchEnd = '',
  diffStart = '',
  diffEnd = '',
  prettyOnNoMatch = false) {
  if (!prettyOnNoMatch && obj.match.start === obj.match.end) {
    return obj.value
  }

  const start = obj.value.slice(0, obj.match.start)
  const match = obj.value.slice(obj.match.start, obj.match.end)
  const end = obj.value.slice(obj.match.end)

  let res = `${diffStart}${start}${diffEnd}`
  res += `${matchStart}${match}${matchEnd}`
  return res + `${diffStart}${end}${diffEnd}`
}
