import client from '@/api/client'

/**
 * Get external profiles of the logged user
 */
export async function getExternalProfiles () {
  const response = await client.get('/profile/external-profiles')
  return { externalProfiles: response.data }
}

/**
 *
 * @param {object} root0 Root object
 * @param {string} root0.provider The name of the oauth provider
 * @param {string} root0.code The code
 * @param {string} root0.nonce The nonce
 */
export async function sendOAuthCodeToServer ({ provider, code, nonce }) {
  return client.get(`/profile/external-profiles/${provider}/oauth/callback`, {
    params: {
      code,
      nonce
    }
  })
}

/**
 *
 * @param {object} root0 Root object
 * @param {string} root0.provider The name of the provider
 * @param {string} root0.token The authentication token
 */
export async function sendGenericCodeToServer ({ provider, token }) {
  return client.get(`/profile/external-profiles/${provider}/generic/callback`, {
    params: {
      token
    }
  })
}

/**
 *
 * @param {object} root0 Root object
 * @param {string} root0.provider The name of the provider
 * @param {string} root0.key The key
 */
export async function checkExternalProfileKeyAndSave ({ provider, key }) {
  const response = await client.put(`/profile/external-profiles/${provider}/key`, { key })
  return response.data
}

/**
 * Imports records to scholar from the given external provider
 * @param {object} root0 Root object
 * @param {string} root0.provider The provider name
 */
export async function syncExternalProfile ({ provider }) {
  return client.put(`/profile/external-profiles/${provider}/sync`)
}

/**
 * Exports records from scholar to the given provider
 * @param {object} root0 Root object
 * @param {string} root0.provider The name of the external provider
 */
export async function exportProfile ({ provider }) {
  return client.post(`/profile/external-profiles/${provider}/export`)
}

/**
 * Fetch a user profile
 */
export async function get () {
  const response = await client.get('/profile')
  return response.data
}

/**
 * Updates the logged user profile data
 * @param {object} payload The new profile data to be saved in the server
 */
export async function update (payload) {
  const response = await client.put('/profile', payload)
  return response.data
}

/**
 * Deletes the link between the logged user and this provider in the server
 * @param {object} root0 Root object
 * @param {string} root0.provider The provider name
 */
export async function removeExternalProfile ({ provider }) {
  await client.delete(`/profile/external-profiles/${provider}`)
}

/**
 * Lists the aliases for the logged user
 */
export async function getAliases () {
  const response = await client.get('/profile/aliases')
  return response.data
}

/**
 * Paginated list of affiliations
 * @param {object} root0 Root object
 * @param {number} root0.page The page to consult
 * @param {number} root0.perPage The number of results that we want in the resulting page
 */
export async function getAffiliations ({ page, perPage }) {
  const response = await client.get('/profile/affiliations', {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage
    }
  })
  return response.data
}

/**
 * Get logged user settings
 */
export async function getSettings () {
  const response = await client.get('/profile/settings')
  return response.data
}

/**
 * List logged user emails
 */
export async function getEmails () {
  const response = await client.get('/profile/emails')
  return response.data
}

/**
 * Deletes an email from the server
 * @param {object} root0 Root object
 * @param {string} root0.id id of the email to delete
 */
export async function removeEmail ({ id }) {
  const response = await client.delete(`/profile/emails/${id}`)
  return response.data
}

/**
 * Adds a new wmail for the logged user
 * @param {object} root0 Root object
 * @param {string} root0.email The email to add
 */
export async function addEmail ({ email }) {
  const response = await client.post('/profile/emails', {
    email
  })
  return response.data
}

/**
 * Updates an email state
 * @param {object} root0 Root object
 * @param {string} root0.id The id of the email to edit
 * @param {boolean} [root0.primary] If the email is to be used for notifications
 * @param {boolean} [root0.open] If the email should be available to the public
 * @param {string} [root0.token] Token to confirm an email
 */
export async function updateEmail ({ id, primary, open, token }) {
  const response = await client.put(`/profile/emails/${id}`, {
    ...(primary !== undefined && { primary }),
    ...(open !== undefined && { open }),
    ...(token && { token })
  })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {string} root0.emailId The email to add a verification request to
 */
export async function addEmailVerificationRequest ({ emailId }) {
  const response = await client.post(`/profile/emails/${emailId}/verification-requests`)
  return response.data
}

/**
 * Fetch a specific affiliation of the logged user
 * @param {object} root0 Root object
 * @param {string} root0.affiliationId The affiliation id
 */
export async function getAffiliation ({ affiliationId }) {
  const response = await client.get(`/profile/affiliations/${affiliationId}`)
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {string} root0.unitId Id of the unit to create relation
 * @param {object} root0.start Affiliation start, with year and month
 * @param {object} root0.end Affiliation start, with year and month. null represents an active affiliation
 */
export async function addAffiliation ({ unitId, start, end }) {
  const response = await client.post('/profile/affiliations', {
    unitId,
    start,
    end
  })
  return response.data
}

/**
 * Updates an affiliation
 * @param {object} root0 Root object
 * @param {string} root0.affiliationId The id of the affiliation to update
 * @param {object} root0.start Affiliation start, with year and month
 * @param {object} root0.end Affiliation start, with year and month. null represents an active affiliation
 */
export async function updateAffiliation ({ affiliationId, start, end }) {
  const response = await client.put(`/profile/affiliations/${affiliationId}`, {
    start,
    end
  })
  return response.data
}

/**
 * Removes an affiliation for the logged user
 * @param {object} root0 Root object
 * @param {string} root0.affiliationId The affiliation id
 */
export async function removeAffiliation ({ affiliationId }) {
  const response = await client.delete(`/profile/affiliations/${affiliationId}`)
  return response.data
}

/**
 * Sets the username for a new user
 * @param {object} root0 Root object
 * @param {string} root0.username The username to set
 * @param {boolean} root0.dryRun If true only tests username availability
 */
export async function setupUsername ({ username, dryRun = false }) {
  const response = await client.post(`/profile/setup/username${dryRun ? '?dryRun=true' : ''}`, {
    username
  })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {boolean} root0.isAuthor If the user will have author role
 */
export async function setupRole ({ isAuthor }) {
  const response = await client.post('/profile/setup/role', {
    isAuthor
  })
  return response.data
}

/**
 *
 */
export async function finishSetup () {
  const response = await client.post('/profile/setup')
  return response.data
}

/**
 * Take a user task by a user
 * @param {object} object Root object
 * @param {string} object.userTaskId The id of the user/researcher
 * @returns Response data
 */
export async function takeUserTask ({ userTaskId }) {
  const response = await client.put(`/profile/user-tasks/${userTaskId}`, {
    take: true
  })
  return response.data
}

/**
 * Untake a user task by a user
 * @param {object} object Root object
 * @param {string} object.userTaskId The id of the user/researcher
 * @returns Response data
 */
export async function untakeUserTask ({ userTaskId }) {
  const response = await client.put(`/profile/user-tasks/${userTaskId}`, {
    take: false
  })
  return response.data
}

/**
 * Overtake a user task by a user
 * @param {object} object Root object
 * @param {string} object.userTaskId The id of the user/researcher
 * @returns Response data
 */
export async function overtakeUserTask ({ userTaskId }) {
  const response = await client.put(`/profile/user-tasks/${userTaskId}`, {
    overtake: true
  })
  return response.data
}

/**
 * Resolve a user task by a user
 * @param {object} object Root object
 * @param {string} object.userTaskId The id of the user/researcher
 * @returns Response data
 */
export async function resolveUserTask ({ userTaskId }) {
  const response = await client.put(`/profile/user-tasks/${userTaskId}`, {
    resolve: true
  })
  return response.data
}

/**
 *
 * @param {object} root0 Root object
 * @param {number} root0.page The page to fetch
 * @param {number} root0.perPage The number of results perPage
 * @param {string} root0.untilTimestamp A timestamp in ISO format
 */
export async function getNotifications ({ page, perPage, untilTimestamp }) {
  const response = await client.get('/profile/notifications', {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      ...untilTimestamp && { date: untilTimestamp }
    }
  })
  return response.data
}

/**
 * Read a specific notification
 * @param {object} root0 Root object
 * @param {string} root0.notificationId The id of the notification to read
 */
export async function readNotification ({ notificationId }) {
  const response = await client.post(`profile/notifications/${notificationId}/read`)
  return response.data
}

/**
 * Marks all notification as read
 * @param {object} root0 Root object
 * @param {string} root0.untilTimestamp A timestamp in ISO format
 */
export async function readManyNotifications ({ untilTimestamp }) {
  const response = await client.post('profile/notifications/read',
    untilTimestamp !== undefined && { date: untilTimestamp }
  )
  return response.data
}
