import axios from 'axios'
import { parse, stringify } from 'qs'

export const baseURL = '/api/'

const client = axios.create({
  baseURL,
  paramsSerializer: {
    serialize: (params) => stringify(params, {
      encodeValuesOnly: true,
      arrayFormat: 'repeat'
    }),
    encode: parse
  }
})

client.interceptors.request.use(function (config) {
  if (!['get', 'head'].includes(config.method.toLowerCase())) {
    const crumbCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('crumb=')).split('=')[1]
    config.headers['X-CSRF-Token'] = crumbCookie
  }
  return config
})

/**
 * Sends an error report to the backend, so it can be forwarded to an RT system afterwards
 * @param {string} email The email that will be registered as sender
 * @param {string} subject The subject for the email
 * @param {string} description The description of the error that ocurred and how it ocurred
 * @param {string} exceptionInfo Technical details about the exception
 */
export async function sendErrorReport (email, subject, description, exceptionInfo) {
  const body = {
    message: exceptionInfo
  }
  if (email) body.email = email
  if (subject) body.subject = subject
  if (description) body.description = description

  client.post('error-report', body)
}

export default client
