import * as AuthorsAPI from '@/api/researchers'
import * as ProfileAPI from '@/api/profile'
import * as UnitsAPI from '@/api/units'
import * as RecordsAPI from '@/api/records'
import * as UsernamesAPI from '@/api/usernames'

import { fetchAllPages } from '@/utils/pagination-helpers'
import { getInfoFromCookie } from 'vue-porg'
import store from '@/store'

export const getUserRolesFromCookie = () => {
  const info = getInfoFromCookie()
  return info?.roles ?? []
}

export const fetchProfileExternalRepositories = async () => {
  const { externalProfiles } = await ProfileAPI.getExternalProfiles()
  return externalProfiles
}

export const fetchAuthorByUserId = async ({ userId, countView }) => {
  // FIXME: should be fetchAuthorByUsername when we start identifying authors by username
  return AuthorsAPI.get({ userId, countView })
}

export const getUserIdFromUsername = async ({ username }) => {
  // FIXME: should be getUsernameForAuthorUserId when we start identifying authors by username
  const { userId } = await UsernamesAPI.getUserIdFromUsername({ username })
  return userId
}

export const fetchRootUnit = () => {
  return UnitsAPI.getRoot()
}

export const fetchAllSubUnits = ({ parentId }) => {
  return fetchAllPages(page => {
    return UnitsAPI.getSubUnits({ unitID: parentId, page, perPage: 20 })
  })
}

export const fetchUnitTypes = async () => {
  return UnitsAPI.listTypes()
}

export const fetchUnit = async ({ id, countView }) => {
  return UnitsAPI.get({ unitID: id, countView })
}

export const fetchRecord = async ({ id, allowDraft, sources, countView, mergeHistory }) => {
  return RecordsAPI.get({ id, allowDraft, sources, countView, mergeHistory })
}

export const fetchAllUnitManagers = async ({ id }) => {
  return fetchAllPages(page => {
    return UnitsAPI.getManagers({ unitID: id, page, perPage: 20 })
  })
}

export const isUnitManager = async ({ unitId }) => {
  const { units } = await ProfileAPI.get()
  return units.includes(unitId)
}

/**
 * @callback GetErrorRoute
 * @param {Error} error
 * @returns {Parameters<Parameters<import('vue-router').NavigationGuard>[2]>[0]}
 */
/**
 * Wraps a navigation guard. The returned guard will catch axios errors during the
 * execution of the wrapped `guard`. If the error has a 404 status, it redirects
 * to `PageNotFound`. If another error happens, calls `getErrorRoute` if it is
 * defined, otherwise aborts navigation altogether.
 *
 * **NOTE:** If any of the functions you pass as parameters need to access the
 * `this` keyword, make sure that they are **not** arrow functions, as this wrapper
 * will not be able to bind its `this` context to your functions.
 * @param {import('vue-router').NavigationGuard} guard A vue-router navigation guard
 * @param {GetErrorRoute} [getErrorRoute] A function that receives an Error and
 * returns the next route. If not defined, `next(false)` is called when there's
 * an error
 * @returns {import('vue-router').NavigationGuard} The wrapper navigation guard
 */
export const guardWithErrorHandling = (guard, getErrorRoute) => {
  // cannot be arrow function because of 'this' context
  return async function (to, from, next) {
    try {
      await guard.bind(this)(to, from, next)
    } catch (err) {
      if (err.response?.status === 404) {
        return next({ name: 'PageNotFound', params: [to.path], replace: false })
      } else if (getErrorRoute) {
        next(getErrorRoute.bind(this)(err))
      } else {
        next({ name: 'ErrorPage', params: { error: err } })
      }
    } finally {
      store.dispatch('completeProgressBar')
    }
  }
}
