import { guardWithErrorHandling, fetchRecord } from '@/router/navigation-guards'
import { getAffiliation, getSettings, getAliases, getEmails, getExternalProfiles } from '@/api/profile'
import store from '@/store'
import intersection from 'underscore/modules/intersection'
import { types as recordTypes } from '@scholar/metadata-utils/main'
import { isRecordAuthor, isUnitManagerOfAuthorMemberships } from '@/utils/record-helpers'
import Vue from 'vue'

const EditRecordPage = () => import('@/pages/EditRecordPage.vue')
const EditProfilePage = () => import('@/pages/EditProfilePage.vue')
const ManageEmailsPage = () => import('@/pages/ManageEmailsPage.vue')
const ManageAliasesPage = () => import('@/pages/ManageAliasesPage.vue')
const ManageRecordsPage = () => import('@/pages/ManageRecordsPage.vue')
const ManageExternalRepositoriesPage = () => import('@/pages/ManageExternalRepositoriesPage.vue')
const ManageAffiliationsPage = () => import('@/pages/ManageAffiliationsPage.vue')
const EditAffiliationPage = () => import('@/pages/EditAffiliationPage.vue')

export default [
  {
    path: 'account',
    name: 'AccountPage',
    redirect: { name: 'EditProfilePage' }
  },
  {
    path: 'account/profile',
    name: 'EditProfilePage',
    component: EditProfilePage,
    meta: {
      'porg-auth': { roles: ['user'] }
    },
    props: route => ({
      currentProfileData: route.params.profileSettings
    }),
    async beforeEnter (to, from, next) {
      const settings = await getSettings()
      to.params.profileSettings = settings
      next()
    }
  },
  {
    path: 'account/emails',
    name: 'ManageEmailsPage',
    component: ManageEmailsPage,
    meta: {
      'porg-auth': { roles: ['user'] }
    },
    props: route => ({
      settings: route.params.settings,
      currentEmails: route.params.emails
    }),
    async beforeEnter (to, from, next) {
      const emails = await getEmails()
      to.params.emails = emails
      const settings = await getSettings()
      to.params.settings = settings
      next()
    }
  },
  {
    path: 'account/aliases',
    name: 'ManageAliasesPage',
    component: ManageAliasesPage,
    meta: {
      'porg-auth': {
        roles: ['author']
      }
    },
    props: (route) => ({
      aliases: route.params.aliases
    }),
    beforeEnter: guardWithErrorHandling(
      async (to, from, next) => {
        to.params.aliases = await getAliases()
        return next()
      }
    )
  },
  {
    path: 'account/records',
    name: 'ManageRecordsPage',
    component: ManageRecordsPage,
    meta: {
      'porg-auth': {
        roles: ['author']
      },
      scrollBehavior: {
        routeUpdate: 'savedPosition'
      }
    },
    props: route => ({
      page: Number(route.query.page) || 1,
      q: route.query.q,
      perPage: Number(route.query.perPage) || undefined,
      type: intersection(recordTypes,
        typeof route.query.type === 'string' ? [route.query.type] : route.query.type)
    })
  },
  {
    path: 'account/records/:id',
    name: 'EditRecordPage',
    component: EditRecordPage,
    meta: {
      'porg-auth': {
        roles: ['user']
      }
    },
    props: route => ({ record: route.params.record, mode: route.query.mode }),
    beforeEnter: guardWithErrorHandling(
      async function (to, from, next) {
        // Pre-fetch record
        const request = { id: to.params.id }
        if (to.query.mode === 'resolve-inconsistency') {
          request.allowDraft = true
        }
        const record = await fetchRecord(request)
        // Check if user should have permission to edit this record
        const profile = store.state.profile

        if (profile.roles.includes('operator') ||
          isRecordAuthor({ record, userId: profile.id }) ||
          isUnitManagerOfAuthorMemberships({ record, profile })) {
          to.params.record = record
          return next()
        }
        Vue.prototype.$notification.send({ key: 'record.edition.guard.no-permission-to-edit', type: 'warn', dismiss: true })
        return next({ ...(from ?? { path: '/' }), replace: true })
      }
    )
  },
  {
    path: 'account/records/deduplication',
    name: 'EditRecordDeduplication',
    component: EditRecordPage,
    meta: {
      'porg-auth': {
        roles: ['author']
      }
    },
    props: route => ({ record: route.params.record, mode: 'deduplication' })
  },
  {
    path: 'account/external-repositories',
    name: 'ManageExternalRepositoriesPage',
    component: ManageExternalRepositoriesPage,
    meta: {
      'porg-auth': {
        roles: ['author']
      }
    },
    props: (route) => ({
      initialRepositories: route.params.repositories
    }),
    beforeEnter: guardWithErrorHandling(
      async (to, from, next) => {
        to.params.repositories = await getExternalProfiles()
        return next()
      }
    )
  },
  {
    path: 'account/affiliations',
    name: 'ManageAffiliationsPage',
    component: ManageAffiliationsPage,
    meta: {
      'porg-auth': {
        roles: ['author']
      },
      scrollBehavior: {
        routeUpdate: 'savedPosition'
      }
    },
    props: route => ({
      page: Number(route.query.page) || 1,
      perPage: Number(route.query.perPage) || undefined
    })
  },
  {
    path: 'account/affiliations/:affiliationId',
    name: 'EditAffiliationPage',
    component: EditAffiliationPage,
    meta: {
      'porg-auth': {
        roles: ['author']
      }
    },
    props: route => ({
      unit: route.params.unit,
      affiliation: route.params.affiliation
    }),
    beforeEnter: guardWithErrorHandling(
      async (to, from, next) => {
        const affiliation = await getAffiliation({ affiliationId: to.params.affiliationId })
        to.params.unit = affiliation.unit
        delete affiliation.unit
        to.params.affiliation = affiliation
        return next()
      }
    )
  }
]
