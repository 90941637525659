<template>
  <div>
    <header class="section-header">
      <div class="section-header__text">
        <h1 class="section-header__title">
          {{ $t('unit.public.profile.subunits.header') }}
        </h1>
        <p>{{ $t('unit.public.profile.subunits.description') }}</p>
      </div>
    </header>
    <SearchPaginatedList
      :is-initial-loading="isInitialLoading"
      :is-searching="isSearching"
      :query="searchQuery"
      :page-data="units"
    >
      <template #search-bar="{buttonAttrs, inputAttrs}">
        <div class="f-field-search">
          <button
            class="f-field-search__submit"
            v-bind="buttonAttrs"
          />
          <input
            v-model.trim="searchQuery"
            :placeholder="$t('search.units')"
            type="search"
            class="f-field-search__input f-field-search__input--card"
            v-bind="inputAttrs"
          >
        </div>
      </template>
      <template #list-skeleton>
        <UnitSkeleton
          :count="4"
          class="card-list"
        />
      </template>
      <template #list>
        <ul class="card card-list">
          <li
            v-for="unit in units.items"
            :key="unit.id"
            class="card-row"
          >
            <Avatar
              :name="unit.name"
              class="card-row__figure figure--letter"
            />

            <div class="card-row__text">
              <UnitBreadcrumb :unit="unit" />
              <h1 class="card-row__title h5--ssp">
                <RouterLink
                  :to="{ name: 'UnitPage', params: { unitID: unit.id } }"
                  class="card-row__link"
                >
                  {{ unit.name }}
                </RouterLink>
              </h1>
            </div>
          </li>
        </ul>
      </template>
      <template #pagination>
        <PaginationBar
          v-if="units"
          :current-page="currentPage"
          :total-items="units.totalItems"
          :items-per-page="perPage"
        />
      </template>
      <template #empty-state>
        <UnitEmptyState>
          <template #title>
            {{ $t('unit.public.profile.subunits.empty.state.search.results.header', { query: emptyStateSearchQuery }) }}
          </template>
          <template #description>
            {{ $t('unit.public.profile.subunits.empty.state.search.results.description') }}
          </template>
        </UnitEmptyState>
      </template>
    </SearchPaginatedList>
  </div>
</template>
<script>
import Avatar from '@/components/utils/Avatar.vue'
import UnitBreadcrumb from '@/components/units/UnitBreadcrumb.vue'
import PaginationBar from '@/components/utils/PaginationBar.vue'
import { getSubUnits } from '@/api/units'
import UnitEmptyState from '@/components/empty-states/Unit.vue'
import UnitSkeleton from '@/components/loading-skeletons/Unit.vue'
import SearchPaginatedList from '@/components/utils/SearchPaginatedList.vue'

export default {
  components: {
    Avatar,
    UnitBreadcrumb,
    PaginationBar,
    UnitEmptyState,
    UnitSkeleton,
    SearchPaginatedList
  },
  beforeRouteUpdate (to, from, next) {
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    this.isSearching = true
    this.searchTimeout = setTimeout(async () => {
      this.emptyStateSearchQuery = to.query.q

      this.currentPage = Number(to.query.page) || 1
      this.units = await getSubUnits({ unitID: this.unitID, page: this.currentPage, perPage: this.perPage, sort: '', q: to.query.q })
      this.isSearching = false
    }, 500)
    localStorage.setItem('perPage', to.query.perPage)
    next()
  },
  props: {
    unitID: {
      type: String,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    q: {
      type: String,
      default: ''
    },
    perPage: {
      type: Number,
      default: Number(localStorage.getItem('perPage')) || 10
    }
  },
  data () {
    return {
      isInitialLoading: true,
      isSearching: false,
      currentPage: this.page,
      units: undefined,
      searchQuery: this.q,
      searchTimeout: undefined
    }
  },
  metaInfo: function () {
    return {
      title: this.$t('title'),
      meta: [{ name: 'description', content: this.$t('description') }]
    }
  },
  watch: {
    async searchQuery (newValue, oldValue) {
      if (newValue === oldValue) return

      const query = { ...this.$route.query, q: newValue }
      if (newValue === '') {
        delete query.q
      }
      delete query.page
      await this.$router.replace({ query })
    }
  },
  beforeDestroy () {
    clearTimeout(this.searchTimeout)
  },
  async mounted () {
    this.units = await getSubUnits({ unitID: this.unitID, page: this.currentPage, perPage: this.perPage, sort: '', q: this.q })
    this.isInitialLoading = false
    // purposefully makes search query non-reactive to display on empty state
    this.emptyStateSearchQuery = this.searchQuery
  },
  i18n: {
    messages: {
      pt: {
        title: 'Subunidades',
        description: 'Uma lista com todos as subunidades'
      },
      en: {
        title: 'Subunits',
        description: 'A list with all the subunits'
      }
    }
  }
}
</script>
