<template>
  <div
    v-if="subject && subject.length"
    class="record-field"
  >
    <h2 class="h5--ssp">
      {{ $t('fields.record.subject.label') }}
    </h2>
    <ul class="research-interests l-list l-list--inline">
      <li
        v-for="keyword in subject"
        :key="`key-${keyword}`"
        class="l-list__item"
      >
        <span class="label label--light label--outline label--lg">
          {{ keyword.trim() }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    subject: {
      type: Array,
      default: () => []
    }
  }
}
</script>
